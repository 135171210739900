<template>
  <a-modal v-model:visible="state.visible"
           :bodyStyle="{ padding: 0 }"
           :closable="false"
           :destroyOnClose="true"
           :footer="null"
           class="folder-modal-wrapper"
  >
    <a-spin :spinning="state.isLoading">
      <div id="folder-set" class="modal modal-md">
        <div class="modal-header bg-gray">
          <h2>폴더 설정</h2>
          <span class="modal-close" role="button" @click="state.visible = false">닫기</span>
        </div>
        <div class="modal-content">
          <div class="modal-body">
            <div v-show="props.canChangeTeam" style="display: flex; justify-content: space-between; align-items: center; padding: 10px 0 25px;">
              <div>
                <TeamOutlined style="margin-right: 5px;"/>
                {{ state.teamName }}
              </div>
              <a v-funcKey="'team-selection'" style="text-decoration: underline solid gray; color: gray;" @click="changeTeam">
                다른팀선택
                <RightOutlined/>
              </a>
            </div>
            <div class="box-wrap">
              <ul class="folder-list">
                <!-- 폴더 설정 모달에서 리스트의 수정버튼을 클릭했을 때 시작 -->
                <li v-if="state.isEditting" class="new-folder">
                  <div class="new-form">
                    <!-- 수정 버튼 클릭하면 display:block 적용 -->
                    <div class="input-group">
                      <div>
                        <i class="ico ico-folder"></i>
                        <input
                          ref="folderNameInput"
                          v-model="state.newFolderName"
                          maxlength="14"
                          placeholder="폴더명을 입력하세요."
                          type="text"
                          @blur="onBlur"
                          @keypress.enter="saveFolderName"
                        />
                      </div>
                      <div v-if="state.isEditting && state.newFolderName" class="input-group-addon btn-txt-group">
                        <button
                          class="btn-txt modal-trigger"
                          data-modal-id="folder-modi"
                          type="button"
                          @click="saveFolderName"
                        >
                          완료
                        </button>
                        <button class="btn-txt" type="button" @click="cancelCreate">
                          취소
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li v-for="folder in state.folders" :key="folder.folderId" :class="{ 'folder-selected': state.selectedFolder ? state.selectedFolder.folderId === folder.folderId : '' }" class="list"
                    @click="handleSelectedFolder(folder)">
                  <div class="name">
                    <i class="ico ico-folder"></i>
                    <i v-if="folder.type === 'PRIVATE'" class="ico ico-lock"></i>
                    {{ folder.name }}
                    <div v-if="hasEditPermission(folder) && showModifyBtns" class="btn-txt-group">
                      <button class="btn-txt" title="수정" type="button" @click="onClickEdit(folder)">
                        <i class="ico ico-modify">수정</i>
                      </button>
                      <button class="btn-txt" title="삭제" type="button" @click="removeFolder(folder)">
                        <i class="ico ico-eraser">삭제</i>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="showModifyBtns" v-funcKey="'create-the-folder'" class="folder-btnset">
              <a @click="onClickCreateFolder('PUBLIC')">+ 팀 공개 폴더 추가</a>
              <a @click="onClickCreateFolder('PRIVATE')">+ 비공개 폴더 추가</a>
            </div>
            <div class="folder-btn-table">
              <a-button class="btn folder-btn" @click="state.visible = false">{{ cancelText }}</a-button>
              <a-button :disabled="!state.selectedFolder" class="btn folder-btn" type="primary" @click="confirm">{{ okText }}</a-button>
            </div>
          </div>
        </div>
      </div>
    </a-spin>

  </a-modal>
  <FolderEditModal ref="folderEditModal" :teamId="state.teamId" :zIndex="10001" @hasChanged="fetchData"/>
  <ChooseTeam v-model:id="state.teamId" v-model:visible="chooseTeamVisible" :targetTeamId="chooseDefaultId" :zIndex="10001"></ChooseTeam>
</template>

<script setup>
import { createVNode, defineEmits, defineExpose, defineProps, reactive, ref } from 'vue'
import { createFolder, deleteFolder, getFolders, getTeamInfo } from 'api/folder'
import { ExclamationCircleOutlined, RightOutlined, TeamOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue'
import FolderEditModal from '@/components/default/folder/FolderEditModal.vue'
import { getTeamId } from '@/utils/helper'
import ChooseTeam from '@/views/teams/chooseTeamModal.vue'
import { useStore } from 'vuex'

const folderEditModal = ref(null)

const props = defineProps({
  canChangeTeam: Boolean,
  showFullFolder: {
    type: Boolean,
    default: true
  },
  okText: { default: '완료' },
  cancelText: { default: '취소' },
  showModifyBtns: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['folderSelected', 'deletedFolder', 'teamSelected'])

const store = useStore()
const state = reactive({
  visible: false,
  folders: [],
  isEditting: false,
  newFolderName: '',
  createFolderType: '',
  isLoading: false,
  selectedFolder: null,
  teamId: '',
  teamName: '',
  teamList: []
})

const chooseTeamVisible = ref(false)
const chooseDefaultId = ref('')
const changeTeam = () => {
  chooseTeamVisible.value = true
  chooseDefaultId.value = state.teamId
}

const show = teamId => {
  state.visible = true
  state.teamId = teamId || getTeamId()
  state.teamIdList = store.state.app.selectedTeamList
  state.selectedFolder = null
  fetchData()
}

// watch(() => state.teamId, (now, pre) => {
//   fetchData()
//   state.selectedFolder = null
// })

const folderNameInput = ref(null)

const fetchData = async () => {
  state.isLoading = true
  const defaultFolders = props.showFullFolder ? [{
    name: '모든 폴더',
    folderId: ''
  }] : []
  const folderRes = await getFolders({
    teamId: state.teamId,
    teamIdList: state.teamIdList
  })
  defaultFolders.push(...folderRes.result)
  const teamRes = await getTeamInfo(state.teamId)
  state.teamName = teamRes.result.name
  state.folders = defaultFolders
  state.isLoading = false
}

const onBlur = () => {
  if (!state.newFolderName.trim()) state.isEditting = false
}

const onClickCreateFolder = (type) => {
  state.isEditting = true
  state.createFolderType = type
  setTimeout(() => {
    folderNameInput.value.focus()
  }, 0)
}

const saveFolderName = () => {
  state.isLoading = true
  setTimeout(async () => {
    try {
      await createFolder({
        name: state.newFolderName,
        type: state.createFolderType,
        teamId: state.teamId
      })
      state.newFolderName = ''
      state.isEditting = false
      fetchData()
    } catch (error) {
      Modal.error({ content: error.msg })
    }
    state.isLoading = false
  }, 1000)
}

const cancelCreate = () => {
  state.newFolderName = ''
  state.isEditting = false
}

const removeFolder = (folder) => {
  Modal.confirm({
    title: () => `${folder.name} 폴더를 삭제하시겠습니까?`,
    content: '삭제된 폴더에 포함된 문서는 모두 ‘기본 폴더’로 이동합니다.',
    icon: () => createVNode(ExclamationCircleOutlined),
    okText: () => '예',
    cancelText: () => '아니오',
    onOk () {
      state.isLoading = true
      deleteFolder(folder.folderId)
        .then(_ => {
          if (state.selectedFolder.folderId === folder.folderId) {
            state.selectedFolder = null
          }
          fetchData()
          var defaultFolder = null
          for (let index = 0; index < state.folders.length; index++) {
            const element = state.folders[index]
            if (element.defaultY) {
              defaultFolder = element
              break
            }
          }
          emit('deletedFolder', {
            deletedFolder: folder,
            defaultFolder: defaultFolder
          })
        }).finally(() => {
          state.isLoading = false
        })
    }
  })
}

const onClickEdit = folder => {
  folderEditModal.value.show(folder)
}

const confirm = () => {
  if (state.selectedFolder) emit('folderSelected', state.selectedFolder)
  if (state.teamId) {
    emit('teamSelected', {
      teamId: state.teamId,
      teamName: state.teamName
    })
  }
  state.visible = false
}

const hasEditPermission = folder => {
  if (folder.defaultY || folder.folderId === '') return false
  return true
  // if (['1000', '1001'].includes(store.state.user.userInfo.organization.roleId)) {
  //   return true
  // }
  // if (store.state.user.userInfo.organization.roleId === '1002') {
  //   const userTeamId = store.state.user.userInfo.organization.teamId
  //   return userTeamId === folder.teamId
  // }
}

const getChosedTeam = () => {
  return state.teamName ? {
    teamName: state.teamName,
    teamId: state.teamId
  } : null
}

const handleSelectedFolder = (folder) => {
  if (folder.folderId === '') {
    state.selectedFolder = {
      name: '모든 폴더',
      folderId: ''
    }
  } else {
    state.selectedFolder = folder
  }
}

defineExpose({
  show,
  getChosedTeam
})
</script>

<style lang="less" scoped>
.folder-modal-wrapper {
  .modal-header {
    padding: 2rem;
  }

  .modal-close {
    margin: 24px;
  }

  .modal-content {
    border: none;
  }

  .folder-selected {
    background: #1890ff !important;
    color: #fff !important;
  }

  .folder-selected .ico {
    filter: brightness(0) invert(1);
  }

  @media (hover: hover) {
    .folder-selected:hover {
      background: #1890ff !important;
      color: #fff !important;
    }

    .folder-selected:hover .ico {
      filter: brightness(0) invert(1);
    }

    .folder-list .list:hover {
      background: #fafafa;
    }
  }

  .box-wrap {
    height: 290px;
    padding: 0.938em;
    border: 1px solid #eee;
    overflow-y: auto;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  .box-wrap.box-lg {
    max-height: 21.875em;
  }

  @media only screen and (max-width: 640px) {
    .box-wrap.box-lg {
      max-height: 60vh;
    }
  }

  .folder-list li {
    padding: 1em 1.25em;
    border: 1px solid #eee;
    font-size: 1.4rem;
    font-weight: 600;
    color: #999;
    cursor: pointer;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
  }

  .folder-list .list.unlabeled {
    color: #666;
  }

  .folder-list .list.selected,
  .folder-list .new-folder,
  .folder-list .new-form {
    border-color: #ccc;
    background: #fafafa;
  }

  .folder-list .new-folder input {
    width: 80%;
    background: transparent;
  }

  .folder-list li + li {
    margin-top: 0.313em;
  }

  .folder-list li .ico {
    margin: -0.2em 0.5em 0 0;
  }

  .folder-list .name {
    position: relative;
  }

  .folder-list .name .btn-txt-group {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  @media (hover: hover) {
    .folder-list .name:hover .btn-txt-group {
      display: block;
    }
  }

  .folder-btnset {
    display: flex;
    justify-content: space-between;
  }

  .folder-btnset a {
    width: 50%;
    margin: 1.5rem 0;
    text-align: center;
    font-size: 1.5rem;
    text-decoration: underline;
  }

  .folder-btn-table {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;

    .folder-btn {
      width: 48%;
    }
  }
}
</style>
