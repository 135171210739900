<template>
  <header>
    <h1>
      <router-link :to="{ name: 'Home' }"
      ><span class="logo logo-sm white">유캔싸인</span>
        <!-- <em>관리자</em> -->
      </router-link>
    </h1>
    <div :class="menuClass" class="menu" role="button" @click="isMenuExpand = !isMenuExpand">
      <span class="line"></span>
    </div>
  </header>
  <aside :class="asideClass" class="aside">
    <div class="side-wrap">
      <div v-if="!guideToolTip1" class="fixed-tooltip">
        <div class="txt">
          <span>서명 요청을 시작해 보세요!</span>
          <CloseOutlined @click="closeGuideTooltip1"/>
        </div>
        <CaretDownOutlined/>
      </div>
      <button
        class="btn btn-blue btn-md btn-rounded"
        type="button"
        @click="$emit('createDoc')"
      >
        서명 요청하기
      </button>
      <div class="point-box">
        <span style="cursor: pointer">
          <router-link :to="{ name: 'Point', hash: '#3' }">
            <i class="point">P</i>{{ pointBalace }}
          </router-link>
        </span>
        <router-link
          v-funcKey="'point-charge'"
          :to="{ name: 'Point', hash: '#1' }"
          class="btn btn-light btn-rounded"
        >
          충전하기
        </router-link>
      </div>
      <nav>
        <div class="menu-list">
          <ul class="slide-menu">
            <li
              v-for="menu in menus"
              :key="menu.title"
              v-hasPermission="menu.permissions || ''"
              :class="{ depth: menu.sub }"
            >
              <router-link
                :class="{ active: currentSelected === menu.name, hasBackIcon: menu.backIcon }"
                :style="menu.sytle || {}"
                :to="{name: menu.name === 'TeamIndex' ? '' : menu.name }"
                @click="clickMenu(menu)"
              >
                <span><i v-if="menu.icon" :class="menu.icon" class="ico"></i>{{ menu.title }}</span>
                <i v-if="menu.backIcon" v-funcKey="{ name: 'team-selection', menu: menu }" :class="menu.backIcon" class="ico"></i>
                <SignPayIcon v-if="menu.name === 'Payments'" :fill="'white'" style="margin-left: 1rem; width: 5.6rem; transform: translateY(0.1rem);"/>
              </router-link>
              <ul v-if="menu.sub" class="sub">C
                <li
                  v-for="subMenu in menu.sub"
                  :key="subMenu.title"
                  @click="onClickSubMenu(subMenu)"
                >
                  <router-link :class="{ active: currentSelected === menu.name && subMenuSelected === subMenu.name }" :to="{name: subMenu.name }">
                    {{ subMenu.title }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <div class="info-menu">
            <a :class="{active: currentSelected === 'Settings'}"
               @click="handleSettingJump"
            >
              <span>설정</span>
              <i class="arrow arrow-md arrow-gray right"></i>
            </a>
            <router-link :to="{ name: 'Dev-Index' }">
              <span class="new">개발자 센터</span>
              <i class="arrow arrow-md arrow-gray right"></i>
            </router-link>
            <!-- 설정이 추가되면 new 클래스 추가 -->
            <a href="https://ucansign.oopy.io" target="__blank"
            >고객센터<i class="arrow arrow-md arrow-gray right"></i
            ></a>
            <a @click="onLogout"
            >로그아웃 <i class="arrow arrow-md arrow-gray right"></i
            ></a>
          </div>
        </div>
      </nav>
      <div class="update-btn">
        <a-button class="btn btn-dark-blue btn-md btn-rounded" type="button" @click="guide" @focus="removeBtnFocus">
          이용가이드 보기
        </a-button>
        <a-button class="btn btn-dark-blue btn-md btn-rounded" type="button" @click="payappLoginModalShow" @focus="removeBtnFocus">
          페이앱 로그인
        </a-button>
      </div>
    </div>
  </aside>
  <TeamChangeModal ref="teamChangeModal" @teamSelected="teamChanged"></TeamChangeModal>
  <SimpleModal ref="payappLoginModal" :centered="true" :footerBtnYn="false">
    <template #textContent>
      전자계약 관련 결제 이외 다른 결제방식이 필요할 때,<br/>
      페이앱을 이용해 보세요.
    </template>
    <template #footer>
      <a-button class="btn" style="width: 240px;" type="primary" @click="payappLogin">페이앱으로 결제받기</a-button>
    </template>
  </SimpleModal>
</template>

<script>
import LogoImg from 'assets/images/logo.svg'
// import router from '@/router/index.js'
// import Icon from '@/components/default/icon/Icon'
import { jumpToSettings, jumpToUrl } from '@/components/common/common.js'
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { formatNum, hasCurTeamAllPermission } from 'utils/helper'
import TeamChangeModal from 'components/common/TeamChangeModal'
import { createMenu } from 'utils/menus'
// import { getAllTeams } from 'api/folder'
import { funcIsEnable } from '@/utils/permission'
import SignPayIcon from 'assets/images/common/SignPayIcon'
import SimpleModal from 'components/common/SimpleModal'
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { setNotice } from 'api/user'

export default {
  components: {
    SimpleModal,
    SignPayIcon,
    TeamChangeModal,
    CloseOutlined,
    CaretDownOutlined
    // Icon
  },
  props: {
    device: String
  },
  emits: ['createDoc'],
  setup (props) {
    const teamChangeModal = ref(null)
    const payappLoginModal = ref(null)
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      // menus: router.options.routes[0].children,
      menus: [],
      collapsed: store.state.app.collapsed,
      isMenuExpand: false,
      currentSelected: route.name,
      subMenuSelected: route.name,
      currentTitle: '',
      guideToolTip1: store.state.user.userInfo ? store.state.user.userInfo.notifications.guide1 : false
    })
    const onCollapse = (collapsed, type) => {
      store.dispatch('setCollapsed', collapsed)
    }

    // change sider state
    const handleSideBar = () => {
      state.collapsed = !store.state.app.collapsed
      store.dispatch('setCollapsed', !store.state.app.collapsed)
    }

    const handleBarJump = (url) => {
      jumpToUrl(url)
      handleSideBar()
    }

    const menuClass = computed(() => {
      return { close: props.device === 'mobile' && state.isMenuExpand }
    })

    const asideClass = computed(() => {
      return { 'aside-hide': props.device === 'mobile' && !state.isMenuExpand }
    })

    const handleSettingJump = () => {
      // onClickMenu()
      jumpToSettings()
      state.currentSelected = 'Settings'
    }

    // const onClickMenu = (menu = null) => {
    //   state.isMenuExpand = false
    //   if (menu) {
    //     state.currentSelected = menu.name
    //   }
    // }

    const pointBalace = computed(() => {
      return formatNum(store.state.app.point)
    })

    const onLogout = () => {
      store
        .dispatch('logout')
        .then(res => {
          store.dispatch('setTeam', null)
          store.dispatch('setFolder', null)
          Modal.success({
            content: res.msg,
            onOk () {
              jumpToUrl('/user/login')
            }
          })
        })
        .catch(error => {
          Modal.error({ content: error.msg })
        })
    }

    const clickMenu = menu => {
      if (menu.isTeamMenu && funcIsEnable({
        name: 'team-selection',
        menu: menu
      })) {
        teamChangeModal.value.show()
      } else {
        state.currentSelected = menu.name
        state.currentTitle = menu.title
      }
    }

    const onClickSubMenu = subMenu => {
      state.subMenuSelected = subMenu.name
      state.currentTitle = subMenu.title
    }

    state.menus = createMenu()

    const teamChanged = () => {
      state.menus = createMenu()
    }

    const showCreateBtn = computed(() => {
      const curTeamId = store.state.app.selectedTeam ? store.state.app.selectedTeam.teamId : ''
      return hasCurTeamAllPermission(curTeamId)
    })

    onMounted(() => {
      setMenu(route)
    })

    const setMenu = route => {
      state.currentSelected = route.name
      if (route.meta.parentMenuName) {
        state.subMenuSelected = route.name
        state.currentSelected = route.meta.parentMenuName
      }
    }

    watch(() => route.name, () => {
      setMenu(route)
      state.isMenuExpand = false
    })

    const guide = (ev) => {
      window.open('https://ucansign.oopy.io/3c0048aa-4697-4d04-a5ad-a8dfcd9d77db')
    }

    const payappLoginModalShow = () => {
      payappLoginModal.value.show()
    }

    const payappLogin = () => {
      window.open('https://seller.payapp.kr/a/signIn')
      payappLoginModal.value.hide()
    }

    const removeBtnFocus = (ev) => {
      ev.target.blur()
    }

    const closeGuideTooltip1 = () => {
      setNotice({
        type: 'guide1'
      })
        .then(() => {
          store.dispatch('userInfo').then(() => {
            state.guideToolTip1 = true
          })
        })
        .catch((err) => {
          Modal.error({ content: err.msg })
        })
    }

    return {
      teamChangeModal,
      payappLoginModal,
      menuClass,
      asideClass,
      LogoImg,
      ...toRefs(state),
      jumpToUrl,
      onCollapse,
      handleSideBar,
      handleBarJump,
      handleSettingJump,
      onLogout,
      pointBalace,
      clickMenu,
      teamChanged,
      showCreateBtn,
      onClickSubMenu,
      guide,
      payappLoginModalShow,
      payappLogin,
      removeBtnFocus,
      closeGuideTooltip1
    }
  }
}
</script>

<style lang="less" scoped>
.aside {
  transition: all 0.2s;
  opacity: 1;
}

.side-wrap {
  scrollbar-width: none;

  .btn {
    margin-bottom: 1rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.aside-hide {
  visibility: hidden;
  opacity: 0;
}

// old
.app-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000;
  opacity: 0.5;
}

.admin_logo {
  height: 32px;
  margin: 16px;
  text-align: center;
}

.app-sider {
  background: hsl(214, 23%, 19%);
  width: 100%;
  flex: 0 0 100%;

  &.ant-layout-sider-below {
    position: fixed;
    z-index: 9;
  }

  .sider-top {
    position: relative;
    z-index: 1;

    .sign-btn {
      padding: 12px;
      position: relative;
      text-align: center;

      button {
        font-size: 14px;
        height: 36px;
        padding: 5px 40px 5px 40px;
      }
    }
  }

  .sider-menu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 300px);
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    .ant-menu-item {
      padding-left: 0px;
      text-align: left;
    }
  }

  .sider-bottom {
    .sign-btn {
      padding: 12px;
      position: relative;
      text-align: center;
    }
  }
}

.fixed-tooltip {
  position: fixed;
  top: 74px;
  left: 260px;
  text-align: center;
  border-radius: 10rem;
  background: #27d0b0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.7rem 1.5rem;
  word-break: keep-all;
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;

  .txt {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon-close {
      padding: 1rem;
      margin: -0.9rem -1rem -1rem 0;
    }
  }

  .anticon-caret-down {
    position: absolute;
    top: 9px;
    left: -12px;
    line-height: 0 !important;
    color: #27d0b0;
    font-size: 20px;
    transform: scale(3, 1) rotate(90deg);
  }
}

@media screen and (max-width: 768px ) {
  .fixed-tooltip {
    display: none;
  }
}

@keyframes bounce {
  0% {
    left: 260px;
  }
  50% {
    left: 265px;
  }
  100% {
    left: 260px;
  }
}
</style>
