<template>
  <UcsModal v-model:visible="isVisible" :width="567" :zIndex="zIndex">
    <template #title>
      팀 선택
    </template>
    <template #content>
      <div style="padding: 0.5em 2em 1.5em;">
        <h3 style="margin: 10px 0 14px; font-size: 15px;">팀 이름</h3>
        <TeamList class="ele" style="max-height: 400px; overflow: auto; border: solid 1px #d9d9d9; border-radius: 2px" ref="list" v-model:selectedTeamId="teamId" :listType="TeamListType.AssignObject"
                  :onlyBelongedTeams="onlyBelongedTeams" @onSelectedTeam="onSelectedTeam"/>
      </div>
      <a-row :gutter="[6, 6]" style="margin: 0 18px 20px">
        <a-col :span="12">
          <a-button class="btn" style="color: #8c8c8c;" :block="true" @click="cancel">취소</a-button>
        </a-col>
        <a-col :span="12">
          <a-button class="btn" :block="true" type="primary" @click="ok">완료</a-button>
        </a-col>
      </a-row>
    </template>
  </UcsModal>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue'
import UcsModal from '@/components/common/UcsModal.vue'
import TeamList, { TeamListType } from './teamList.vue'

export default {
  components: {
    UcsModal,
    TeamList
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    onlyBelongedTeams: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 1000
    }
  },

  emits: ['update:visible', 'update:id', 'update:name'],

  setup (props, { emit }) {
    const state = reactive({
      isVisible: props.visible || false,
      teamName: props.name || '',
      teamId: props.id || ''
    })
    const list = ref(null)
    watch(() => props.visible, (now, pre) => {
      if (state.isVisible !== now) state.isVisible = now
    })
    watch(() => props.name, (now, pre) => {
      state.teamName = now
    })
    watch(() => props.id, (now, pre) => {
      state.teamId = now
    })
    watch(() => state.isVisible, (now, pre) => {
      if (now && list.value) {
        list.value.refresh()
      }
      emit('update:visible', now)
    })

    const onSelectedTeam = team => {
      state.teamId = team.teamId
      state.teamName = team.name
    }

    const ok = () => {
      if (props.id !== state.teamId) {
        emit('update:id', state.teamId)
        emit('update:name', state.teamName)
      }
      cancel()
    }

    const cancel = () => {
      state.isVisible = false
    }

    return {
      TeamListType,
      ...toRefs(state),
      list,
      onSelectedTeam,
      ok,
      cancel
    }
  }
}
</script>
