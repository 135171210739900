<template>
  <div :class="cls" class="e-control e-chk" @click.stop.prevent="onClick">
    <svg v-show="checkboxValue" aria-label="check icon" color="#1890ff" role="img" viewBox="0 0 14 14" width="100%">
      <polyline fill="none" opacity="1" points="2.293 6.73 5.842 10.052 11.707 3.948" stroke="#000" stroke-width="1.8"></polyline>
    </svg>
    <svg v-show="!checkboxValue" aria-label="check icon" color="#1890ff" role="img" viewBox="0 0 14 14" width="100%">
      <polyline fill="none" opacity="1" points="2.293 6.73 5.842 10.052 11.707 3.948" stroke="#0000002f" stroke-width="1.8"></polyline>
    </svg>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue'

export default {
  props: ['isChecked', 'field'],
  setup (props, { emit }) {
    const state = reactive({
      checkboxValue: props.field.checkboxValue
    })

    onMounted(() => {
      emit('update:isChecked', state.checkboxValue)
    })

    const onClick = () => {
      state.checkboxValue = !state.checkboxValue
      emit('update:isChecked', state.checkboxValue)
      // state.isChecked = !state.isChecked
    }

    const cls = computed(() => {
      return { 'e-sure': props.field.required }
    })

    const groupParentName = computed(() => {
      return props.field.groupParentName
    })

    return {
      onClick,
      cls,
      ...toRefs(state),
      groupParentName
    }
  }
}
</script>

<style lang="less" scoped>
.e-chk {
  font-size: 0;
  background-color: rgba(0, 144, 255, 0.2);
  border: 1px solid #1890ff;
  cursor: pointer;

  svg {
    color: #1890ff;
  }
}
</style>
