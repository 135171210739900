<template>
  <div class="sign-wrapper">
    <div class="box ">
      <div class="box-body">
        <div class="b-tit sub-tit">
          <span v-if="isSequential" class="label s-left">{{ index + 1 }}</span>
          <h3>{{ signTip }} <span v-if="signer.roleName" class="roleName">( {{ signer.roleName }} )</span></h3>
          <button v-if="!isFromMobile" class="btn-close" type="button" @click="remove">
            <i class="ico ico-close">닫기</i>
          </button>
        </div>
        <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
          <a-form-item v-if="operationType === 'template'" class="form-item" name="roleName">
            <a-input v-model:value="formState.roleName" :allowClear="true" :lazy="false" placeholder="역할" @mousedown="stopPropagation"
                     @pointerdown="stopPropagation"/>
          </a-form-item>
          <template v-if="isShowSignerInfo">
            <a-form-item class="form-item" name="name">
              <a-input ref="nameInput" v-model:value="formState.name" :allowClear="true" :lazy="false" placeholder="이름 또는 회사명"
                       @mousedown="stopPropagation" @pointerdown="stopPropagation"/>
            </a-form-item>
            <a-form-item v-if="signingMethodType === 'email'" class="form-item" name="email">
              <a-input ref="emailInput" v-model:value="formState.email" :allowClear="true" :lazy="false" class="form-control" placeholder="이메일 주소"
                       type="email" @mousedown="stopPropagation" @pointerdown="stopPropagation"/>
            </a-form-item>
            <a-form-item v-if="signingMethodType === 'kakao'" class="form-item" name="kakao">
              <a-input-group>
                <a-row :gutter="1">
                  <a-col :span="6">
                    <a-tooltip placement="left">
                      <template #title>
                        <span>010이 아닌 2G 휴대폰 참여자에게 카카오톡으로 요청할 수 없습니다.</span>
                      </template>
                      <a-select default-value="010" disabled>
                        <a-select-option value="010">
                          010
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-col>
                  <a-col :span="18">
                    <a-tooltip placement="right">
                      <a-input ref="kakaoInput" v-model:value="formState.kakao" :allowClear="true" :lazy="false" :maxlength="8" placeholder="'-' 없이 숫자만 입력"
                               type="tel" @mousedown="stopPropagation" @pointerdown="stopPropagation"/>
                      <template #title>
                        <span>해당 서명 참여자가 카카오톡을 이용하지 않거나 유캔싸인 알림톡을 차단한 경우, 문자로 요청합니다.</span>
                      </template>
                    </a-tooltip>
                  </a-col>
                </a-row>
              </a-input-group>
            </a-form-item>
          </template>
        </a-form>
      </div>
      <div class="box-btn" @mousedown="stopPropagation" @pointerdown="stopPropagation">
        <div class="table-group">
          <span v-if="operationType === 'template' && isShowCreateSignerBtn" class="btn btn-gray" role="button" @click="addSignerInfo">미리 정보 입력하기</span>
          <template v-else>
            <span :class="{ active: signingMethodType === 'email' }" class="btn btn-gray" role="button" @click.prevent="onClickInfoBtn('email')">이메일 전송</span>
            <span :class="{ active: signingMethodType === 'kakao' }" class="btn btn-gray" role="button" @click.prevent="onClickInfoBtn('kakao')">카카오톡 전송</span>
          </template>
        </div>
      </div>
    </div>
    <!--
    <div class="drag-btn">
      <button type="button">
        <i class="ico ico-drag">위치 옮기는 버튼</i>
      </button>
    </div>
    -->
  </div>

</template>

<script>
import { computed, nextTick, reactive, ref, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  props: ['signer', 'id', 'operationType', 'index', 'isSequential'],
  setup (props, { emit }) {
    const formRef = ref(null)
    // const signer = toRefs(props.signer)
    // const store = useStore()
    const nameInput = ref(null)
    const emailInput = ref(null)
    const kakaoInput = ref(null)
    const route = useRoute()

    const state = reactive({
      isFromMobile: route.name === 'MobileSignCreating',
      curSigner: props.signer,
      signingMethodType: props.signer.signingMethodType,
      rules: {
        name: [{
          required: true,
          message: '이름 또는 회사명을 입력하세요.',
          trigger: 'change'
        }],
        email: [
          {
            required: true,
            message: '올바른 이메일을 입력해 주세요.',
            trigger: 'change'
          },
          {
            pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/,
            type: 'email',
            message: '올바른 이메일을 입력해 주세요.',
            trigger: 'change'
          }
        ],
        kakao: [
          {
            required: true,
            message: '휴대전화 번호를 입력해주세요.',
            trigger: 'change'
          },
          {
            pattern: /^([0-9]{8})$/,
            message: '올바른 휴대전화 번호를 입력해주세요.',
            trigger: 'change'
          }
        ],
        roleName: [{
          required: true,
          message: '역할을 입력해 주세요.',
          trigger: 'blur'
        }]
      },
      isShowCreateSignerBtn: props.operationType === 'template'
    })

    const formState = reactive({
      name: state.curSigner.name,
      email: state.curSigner.signingContactInfo,
      kakao: state.curSigner.signingContactInfo,
      roleName: state.curSigner.roleName || ''
    })

    const signTip = computed(() => {
      if (props.isSequential) {
        const singerTip = ['첫 번째 서명 참여자', '두 번째 서명 참여자', '세 번째 서명 참여자', '네 번째 서명 참여자', '다섯 번째 서명 참여자']
        return singerTip[props.index]
      } else {
        return '서명 참여자'
      }
    })
    // remove itself
    const remove = () => {
      // emit('removeSigner', state.curSigner.signingOrder)
      emit('removeSigner', state.curSigner.dragKey)
    }
    const onClickOut = () => {
      if (state.signingMethodType === 'email' && formState.email.trim() === '') state.signingMethodType = ''
      if (state.signingMethodType === 'kakao' && formState.kakao.trim() === '') state.signingMethodType = ''
      if (props.operationType === 'template' && formState.name.trim() === '') {
        if (state.signingMethodType === '') state.isShowCreateSignerBtn = true
        if ((state.signingMethodType === 'email' && formState.email.trim() !== '') || (state.signingMethodType === 'kakao' && formState.kakao.trim() !== '')) {
          state.isShowCreateSignerBtn = false
        } else {
          state.isShowCreateSignerBtn = true
        }
      }
    }
    const onClickInfoBtn = (type) => {
      if (state.signingMethodType === type) return
      state.signingMethodType = type
      formState.email = ''
      formState.kakao = ''
      nextTick(() => {
        if (type === 'email') emailInput.value.focus()
        if (type === 'kakao') kakaoInput.value.focus()
      })
    }

    const stopPropagation = e => {
      e.stopPropagation()
      if (e.srcElement.focus()) e.srcElement.focus()
    }

    watch(formState, async (newVal) => {
      const signingContactInfo = state.signingMethodType === 'kakao' ? '010' + newVal[state.signingMethodType] : newVal[state.signingMethodType]
      const newData = {
        name: newVal.name,
        signingContactInfo,
        signingMethodType: state.signingMethodType,
        signingOrder: state.curSigner.signingOrder,
        message: '',
        attachments: state.curSigner.attachments,
        roleName: state.curSigner.roleName,
        dragKey: state.curSigner.dragKey,
        validated: false
      }
      if (props.operationType === 'template') {
        newData.roleName = newVal.roleName
      }
      try {
        await formRef.value.validateFields()
        newData.validated = true
      } catch (error) {
        newData.validated = false
      }
      emit('updateSigner', newData)
    })

    const isShowSignerInfo = computed(() => {
      if (props.operationType === 'template') {
        if (state.curSigner.name || state.curSigner.signingContactInfo) {
          return true
        } else {
          return !state.isShowCreateSignerBtn
        }
      } else {
        return true
      }
    })

    const addSignerInfo = () => {
      state.isShowCreateSignerBtn = false
      nextTick(() => {
        nameInput.value.focus()
      })
    }

    return {
      formRef,
      signTip,
      remove,
      onClickOut,
      onClickInfoBtn,
      emailInput,
      kakaoInput,
      formState,
      stopPropagation,
      addSignerInfo,
      isShowSignerInfo,
      nameInput,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.box-body {
  cursor: move;
}

.active {
  background: #eff8ff;
  border: 1px solid #1890ff;
  color: #1890ff;
}

.sign-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box {
    width: 340px;
  }

  .form-item {
    margin-bottom: 10px;
  }

  .roleName {
    margin-left: 5px;
    font-size: 12px;
    color: #4a98ff;
  }
}
</style>
