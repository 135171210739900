<template>
  <VueDragResize ref="signContainer"
                 :aspectRatio="true"
                 :class="isActive ? 'wrap-active' : ''"
                 :h="init.h"
                 :isDraggable="false"
                 :isResizable="true"
                 :minh="min.h"
                 :minw="min.w"
                 :parentLimitation="false"
                 :sticks="['br']"
                 :w="init.w"
                 :x="init.x"
                 :y="init.y"
                 class="signwrap"
                 contentClass="box-shaddow"
                 v-bind="$attrs"
                 @activated="onActive"
                 @deactivated="onDeactivated"
                 @resizestop="onResizeStop"
                 @resizing="onResize">
    <div style="transform-origin: center;">
      <template v-if="isActive">
        <button class="sign-close" type="button" @click="onClickRemove" @touchstart="onClickRemove">
          <close-circle-outlined style="font-size: 14px;"/>
        </button>
        <div class="sign-header"></div>
      </template>
      <img :src="fullFilePath" class="image-box">
    </div>
    <template #stick>
      <arrows-alt-outlined style="transform: rotate(90deg);"/>
    </template>
  </VueDragResize>
</template>
<script setup>
import VueDragResize from '@/components/common/ucsDrag/Drag.vue'
import { computed, defineEmits, defineProps, nextTick, onMounted, ref } from 'vue'
import { ArrowsAltOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { SIGNATURE_BYTE_URL } from 'config/config'

const props = defineProps(['style', 'class', 'signatureId', 'w', 'h', 'x', 'y'])
const emit = defineEmits(['remove', 'update:x', 'update:y', 'update:w', 'update:h', 'update:isActive', 'onActive'])
const signContainer = ref(null)
const isActive = ref(false)

const fullFilePath = computed(() => {
  return SIGNATURE_BYTE_URL[process.env.NODE_ENV] + props.signatureId
})

const onClickRemove = () => {
  emit('remove')
}

const onActive = () => {
  isActive.value = true
  emit('onActive')
  emit('update:isActive', true)
}

const onDeactivated = () => {
  isActive.value = false
  emit('update:isActive', false)
}

const min = ref({
  w: 60,
  h: 60
})

const init = ref({
  w: props.w,
  h: props.h,
  x: props.x,
  y: props.y
})

const onResize = ({
  width,
  height
}) => {
  const transX = -(width - init.value.w) / 2
  const transY = -(height - init.value.h) / 2
  signContainer.value.$el.style.transformOrigin = 'center'
  signContainer.value.$el.style.transform = `translate3d(${transX}px, ${transY}px, 0)`
}

onMounted(() => {
  nextTick(() => {
    min.value.w = props.w
    min.value.h = props.h
    updateSize(init.value.w, init.value.h, init.value.x, init.value.y)
  })
})

const onResizeStop = ({
  width,
  height
}) => {
  const transX = -(width - init.value.w) / 2
  const transY = -(height - init.value.h) / 2
  updateSize(width, height, init.value.x + transX, init.value.y + transY)
}

const updateSize = (w, h, x, y) => {
  emit('update:w', w)
  emit('update:h', h)
  emit('update:x', x)
  emit('update:y', y)
}

</script>

<style lang="less" scoped>
.signwrap {
  box-sizing: border-box;
  border: 2px dashed #CECECE;
  margin: -2px;
  transform-origin: center;
}

.sign-header {
  position: absolute;
  top: -24px;
  left: -2px;
  width: calc(100% + 4px);
  height: 24px;
  background-color: #1890FF;
  border-radius: 4px 4px 0 0;
}

.image-box {
  width: 100%;
  height: 100%;
  position: absolute;
}

.wrap-active {
  border: 2px solid #1890FF;
  margin: -2px;

  /deep/ .vdr-stick {
    border-radius: 50%;
    background: #1890FF;
    border-color: #1890FF;
    color: #FFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }

  /deep/ .vdr-stick.not-resizable {
    display: none;
  }

  .sign-close {
    position: absolute;
    right: 3px;
    top: -25px;
    color: #FFF;
    z-index: 5;
  }
}

@media screen and (max-width: 768px) {
  .wrap-active .sign-close {
    top: -22px;
  }
}
</style>
