<template>
  <!--  <Icon class="sign-tip"></Icon>-->
  <div class="sign-tip" :style="colorStyle">서명</div>
</template>

<script>
import { computed } from 'vue'
// import Icon from 'components/default/icon/Icon'
import { signerColor } from 'config/config'

export default {
  props: ['field', 'signingOrder'],
  // components: { Icon },
  setup (props) {
    const colorStyle = computed(() => {
      return { color: signerColor[props.signingOrder - 1] }
    })
    const type = computed(() => {
      if (props.field.fieldType === 'signature') {
        if (props.field.allowSignatureTypes.length === 2) {
          return 'icon-sign-user'
        } else {
          return `icon-sign-${props.field.allowSignatureTypes[0].toLowerCase()}`
        }
      } else {
        return `icon-sign-${props.field.fieldType}`
      }
    })

    return {
      type,
      colorStyle
    }
  }
}
</script>

<style lang="less" scoped>
.sign-tip {
  font-size: 18px;
  background: rgb(39, 208, 176);
  color: #fff !important;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  line-height: 5rem;
}
</style>
