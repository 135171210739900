<template>
  <div :class="cls" :style="wrapStyle" class="text-wrap">
    <textarea
      ref="textInput"
      v-model="text"
      :placeholder="fieldName"
      :readonly="true"
      :style="`transform: scale(${inputScale}); width: ${width}px; min-height: ${height}px; max-height:${height}px; text-align:${textAlign};`"
      class="inputtext"
      style="transform-origin:left top; padding: 0; font-size: 60px; resize: none; line-height: 1; word-break: break-all;letter-spacing: none;  outline: none; border: 0px; overflow: hidden; background-color: rgba(0,144,255,0.2); "
      @click="openDatePickerModal">
    </textarea>
    <a-modal v-model:visible="openDatePicker" :centered="true" :destroyOnClose="true" :maskClosable="false" cancelText="취소" okText="확인" @cancel="onCancel" @ok="onOk">
      <a-calendar v-model:value="dateValue" :fullscreen="false" @select="datePick">
        <template #headerRender="{ value: current, onChange }">
          <div style="padding: 10px;">
            <a-row justify="left" type="flex">
              <a-col style="margin-right: 10px;">
                <a-select
                  :dropdown-match-select-width="false"
                  :value="String(current.year())"
                  size="small"
                  @change="newYear => { onChange(current.year(+newYear)) }"
                >
                  <a-select-option
                    v-for="val in getYears(current)"
                    :key="String(val)"
                    class="year-item"
                  >
                    {{ val }}년
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col style="margin-right: 10px;">
                <a-select
                  :dropdown-match-select-width="false"
                  :value="String(current.month())"
                  size="small"
                  @change="selectedMonth => { onChange(current.month(parseInt(String(selectedMonth), 10))) }"
                >
                  <a-select-option
                    v-for="(val, index) in getMonths(current)"
                    :key="String(index)"
                    class="month-item"
                  >
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
        </template>
      </a-calendar>
      <a-input v-model:value="dateText" placeholder="입력할 날짜" readonly></a-input>
    </a-modal>
    <!--    <a-date-picker v-show="openDatePicker" v-model:value="dateValue" @openChange="datePick" :open="openDatePicker" style="visibility: hidden; margin: 0; width: 0; height: 0; left: 0; top: 10px; position: absolute;"/>-->
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue'
import dayjs from 'dayjs'

export default {
  props: ['field', 'textValue', 'scale'],
  setup (props, { emit }) {
    const obTextInput = ref(null)
    const textInput = ref(null)

    const state = reactive({
      id: props.field.fieldId,
      text: props.field.textValueText,
      dateText: '',
      inputScale: 1,
      textAlign: props.field.textAlign || 'left',
      minHeight: 0,
      maxHeight: 0,
      width: 0,
      height: 0,
      fieldName: props.field.fieldName,
      dateValue: '',
      dateFormat: props.field.dateFormat,
      openDatePicker: false
    })

    const init = () => {
      const initScale = (props.field.textStyleFontSize * props.scale) / 60
      const minFontSize = props.field.textStyleFontSize < 4 ? props.field.textStyleFontSize : 4
      const minScale = (minFontSize * props.scale) / 60
      state.inputScale = initScale
      state.minHeight = props.field.height / initScale
      state.maxHeight = props.field.height / minScale
      state.width = props.field.width / initScale
      state.height = props.field.height / initScale
    }

    onMounted(() => {
      init()
      emit('update:textValue', state.text)
      if (state.text) {
        const dateArray = state.text.split(/[-/년월일]/)
        const year = parseInt(dateArray[0])
        const month = parseInt(dateArray[1]) - 1
        const day = parseInt(dateArray[2])
        state.dateValue = dayjs(new Date(year, month, day))
      } else {
        state.dateValue = dayjs()
      }
    })

    const cls = computed(() => {
      return { 'e-sure': props.field.required }
    })

    const wrapStyle = computed(() => {
      // // has border width
      const width = props.field.width
      const height = props.field.height
      return {
        width: `${width}px`,
        height: `${height}px`,
        fontFamily: props.field.textStyleFontType
      }
    })

    watch(() => props.field.height, () => {
      init()
    })

    const openDatePickerModal = () => {
      state.openDatePicker = true
      window.getSelection().removeAllRanges()
      textInput.value.blur()
      if (state.dateValue) {
        state.dateText = dayjs(state.dateValue).format(state.dateFormat)
      }
    }

    const datePick = () => {
      if (state.dateValue) {
        state.dateText = dayjs(state.dateValue).format(state.dateFormat)
      }
    }

    const onOk = () => {
      state.text = state.dateText
      emit('update:textValue', state.text)
      state.openDatePicker = false
    }

    const onCancel = () => {
      state.openDatePicker = false
    }

    const getMonths = value => {
      return ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']
    }
    const getYears = value => {
      const year = value.year()
      const years = []
      for (let i = year - 100; i < year + 50; i += 1) {
        years.push(i)
      }
      return years
    }

    return {
      cls,
      obTextInput,
      textInput,
      wrapStyle,
      openDatePickerModal,
      datePick,
      onOk,
      onCancel,
      getMonths,
      getYears,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.text-wrap {
  font-size: 0;
  padding: 0;

  .inputtext {
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: text;
    font-size: 60px;
    line-height: 1;
    background-color: rgba(255, 255, 255, 0.8);
    resize: none;
  }

  .ob-text {
    position: absolute;
    z-index: -9999;
    background-color: bisque;
    visibility: hidden;
  }
}

.inputtext::placeholder {
  color: #1890ff;
  font-weight: 500;
}

.inputtext:focus {
  background: #ffffff2c !important;
  box-shadow: 0px 0px 0px 3px #1890ff
}

.inputtext:focus::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

::selection {
  background-color: #338fff;
  color: #ffffff;
}
</style>

<style lang="less">
.ant-select-dropdown {
  z-index: 9999;
}
</style>
