<template>
  <div :class="isEnd? 'end' : ''" class="box sign">
    <div class="box-body">
      <div class="b-tit sub-tit">
        <span v-if="isSequential" class="label s-left">{{ orderWord }}</span>
        <h3 v-if="isSequential">{{ signTip }}<span v-if="signer.roleName" class="roleName">({{ signer.roleName }})</span></h3>
        <h3 v-else>서명 참여자<span v-if="signer.roleName" class="roleName">({{ signer.roleName }})</span></h3>
      </div>
      <div class="form-group">
        <label class="sr-only" for="docuName1">문서이름</label>
        <div class="signer-info">
          <span>{{ signer.name }}</span>
          <span>{{ signer.signingContactInfo }}</span>
        </div>
      </div>
      <a-button v-if="!needMessage" block type="primary" @click="showMessageText">
        남길 말 입력
      </a-button>
      <!-- 남길 말 입력 버튼을 클릭하면 노출되는 영역 -->
      <div v-if="needMessage" class="form-group ant-custom">
        <label class="sr-only">남기실 말</label>
        <a-textarea ref="messageText" v-model:value="textValue" :maxlength="200" allowClear showCount @blur="onMessageBlur" @change="updateMessage"/>
      </div>
      <div v-show="authInfo.length>0" class="certify-wrap">
        <div v-for="(item, index) in authInfo" :key="item.authType" class="sub-tit">
          <div class="left-info">
            <i :class="item.authType === 'password' ? 'ico-pw-chk' : 'ico-phone-chk'" class="ico s-left"></i>
            <div class="tit-txt">
              <h3>{{ item.authType === 'password' ? '접근 암호 인증' : (item.authType === 'mobile_otp' ? 'OTP 인증' : '휴대폰 본인 인증') }}</h3>
              <p>{{ item.authType === 'password' ? '**********' : `${item.authValueName}(${item.authValuePhone})` }}</p>
            </div>
          </div>
          <CloseCircleFilled class="remove-btn" @click="onClickRemove(index)"/>
        </div>
      </div>
    </div>
    <div class="box-btn">
      <button
        class="btn btn-gray btn-block modal-trigger"
        data-modal-id="certify-way"
        type="button"
        @click="onClickScBtn"
      >
        인증 수단 추가 하기
      </button>
    </div>
  </div>
  <SignerSecurityModal ref="scModal" :processType="processType" :signer="signer" @confirmInfo="onConfirmInfo"/>
</template>

<script>
import { nextTick, reactive, ref, toRefs } from 'vue'
import SignerSecurityModal from 'components/signing/SignerSecurityModal'
import { useSignerOrder } from 'use/sign/useSignerOrder'
import { CloseCircleFilled } from '@ant-design/icons-vue'

export default {
  props: ['signer', 'message', 'processType', 'isSequential', 'isEnd'],
  components: {
    SignerSecurityModal,
    CloseCircleFilled
  },
  setup (props, { emit }) {
    const {
      signTip,
      orderWord
    } = useSignerOrder(props)
    const scModal = ref(null)
    const messageText = ref(null)
    const state = reactive({
      needMessage: false,
      textValue: props.message || '',
      authInfo: []
    })
    const onClickScBtn = () => {
      scModal.value.show()
    }
    const onMessageBlur = () => {
      if (state.value === '') state.needMessage = false
    }
    const showMessageText = () => {
      state.needMessage = true
      nextTick(() => {
        messageText.value.focus()
      })
    }

    const onConfirmInfo = (authInfo) => {
      state.authInfo = authInfo.authentications
    }

    const onClickRemove = index => {
      const auths = props.signer.authentications
      auths.splice(index, 1)
    }

    const updateMessage = () => {
      emit('update:message', state.textValue)
    }

    return {
      scModal,
      signTip,
      orderWord,
      onClickScBtn,
      onMessageBlur,
      messageText,
      showMessageText,
      onConfirmInfo,
      onClickRemove,
      updateMessage,
      ...toRefs(props),
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.signer-info {
  padding: 8px 0;
  height: auto;
  color: #777;
  display: flex;
  justify-content: space-between;

  span {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:nth-child(2) {
      text-align: right
    }
  }
}

.sub-tit {
  display: flex;
  align-items: center;

  .left-info {
    flex: 1;
  }

  .ico {
    margin-right: 15px;
  }

  &:nth-of-type(2) {
    margin-top: 15px;
  }

  .remove-btn {
    cursor: pointer;
    color: #CCC;
    transition: all 0.3s;
    @media (hover: hover) {
      &:hover {
        color: #000;
      }
    }
  }

  .roleName {
    margin-left: 5px;
    font-size: 12px;
    color: #4a98ff;
  }
}
</style>
<style>

.ant-custom .ant-input {
  padding-right: 40px
}

</style>
