import { useStore } from 'vuex'

const PARTIAL_CANCELLATION_CODE = [70, 71]

export const getMainPayment = paymentList => {
  if (paymentList === null || paymentList === undefined) return null
  let result = paymentList[0]
  paymentList.forEach(payment => {
    if (!PARTIAL_CANCELLATION_CODE.includes(payment.payState)) {
      result = payment
      return false
    }
  })
  return result
}

export const getPartialCancellation = paymentList => {
  const result = []
  if (paymentList === null) return null
  paymentList.forEach(payment => {
    if (PARTIAL_CANCELLATION_CODE.includes(payment.payState)) {
      result.push(payment)
    }
  })
  if (result.length < 1) return null
  return result
}

export const getPayStateMsg = paymentList => {
  const mainPayment = getMainPayment(paymentList)
  let msg = mainPayment.payStateMsg
  if (paymentList != null) {
    paymentList.forEach(payment => {
      if (PARTIAL_CANCELLATION_CODE.includes(payment.payState) && mainPayment.payState === 4) {
        msg = payment.payStateMsg
        return false
      }
    })
  }
  return msg
}

export const payCancelPermission = record => {
  const store = useStore()
  const paymentList = record.payments
  if (!paymentList) return false
  if (store.state.user.userInfo.userId !== record.userId) return false
  const payment = getMainPayment(paymentList)
  return payment.payState === 4
}

export const payKakaoPermission = record => {
  if (record.status !== 'completed') return false

  const store = useStore()
  if (store.state.user.userInfo.userId !== record.userId) return false
  if (store.state.user.userInfo.linkedService !== 'payapp') return false

  return !record.payments
}

export const payKakaoResendPermission = record => {
  if (record.status !== 'completed') return false

  const store = useStore()
  if (store.state.user.userInfo.userId !== record.userId) return false
  if (store.state.user.userInfo.linkedService !== 'payapp') return false

  const paymentList = record.payments
  if (!paymentList) return false
  const payment = getMainPayment(paymentList)
  return payment.payState === 1
}
