<template>
  <div class="attachments-wrap">
    <a-spin :spinning="showSping" size="large">
      <div class="attachments-box">
        <div v-for="item in attachments" :key="item.curAttachements" class="frame-wrap">
          <div class="item">
            <div class="txt-box">
              <div style="display: flex; justify-content: flex-start">
                <h3>{{ item.request.attachmentType }}</h3>
                <span :class="item.request.required ? 'essential' : 'select' " class="mark">{{ item.request.required ? '필수' : '선택' }}</span>
              </div>
              <p>{{ item.request.description }}</p>
            </div>
            <!--
            <a-upload
              v-if="!item.file"
              accept="image/., .pdf, .ppt, .doc, .xls, .hwp, .txt"
              :beforeUpload="(file) => beforeUpload(item, file)"
              :showUploadList="false">
              <a-button class="" type="primary" >첨부하기</a-button>
            </a-upload>
            -->
            <div v-if="!item.file">
              <label :for="item.attachmentId">
                <div class="upload-button">첨부하기</div>
              </label>
              <input :id="item.attachmentId" accept="*" class="file-input" name="" type="file" @change="(file) => uploadFile(item, file)">
            </div>
            <div v-else class="file-name">
              <span class="name">{{ fileName(item.file.name) }}</span>
              <span>{{ fileExtension(item.file.name) }}</span>
              <button type="button" @click="removeAttachment(item)"><i class="ico ico-file-delete"></i></button>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { createVNode, reactive, toRefs } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { deleteAttachment, updateAttachment, uploadAttachemnt } from 'api/sign'
import { file2Base64 } from 'utils/helper'
import { Modal } from 'ant-design-vue'

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB
const TOTAL_MAX_FILE_SIZE = 50 * 1024 * 1024 // 50MB

export default {
  props: ['attachments', 'documentId', 'isFinishAttachments'],
  setup (props) {
    const state = reactive({
      showSping: false
    })

    const uploadFile = (item, files) => {
      const file = files.target.files[0]
      console.log(file)
      beforeUpload(item, file)
    }

    const beforeUpload = (attachment, file) => {
      (async () => {
        const fileBase64 = await file2Base64(file)
        if (file.size > MAX_FILE_SIZE) {
          Modal.error({ content: '파일의 크기가 너무 큽니다(10MB까지 업로드 가능)' })
          state.showSping = false
          return false
        }

        let totalFileSize = 0
        props.attachments.forEach(attachment => {
          if (attachment.file != null) totalFileSize += attachment.file.size
        })
        if (totalFileSize + file.size > TOTAL_MAX_FILE_SIZE) {
          Modal.error({ content: '첨부파일의 용량은 총합 50MB를 넘을 수 없습니다' })
          return false
        }
        const nameArray = file.name.split('.')
        const ext = '.' + nameArray[nameArray.length - 1]
        state.showSping = true
        await uploadAttachemnt(props.documentId, nameArray[0].normalize('NFC'), fileBase64.split(',')[1], ext)
          .then(async res => {
            const fileInfo = {
              participantId: attachment.participant.participantId,
              fileId: res.result.fileId,
              fileName: file.name.normalize('NFC')
            }
            await updateAttachment(props.documentId, attachment.attachmentId, fileInfo)
            attachment.file = {
              ...res.result,
              name: file.name.normalize('NFC'),
              size: file.size
            }
          })
          .finally(() => {
            state.showSping = false
          })
      })()
      return false
    }
    const removeAttachment = attachment => {
      Modal.confirm({
        title: '첨부파일 삭제',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, '첨부파일을 삭제하시겠습니까?'),
        onOk () {
          state.showSping = true
          deleteAttachment(props.documentId, attachment.participant.participantId, attachment.attachmentId).then(res => {
            if (res.code === 0) attachment.file = null
          }).finally(() => {
            state.showSping = false
          })
        }
      })
    }
    const fileName = (filename) => {
      const lastDot = filename.lastIndexOf('.')
      const fileExtension = filename.substring(0, lastDot)
      return fileExtension
    }

    const fileExtension = (filename) => {
      const fileLength = filename.length
      const lastDot = filename.lastIndexOf('.')
      const fileExtension = filename.substring(lastDot, fileLength)
      return fileExtension
    }
    return {
      uploadFile,
      beforeUpload,
      removeAttachment,
      fileName,
      fileExtension,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.upload-button {
  background: #1890ff;
  padding: 0.5rem 2rem;
  color: #fff;
  font-size: 1.4rem;
  cursor: pointer;
}

.attachments-wrap {
  position: relative;
  margin: 20px auto;
  width: 100%;
  max-width: 816px;
}

.attachments-box {
  width: 100%;
}

/* file*/
.attachments-box .frame-wrap {
  width: 100%;
  padding: 4.5rem;
  margin-bottom: 1.2rem
}

.attachments-box .frame-wrap .item {
  display: flex;
  justify-content: space-between;
}

.attachments-box .frame-wrap .item .txt-box {
  width: 65%;
}

.attachments-box .frame-wrap h3 {
  font-size: 2rem;
  font-weight: 400;
  float: left;
  margin-right: 1rem;
  padding-top: 0.2rem;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attachments-box .frame-wrap .mark {
  font-size: 1.5rem;
  border-radius: 2rem;
  height: 2.5rem;
  line-height: 2.4rem;
  padding: 0 1.5rem;
  word-break: keep-all;
}

.attachments-box .frame-wrap span.essential {
  background: #ff4d4e;
  color: #fff;
}

.attachments-box .frame-wrap span.select {
  background: #999;
  color: #fff;
}

.attachments-box .frame-wrap p {
  margin-top: 1rem;
  font-size: 1.4rem
}

.attachments-box .frame-wrap .btn {
  min-width: 16rem;
}

.attachments-box .frame-wrap .file-name {
  position: relative;
  width: 30%;
  padding: 0 5% 0 0;
  font-size: 1.4rem;
  display: flex;
  justify-content: flex-end;
}

.attachments-box .frame-wrap .file-name .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50px;
  margin-right: 0
}

.attachments-box .frame-wrap .file-name span {
  display: inline-block;
  margin-right: 12px;
  min-width: 20px

}

.attachments-box .frame-wrap .file-name i {
  margin-top: -1rem;
  margin-left: 1rem;
  position: absolute;
  top: 5px;
  right: 0;
}

@media (hover: hover) {
  .attachments-box .frame-wrap .file-name button:hover {
    opacity: 0.6;
  }
}

@media screen and (max-width: 992px) {
  .attachments-wrap {
    width: 95%;

    .attachments-box {
      .frame-wrap {
        padding: 10px 5px;
        // height: 80px;
        height: auto;
      }
    }

    .attachments-box .frame-wrap .item {
      align-items: center;

      .txt-box {
        margin-right: 8px;
      }
    }

    .attachments-box .frame-wrap .mark {
      font-size: 12px;
      border-radius: 10px;
      padding: 0px 8px;
    }
  }
}

.file-input {
  display: none;
}
</style>
