<template>
  <div ref="scroll" class="pdf-previewer">
    <div v-for="index in pdfNums" :key="index" :class="{'current-page': currentPage === index}" class="pdf-card" @click="onClickPage(index)">
      <div class="layer"></div>
      <span class="page-index">{{ index }}</span>
      <canvas :id="`canvas-${index}`"></canvas>
      <FieldTip v-if="mode === 'signing' && matchField(index)" :field="matchField(index)" :signingOrder="participant.signingOrder" class="field-tip"/>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
// import { usePdfPreviewer } from 'use/sign/usePdfPreviewer'
import FieldTip from 'components/signing/signing/FieldTip'

export default {
  // mode: reading or signing
  props: ['participant', 'fields', 'mode', 'pdfNums'],
  components: { FieldTip },
  setup (props, { emit }) {
    const scroll = ref(null)
    const state = reactive({
      currentPage: 1
    })
    const onClickPage = (index) => {
      state.currentPage = index
      emit('clickPreviewer', index)
    }
    const matchField = pageIndex => {
      return props.fields.find(item => item.locationPage === pageIndex) || null
    }
    const setCurrentPage = (pageIndex) => {
      state.currentPage = pageIndex
      const card = document.querySelector('.current-page')
      scroll.value.scrollTo(0, card.offsetTop - card.clientHeight)
    }

    return {
      scroll,
      onClickPage,
      matchField,
      setCurrentPage,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.pdf-previewer {
  background: #fff;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .pdf-card {
    position: relative;
    margin-bottom: 5px;
    width: 100px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    .layer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      transition: background 0.4s;
    }

    .page-index {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 16px;
      font-weight: 500;
    }

    @media (hover: hover) {
      &:hover {
        .layer {
          background: rgba(0, 0, 0, 0.1)
        }
      }
    }

    .field-tip {
      position: absolute;
      top: 35%;
      width: 50%;
      text-align: center;
      margin-left: 25%;
    }
  }

  .current-page {
    .layer {
      background: none;
      border: 2px solid #1890ff;
    }
  }
}
</style>
