<template>
  <div id="editPage" class="signing-e-wrapper" style="font-size: 16px;">
    <!-- //e-header -->
    <div class="signing-e-header">
      <div class="sign-steps">
        <UcsSteps :currentStep="currentStep" :steps="steps"/>
      </div>
    </div>
    <!-- //e-container -->
    <div class="e-container">
      <signStepOne
        v-show="currentStep === 0"
        ref="stepOne"
        v-model:isSequential="isSequential"
        v-model:processType="processType"
        @removeSigner="onRemoveSigner"
      />
      <EditPdf
        v-if="editingData.length > 0 "
        v-show="currentStep === 1"
        ref="editPdf"
        v-model:pdfState="pdfState"
        :isSequential="isSequential"
        :isShowField="currentStep === 1"
        :processType="processType"
        @changeDocFile="onChangeDocFileModalShow"
      />
      <signStepThree
        v-if="currentStep === 2"
        ref="stepThreeComp"
        :isSequential="isSequential"
        :processType="processType"
      />
    </div>

    <!-- //e-footer -->
    <div class="e-footer">
      <div class="e-inner-sm">
        <div class="btn-row">
          <div class="btn-table">
            <a-button class="btn btn-gray-line" @click="goBack">{{ backBtnText }}</a-button>
            <a-button :class="dangerNext ? 'btn-disabled' : ''" :disabled="disableNext" class="btn" type="primary" @click="goNext">{{ stepButtonText }}</a-button>
          </div>
        </div>
      </div>
    </div>
    <!-- //e-footer -->
  </div>
  <div class="float-btn" @click="openBusinessGuide">
    사업자 계약서 유의사항 보기
  </div>
  <!--<SignPayTutorialModal ref="signPayTutorialModal"/>-->
  <FinishModal ref="finishModal" :isSequential="isSequential" :processType="processType" @finish="onFinish"/>
  <SimpleModal ref="backTipModal" okText="나가기" @confirm="onConfirmBack">
    <template #title>
      <h3>나가기</h3>
    </template>
    <template #textContent>
      <p>지금 나가면 작성한 모든 내용을 잃게 됩니다.</p>
      <p>정말 나가시겠습니까?</p>
    </template>
  </SimpleModal>
  <SimpleModal ref="unwrittenModal" :width="520">
    <template #title>
      <div class="cus-icon">
        <slot name="cusIcon">
          <ExclamationCircleFilled class="icon"/>
        </slot>
      </div>
      <h3>서명조건 미입력</h3>
    </template>
    <template #textContent>
      모든 서명 참여자의 필수 서명란이 1개 이상 추가되어 있어야 합니다.<br/>
      추가내용 입력하기 > 사인/도장 입력하기를 추가한 경우, 실제 서명까지 넣어주세요.
    </template>
    <template #footer>
      <a-button class="btn" type="primary" @click="unwrittenModal.hide()">확인</a-button>
    </template>
  </SimpleModal>
  <SimpleModal ref="changeDocFileModal" cancelText="취소" okText="변경하기">
    <template #title>
      <h3>문서 변경하기</h3>
    </template>
    <template #content>
      변경할 문서 형식에 따라 추가된 필드의 크기 및 위치가 달라질 수 있으니 문서 변경 후 확인 후 이용해 주세요.
    </template>
    <template #footer>
      <a-button class="btn btn-gray-line" @click="onChangeDocFileModalHide">취소</a-button>
      <a-upload
        :beforeUpload="beforeUpload"
        :showUploadList="false"
        accept=".hwp, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .jpg, .jpge, .png, .ppts, .docs"
      >
        <a-button class="btn btn-blue">변경하기</a-button>
      </a-upload>
    </template>
  </SimpleModal>
</template>

<script>
import { computed, inject, nextTick, onBeforeMount, onUnmounted, reactive, ref, toRefs } from 'vue'
import signStepOne from './signStepOne'
import { useStore } from 'vuex'
import EditPdf from './editPdf'
import signStepThree from './signStepThree'
import FinishModal from 'components/signing/FinishModal'
import { createDocument, uploadFile } from 'api/sign'
import { formatPdfData, regenerateSignData, revertSignData } from 'components/signing/createForm/createForm'
import { useRoute, useRouter } from 'vue-router'
import { getTemplateDetail } from 'api/template'
import { usePdf } from 'use/sign/usePdf'
import { Modal } from 'ant-design-vue'
import SimpleModal from 'components/common/SimpleModal'
import UcsSteps from 'components/common/UcsSteps.vue'
import { validEmail, validMobilePhone, validPureMobilePhone } from 'utils/validate'
import { ExclamationCircleFilled } from '@ant-design/icons-vue'
import { DocumentProcessTypes } from 'utils/commonValue'
import { getFile } from 'api/file'
import { file2Base64 } from 'utils/helper'

export default {
  components: {
    signStepOne,
    EditPdf,
    signStepThree,
    FinishModal,
    SimpleModal,
    UcsSteps,
    ExclamationCircleFilled
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const finishModal = ref(null)
    const globalSpin = inject('globalSpin')
    const backTipModal = ref(null)
    // const signPayTutorialModal = ref(null)
    const stepOne = ref(null)
    const editPdf = ref(null)
    const stepThreeComp = ref(null)
    const isPayappLinked = store.state.user.userInfo.linkedService === 'payapp'
    const unwrittenModal = ref(null)
    const changeDocFileModal = ref(null)
    const state = reactive({
      count: 0,
      current: 0,
      currentStep: 0,
      steps: ['참여자 설정', '서명 입력 설정', isPayappLinked ? '문서/결제 설정' : '문서 설정'],
      editingData: [],
      processType: DocumentProcessTypes.PROCEDURE,
      isSequential: true,
      changeDocFileVis: false,
      uploadFile: null
    })

    const onFinish = ({
      folderId,
      teamId
    }) => {
      finishModal.value.isLoading = true
      const documentInfo = { ...store.state.documents.documentInfo }
      const participants = [...store.state.documents.signers]
      const pdfData = { ...store.state.documents.pdfData }
      const groups = { ...store.state.documents.fieldGroup }
      const {
        requesterInputs,
        fields
      } = formatPdfData(pdfData, pdfData, groups)
      const signPayment = { ...store.state.documents.pay }

      requesterInputs.forEach(field => {
        if (field.locationX > 1 || field.locationY > 1) {
          finishModal.value.isLoading = false
          Modal.error({ content: `필드 좌표값 이상이 감지되었습니다. 해당 필드를 다시 설정해 주시기 바랍니다.(페이지 : ${field.locationPage}페이지, 필드이름 : ${field.fieldName}, 가로좌표 : ${field.locationX}, 세로좌표 : ${field.locationY}` })
          return false
        } else if (field.sizeHeight > 1 || field.sizeWidth > 1) {
          finishModal.value.isLoading = false
          Modal.error({ content: `필드 사이즈 이상이 감지되었습니다. 해당 필드를 다시 설정해 주시기 바랍니다.(페이지 : ${field.locationPage}페이지, 필드이름 : ${field.fieldName}, 가로좌표 : ${field.sizeWidth}, 세로좌표 : ${field.sizeHeight}` })
          return false
        }
      })

      for (const field of fields) {
        if (field.locationX > 1 || field.locationY > 1 || field.locationX < 0 || field.locationY < 0) {
          finishModal.value.isLoading = false
          Modal.error({ content: `필드 좌표값 이상이 감지되었습니다. 해당 필드를 다시 설정해 주시기 바랍니다.(페이지 : ${field.locationPage}페이지, 필드이름 : ${field.fieldName}, 가로좌표 : ${field.locationX}, 세로좌표 : ${field.locationY}` })
          return false
        } else if (field.sizeHeight > 1 || field.sizeWidth > 1 || field.sizeHeight < 0 || field.sizeWidth < 0) {
          finishModal.value.isLoading = false
          Modal.error({ content: `필드 사이즈 이상이 감지되었습니다. 해당 필드를 다시 설정해 주시기 바랍니다.(페이지 : ${field.locationPage}페이지, 필드이름 : ${field.fieldName}, 가로좌표 : ${field.sizeWidth}, 세로좌표 : ${field.sizeHeight}` })
          return false
        }
      }

      participants.forEach((item, index) => {
        item.fields = fields.filter(field => field.signingOrder === index + 1)
      })

      if (signPayment.usePayapp) {
        signPayment.price = Number(signPayment.price.replace(/,/g, ''))
        if (state.processType === DocumentProcessTypes.PROCEDURE) {
          delete signPayment.signingOrder
          delete signPayment.recvPhone
          participants[participants.length - 1].signPayment = signPayment
        } else if (state.processType === DocumentProcessTypes.FACE_TO_FACE) {
          const signingOrder = signPayment.signingOrder
          delete signPayment.signingOrder
          participants.find(participant => participant.signingOrder === signingOrder).signPayment = signPayment
        }
      }

      if (!state.isSequential) {
        participants.forEach(participant => {
          participant.signingOrder = 1
        })
      }

      const requestData = {
        ...documentInfo,
        processType: state.processType,
        isSequential: state.isSequential,
        configExpireMinute: documentInfo.configExpireMinute * 24 * 60,
        participants,
        requesterInputs
      }
      if (folderId) requestData.folderId = folderId
      if (teamId) requestData.teamId = teamId

      if (route.query.customValue) requestData.customValue = decodeURIComponent(route.query.customValue)
      if (route.query.customValue1) requestData.customValue1 = decodeURIComponent(route.query.customValue1)
      if (route.query.customValue2) requestData.customValue2 = decodeURIComponent(route.query.customValue2)
      if (route.query.customValue3) requestData.customValue3 = decodeURIComponent(route.query.customValue3)
      if (route.query.customValue4) requestData.customValue4 = decodeURIComponent(route.query.customValue4)
      if (route.query.customValue5) requestData.customValue5 = decodeURIComponent(route.query.customValue5)

      createDocument(requestData).then(res => {
        if (route.query.redirectUrl) {
          let link = route.query.redirectUrl
          if (!route.query.redirectUrl.includes(encodeURIComponent('?'))) link += '?'
          link += `&documentId=${res.result.documentId}&action=document_creating`
          window.location.href = decodeURIComponent(link)
        } else {
          const userInfo = store.state.user.userInfo
          if (requestData.reservationDate !== null) {
            store.state.app.immediateSignPop = 3
          } else if (state.processType === DocumentProcessTypes.FACE_TO_FACE) {
            store.state.app.immediateSignDocumentId = res.result.documentId
            store.state.app.immediateSignPop = 5
          } else if (requestData.participants[0].signingContactInfo.toLowerCase() === userInfo.email.toLowerCase() || requestData.participants[0].signingContactInfo === userInfo.phone) {
            store.state.app.immediateSignDocumentId = res.result.documentId
            store.state.app.immediateSignPop = 4
          } else if (signPayment.usePayapp === true) {
            store.state.app.immediateSignPop = 2
          } else {
            store.state.app.immediateSignPop = 1
          }
          const noti = store.state.user.userInfo.notifications
          if (signPayment.usePayapp === true && !noti.requestKakaoPayment) {
            store.state.app.firstRequestPaymentPop = true
          }
          router.replace({ name: 'Home' })
        }
      }).catch((error) => {
        Modal.error({
          title: error.msg || 'Create Failed.'
        })
      }).finally(() => {
        finishModal.value.isLoading = false
      })
    }
    const goNext = () => {
      if (state.currentStep === 0) {
        if (!state.isSequential) {
          const participants = [...store.state.documents.signers]
          const requesterNum = participants.filter(participant => participant.signingContactInfo === store.state.user.userInfo.email || participant.signingContactInfo === store.state.user.userInfo.phone).length
          if (requesterNum >= 2) {
            Modal.error({ content: '동시 서명 문서에서 서명 참여자에 요청자가 2번 이상 참여할 수 없습니다.' })
            return false
          }
        }
        const orderMap = stepOne.value.refreshOrder()
        refreshFields(orderMap)
        editPdf.value.startKeyboardEvent()
        editPdf.value.checkParticipantsSignFields()
        state.currentStep++
      } else if (state.currentStep === 1) {
        if (!store.state.documents.isAllSignFilled || !store.state.documents.isRequesterSigned) {
          unwrittenModal.value.show()
          return false
        } else {
          editPdf.value.stopKeyboardEvent()
          state.currentStep++
        }
      } else if (state.currentStep === 2) {
        const needPassword = store.state.documents.documentInfo.needPassword
        finishModal.value.show(needPassword)
      }
    }
    const goBack = () => {
      if (state.currentStep === 0) {
        backTipModal.value.show()
      } else if (state.currentStep === 1) {
        editPdf.value.stopKeyboardEvent()
        state.currentStep--
      } else if (state.currentStep === 2) {
        editPdf.value.startKeyboardEvent()
        state.currentStep--
      }
    }

    const backBtnText = computed(() => {
      return state.currentStep === 0 ? '나가기' : '뒤로 가기'
    })

    const disableNext = computed(() => {
      if (state.currentStep === 0) {
        const hasUnfinishedInfo = store.state.documents.signers.findIndex(item => !item.validated)
        return hasUnfinishedInfo !== -1 || store.state.documents.signers.length < 1
      }
      if (state.currentStep === 2) {
        const {
          needExpireReminder,
          configExpireReminderDay,
          configExpireMinute,
          needPassword,
          documentPassword,
          needReservation,
          reservationDate
        } = store.state.documents.documentInfo
        const pay = store.state.documents.pay
        if (!configExpireMinute) return true
        if (needPassword && (documentPassword.length < 8 || documentPassword.length > 32)) return true
        if (stepThreeComp.value && stepThreeComp.value.allowEditName) {
          return true
        } else if (stepThreeComp.value && stepThreeComp.value.disableConfirmName) {
          return true
        }
        if (pay.usePayapp) {
          if (pay.productName.length < 1 && pay.productName.length >= 20) return true
          if (pay.price < 1000) return true
          if (state.processType === DocumentProcessTypes.FACE_TO_FACE) {
            if (!pay.signingOrder) return true
            if (!validPureMobilePhone(pay.recvPhone)) return true
          }
        }
        if (needReservation && !reservationDate) return true
        if (needExpireReminder && (!configExpireReminderDay || configExpireReminderDay >= configExpireMinute)) return true
      }
      return false
    })

    const dangerNext = computed(() => {
      return state.currentStep === 1 && (!store.state.documents.isAllSignFilled || !store.state.documents.isRequesterSigned)
    })

    const stepButtonText = computed(() => {
      if (state.currentStep === 2) {
        return '설정 완료'
      } else {
        return '다음 단계로'
      }
    })

    const formatSigners = (participants) => {
      const signers = participants.map(item => {
        let attachments = []
        if (item.attachments.length > 0) {
          attachments = item.attachments.map(item => {
            return {
              attachmentType: item.request.attachmentType,
              description: item.request.description,
              required: item.request.required
            }
          })
        }
        let validation = true
        if (item.name < 1 || !item.signingContactInfo) {
          validation = false
        }
        if (!item.signingContactInfo) {
          validation = false
        } else if (!validEmail(item.signingContactInfo) && !validPureMobilePhone(item.signingContactInfo) && !validMobilePhone(item.signingContactInfo)) {
          validation = false
        }
        return {
          roleName: item.roleName,
          name: item.name,
          signingMethodType: item.signingMethodType || 'email',
          signingOrder: item.signingOrder,
          signingContactInfo: item.signingContactInfo,
          message: item.message || '',
          attachments,
          dragKey: item.participantId,
          validated: validation
        }
      })
      store.dispatch('updateSigners', signers)
    }

    const isFromTemplate = Boolean(route.params.documentId)
    const {
      pdfState,
      loadPdf,
      destroyPdf,
      renderPdf,
      renderPdfPreviewer
    } = usePdf()
    const initTemplatePage = async () => {
      globalSpin.show()
      try {
        store.dispatch('resetEditData')
        if (isFromTemplate) { // editing template
          const res = await getTemplateDetail(route.params.documentId)
          state.processType = res.result.processType || DocumentProcessTypes.PROCEDURE
          const currentPdf = {
            name: res.result.name,
            fileId: res.result.fileId
          }
          await store.dispatch('setCurrentPdf', currentPdf)
          formatSigners(res.result.participants)
          const fileRes = await getFile({ documentId: route.params.documentId })
          if (fileRes.result) {
            const loadPdfResult = await loadPdf(atob(fileRes.result.file))
            if (loadPdfResult.length > 0) {
              const {
                formattedPdfData,
                fieldGroup
              } = revertSignData(loadPdfResult, res.result.requesterInputs, res.result.participants)
              await store.dispatch('setPdfData', formattedPdfData)
              await store.dispatch('setFieldGroup', fieldGroup)
              await store.dispatch('pushHistoryStorage')
              state.editingData = formattedPdfData
            }
            renderPdf()
            renderPdfPreviewer()
          } else {
            Modal.error({
              title: '문서 불러오기에 실패했습니다',
              onOk () {
                router.back()
              }
            })
          }
        } else { // create template
          const fileRes = await getFile({ fileId: store.state.documents.currentPdf.fileId })
          if (fileRes.result) {
            const loadPdfResult = await loadPdf(atob(fileRes.result.file))
            state.editingData = loadPdfResult.map(item => {
              item.fields = []
              item.requesterFields = []
              return item
            })
            await store.dispatch('setPdfData', state.editingData)
            await store.dispatch('pushHistoryStorage')
            nextTick(async () => {
              renderPdf()
              renderPdfPreviewer()
              // if (store.state.notice.hideSignCreatingTutorial !== true) {
              //   signPayTutorialModal.value.show()
              // }
            })
          } else {
            Modal.error({
              title: '문서 불러오기에 실패했습니다',
              onOk () {
                router.back()
              }
            })
          }
        }
      } catch (error) {
        console.error(error)
        Modal.error({
          title: error.message || 'Data load failed.',
          onOk () {
            router.back()
          }
        })
      } finally {
        globalSpin.hide()
      }
    }

    const refreshFields = (orderMap) => {
      store.state.documents.pdfData.forEach(pdf => {
        if (pdf.fields.length > 0) {
          pdf.fields.forEach(field => {
            field.signingOrder = orderMap[field.dragKey]
          })
        }
      })
    }

    const onRemoveSigner = (dragKey) => {
      store.state.documents.pdfData.forEach(pdf => {
        if (pdf.fields.length > 0) {
          pdf.fields = pdf.fields.filter(field => field.dragKey !== dragKey)
        }
      })
    }

    onBeforeMount(() => {
      store.dispatch('resetEditData')
      initTemplatePage()
    })

    onUnmounted(() => {
      store.state.documents.signers = []
    })

    const openBusinessGuide = () => {
      window.open('https://ucansign.oopy.io/10e36215-c58f-80f0-84ea-d9f71a4fafeb', '_blank')
    }

    const onChangeDocFileModalShow = () => {
      changeDocFileModal.value.show()
    }

    const onChangeDocFileModalHide = () => {
      changeDocFileModal.value.hide()
    }

    const beforeUpload = async file => {
      const limitSize = 5 * 1024 * 1024
      if (file.size > limitSize) {
        Modal.error({
          content: '파일 업로드 최대 용량은 5MB 입니다.'
        })
        return false
      } else {
        destroyPdf()
        state.uploadFile = file
        changeDocFileModal.value.hide()
        const formatFile = await file2Base64(state.uploadFile)
        const nameArray = state.uploadFile.name.split('.')
        uploadFile({
          base64: formatFile.split(',')[1],
          ext: '.' + nameArray[nameArray.length - 1]
        }).then(async res => {
          globalSpin.show()
          if (res) {
            const currentPdf = {
              name: state.uploadFile.name,
              fileId: res.result.fileId
            }
            await store.dispatch('setCurrentPdf', currentPdf)
            const fileRes = await getFile({ fileId: res.result.fileId })
            if (fileRes.result) {
              await store.dispatch('setPdfData', [])
              const loadPdfResult = await loadPdf(atob(fileRes.result.file))
              const prevData = state.editingData
              const newData = loadPdfResult.map(item => {
                item.fields = []
                item.requesterFields = []
                return item
              })
              console.log(prevData, newData)
              const { newPdfData } = regenerateSignData(prevData, newData)
              state.editingData = newPdfData
              await store.dispatch('setPdfData', state.editingData)
              await nextTick(() => {
                const group = store.state.documents.fieldGroup
                store.dispatch('resetEditData')
                store.dispatch('setFieldGroup', group)
                store.dispatch('pushHistoryStorage')
                renderPdf()
                renderPdfPreviewer()
                nextTick(() => {
                  editPdf.value.setting()
                })
              })
            } else {
              Modal.error({
                title: '문서 불러오기에 실패했습니다',
                onOk () {
                  router.back()
                }
              })
            }
          }
        }).catch(error => {
          console.error(error)
          Modal.error({
            content: error.msg
          })
        }).finally(() => {
          globalSpin.hide()
        })
      }
    }
    const onConfirmBack = () => {
      if (route.query.redirectUrl) {
        let link = route.query.redirectUrl
        if (!route.query.redirectUrl.includes(encodeURIComponent('?'))) link += '?'
        link += '&action=document_creating_fail'
        window.location.href = decodeURIComponent(link)
      } else {
        router.back()
      }
    }

    return {
      onFinish,
      goBack,
      goNext,
      finishModal,
      disableNext,
      dangerNext,
      stepButtonText,
      backBtnText,
      backTipModal,
      unwrittenModal,
      changeDocFileModal,
      // signPayTutorialModal,
      stepOne,
      editPdf,
      onRemoveSigner,
      stepThreeComp,
      openBusinessGuide,
      ...toRefs(state),
      pdfState,
      onChangeDocFileModalShow,
      onChangeDocFileModalHide,
      beforeUpload,
      onConfirmBack
    }
  }
}
</script>

<style lang="less" scoped>

.btn-table {
  display: flex;
  justify-content: space-between;

  .btn {
    width: 165px;
  }
}

.cus-icon {
  margin-top: 16px;
  text-align: center;

  .icon {
    font-size: 60px;
    color: #1890ff;
  }
}

.float-btn {
  position: fixed;
  border-radius: 2px;
  background: #ff4d4f;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
  color: #fff;
  cursor: pointer;
  padding: 1.2rem 2rem;
  display: flex;
  font-size: 1.5rem;
  user-select: none;
  //align-items: center;
  //justify-content: center;
  //width: 220px;
  //height: 65px;
}
</style>
