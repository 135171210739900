import { computed } from 'vue'

export const useSignerOrder = (props) => {
  const signTip = computed(() => {
    const singerTip = ['첫 번째 서명 참여자', '두 번째 서명 참여자', '세 번째 서명 참여자', '네 번째 서명 참여자', '다섯 번째 서명 참여자']
    return singerTip[props.signer.signingOrder - 1]
  })
  const orderWord = computed(() => {
    return ['A', 'B', 'C', 'D', 'E'][props.signer.signingOrder - 1]
  })

  return {
    signTip,
    orderWord
  }
}
