<template>
  <div class="sign-wrap" @click="onClickSignBtn('before')" v-if="hasSignature && hasStamp && !writeSign">
    <a-popover :trigger="trigger" :zIndex="990">
      <template #content>
        <div>
          <a @click="onClickSignBtn('sign')">사인</a>
          <a-divider type="vertical"/>
          <a @click="onClickSignBtn('stamp')">도장</a>
        </div>
      </template>
      <div class="innerText" :class="cls">
        <span>서</span>
        <span>명</span>
      </div>
    </a-popover>
  </div>
  <div class="sign-wrap" @click="onClickSignBtn('after')" v-else-if="hasSignature && hasStamp && writeSign">
    <div class="innerText" :class="cls">
      <span>서</span>
      <span>명</span>
    </div>
  </div>
  <div class="sign-wrap" @click="onClickSignBtn('sign')" v-else-if="hasSignature">
    <div class="innerText" :class="cls">
      <span>사</span>
      <span>인</span>
    </div>
  </div>
  <div class="sign-wrap" @click="onClickSignBtn('stamp')" v-else-if="hasStamp">
    <div class="innerText" :class="cls">
      <span>도</span>
      <span>장</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'

export default {
  props: ['field', 'signedData'],
  setup (props, { emit }) {
    const isMobile = useMediaQuery('(max-width: 768px)')

    const cls = computed(() => {
      return { 'e-sure': props.field.required }
    })

    const hasSignature = computed(() => {
      return props.field.allowSignatureTypes.includes('SIGN')
    })
    const hasStamp = computed(() => {
      return props.field.allowSignatureTypes.includes('STAMP')
    })
    const trigger = computed(() => {
      return isMobile.value ? 'click' : 'hover'
    })

    const writeSign = computed(() => {
      return props.signedData !== null && props.signedData !== undefined
    })

    const onClickSignBtn = (signType) => {
      emit('clickSignBtn', {
        field: props.field,
        signType
      })
    }

    return {
      cls,
      isMobile,
      hasSignature,
      hasStamp,
      trigger,
      writeSign,
      onClickSignBtn
    }
  }
}
</script>

<style lang="less" scoped>
.sign-wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 144, 255, 0.2);
  cursor: pointer;

  .innerText {
    padding: 0 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: #1890ff;
    font-size: 1.4rem;
    font-weight: 500;

    @media only screen and (max-width: 765px) {
      font-size: 1.0rem;
    }
  }

  .innerText:after {
    content: inherit;
  }
}
</style>
