<template>
  <div class="e-cont">
    <div class="e-inner-sm">
      <!-- 영역 1 -->
      <div class="box">
        <div class="box-body">
          <div class="b-tit sub-tit">
            <h3>문서 정보</h3>
          </div>
          <!-- 문서이름 -->
          <div class="form-group">
            <label class="sr-only" for="docuName">문서이름</label>
            <a-input v-model:value="document.name" :lazy="false" placeholder="문서이름을 입력해 주세요"/>
            <div v-if="disableConfirmName" style="color: #F00">2자 이상 100자 이하로 입력해 주세요</div>
          </div>
          <!-- end 문서이름 -->
          <!-- 서명 유효기간 설정 -->
          <div class="form">
            <div class="row">
              <div class="col-6">
                <label class="form-tit">서명 유효기간 설정</label>
              </div>
              <div class="col-6 text-right">
                <a-input-number v-model:value="document.configExpireMinute" :max="365" :min="1" class="form-control input-num" style="text-align: center; width: 68px;" type="number"/>
                일
              </div>
            </div>
          </div>
          <!-- end 서명 유효기간 설정 -->
          <!-- 계약만료 알림 설정 -->
          <div v-if="processType !== DocumentProcessTypes.FACE_TO_FACE" class="form">
            <div class="row">
              <div class="col-6">
                <label class="form-tit">계약만료 알림 설정</label>
              </div>
              <div class="col-6 text-right">
                <a-switch v-model:checked="document.needExpireReminder" size="small" @change="setReminder"></a-switch>
              </div>
            </div>
          </div>
          <div v-if="document.needExpireReminder" class="lock-wrap">
            <p class="form-guide">서명 유효기간 만료일 &nbsp;&nbsp;<span><a-select v-model:value="document.configExpireReminderDay" :options="configExpireReminderDayList"/></span>전 알림 전송</p>
            <p v-if="!validateExpireReminder" class="form-warning">유효기간 만료 전에만 만료 알림을 받을 수 있습니다.</p>
          </div>
          <!-- end 계약만료 알림 설정 -->
          <!-- 완료된 문서 잠금 설정 -->
          <div class="form-line">
            <div class="row">
              <div class="col-6">
                <label class="form-tit">완료된 문서 잠금설정</label>
              </div>
              <div class="col-6 text-right">
                <a-switch v-model:checked="document.needPassword" size="small"></a-switch>
              </div>
            </div>
          </div>
          <div v-if="document.needPassword" class="lock-wrap">
            <a-input-password v-model:value="document.documentPassword" :maxlength="32" placeholder="8자 이상 32자 이하"></a-input-password>
            <p class="form-guide">서명이 모두 완료된 문서 파일을 열람할 때 비밀번호를 입력해야 합니다.</p>
          </div>
          <!-- end 완료된 문서 잠금 설정 -->
          <!-- 예약 전송 -->
          <div v-if="processType !== DocumentProcessTypes.FACE_TO_FACE" class="form-line" style="min-height:auto; padding-bottom:0">
            <div class="row">
              <div class="col-6">
                <label class="form-tit">예약 전송</label>
              </div>
              <div class="col-6 text-right">
                <a-switch v-model:checked="document.needReservation" size="small" @change="setReservationSelector"/>
              </div>
            </div>
          </div>
          <div v-if="document.needReservation" class="lock-wrap">
            <div class="form-inline" style="text-align: center;">
              <a-date-picker
                v-model:value="selectedResDate"
                :disabled-date="disabledDate"
                :showNow="false"
                format="YYYY-MM-DD"
                style="display: inline-block; width: 45%;"
                @change="changeResDate"/>
              <a-select
                v-model:value="selectedResHour"
                :disabled="!selectedResDate"
                :options="resHourList"
                placeholder="시"
                style="display: inline-block; width: 30%;"
                @select="changeResHour"
              >
              </a-select>
              <a-select
                v-model:value="selectedResMinute"
                :disabled="selectedResHour === null"
                :options="resMinuteList"
                placeholder="분"
                style="display: inline-block; width: 25%;"
                @select="changeResMinute"
              >
              </a-select>
            </div>
            <p class="res-description">
              - 예약 설정 시 서명요청 포인트를 미리 차감하며, 만약 예약 전송을 취소할 경우 차감된 포인트는 복구됩니다.<br/>
              - 예약 일시 변경/취소는 전송 예정 일시 5분 전까지만 가능합니다.<br/>
              - 설정한 일시에 예약 요청된 건수가 많은 경우 예약 시간보다 조금 늦게 발송될 수 있습니다.
            </p>
          </div>
          <!-- end 예약 전송 -->
        </div>
      </div>
      <!-- end 영역 1 -->
      <!-- 영역 2 -->
      <!-- 결제 정보 -->
      <div v-if="isPayappLinked && isSequential" class="box pay-box">
        <div class="box-body">
          <div class="form2">
            <div class="row">
              <div class="col-6">
                <label class="form-tit">
                  <SignPayIcon class="signpay-sm"/>
                </label>
              </div>
              <div class="col-6 text-right" style="vertical-align: middle;">
                <a-switch v-model:checked="pay.usePayapp" size="small" @click="switchPaymentYn"/>
              </div>
            </div>
          </div>
          <div v-if="!guideToolTip3" class="fixed-tooltip">
            <div class="txt">
              <span>결제까지 한번에 받기!</span>
              <CloseOutlined @click="closeGuideTooltip3"/>
            </div>
            <CaretDownOutlined/>
          </div>
          <div v-if="pay.usePayapp" class="form-group">
            <label class="form-tit">상품명</label>
            <a-input v-model:value="pay.productName" :maxlength="20" class="content" placeholder="상품명을 입력하세요."/>
            <label class="form-tit">결제요청 금액(단위: 원)</label>
            <a-input
              v-model:value="pay.price"
              class="content"
              placeholder="숫자만 입력하세요."
              type="tel"
              @input="changePrice"
            />
            <div v-if="processType === DocumentProcessTypes.FACE_TO_FACE">
              <label class="form-tit">참여자</label>
              <a-select v-model:value="pay.signingOrder" :options="participants" class="content" style="width: 100%"/>
              <label class="form-tit">휴대폰번호</label>
              <a-input v-model:value="pay.recvPhone" :allowClear="true" :lazy="false" :maxlength="11" class="content" placeholder="'-' 없이 숫자만 입력" type="tel" @change="validatePhoneNumber"/>
              <div v-if="!validPhoneNumberYn" class="form-tit" style="color:red">휴대폰 번호 형식이 맞지 않습니다.</div>
            </div>
          </div>
        </div>
      </div>
      <!-- end 결제 정보 -->
      <!-- end 영역 2 -->
      <!-- 영역3 -->
      <div class="signer-list">
        <SignConfirmCard
          v-for="signer in signers"
          :key="signer.signingOrder"
          v-model:message="signer.message"
          :isEnd="signer.signingOrder === signers.length"
          :processType="processType"
          :signer="signer"
        />
      </div>
      <!-- end 영역3 -->
    </div>
  </div>
  <SimpleModal ref="tooltipPaymentModal" :footerBtnYn="false">
    <template #title>
      <h3>
        <SignPayIcon class="signpay-title"/>
      </h3>
    </template>
    <template #textContent>
      <p style="font-weight: normal">
        계약 서명 이후 결제까지 추가로 받을 수 있습니다. 마지막 서명 순서의 참여자에게 결제를 요청할 수 있으며, 결제 진행상황은 [내문서 >
        <SignPayIcon class="signpay-content"/>
        ] 메뉴에서 해당 문서를 조회하여 확인할 수 있습니다.
      </p>
      <br/>
      <p style="font-weight: normal">
        추가 비용 없이 누구나 무료로 사용할 수 있고, 결제금액 정산 시, 결제 수수료(카드결제 기준 3.4%)만 요청되고, 나머지 금액이 정산됩니다.
      </p>
    </template>
    <template #footer>
      <a-button class="btn btn-blue" @click="tooltipPaymentModalConfirm">다시 보지 않기</a-button>
      <a-button class="btn btn-gray-line" @click="tooltipPaymentModalCancel">닫기</a-button>
    </template>
  </SimpleModal>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import SignConfirmCard from 'components/signing/SignerConfirmCard'
import dayjs from 'dayjs'
import { validPureMobilePhone } from 'utils/validate'
import { DocumentProcessTypes } from 'utils/commonValue'
import SimpleModal from 'components/common/SimpleModal'
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons-vue'
import SignPayIcon from 'assets/images/common/SignPayIcon'
import { setNotice } from 'api/user'
import { Modal } from 'ant-design-vue'

const configExpireReminderDayList = [
  {
    label: '7일',
    value: 7
  },
  {
    label: '3일',
    value: 3
  },
  {
    label: '1일',
    value: 1
  }
]

export default {
  components: {
    SimpleModal,
    SignConfirmCard,
    CaretDownOutlined,
    CloseOutlined,
    SignPayIcon
  },
  props: {
    processType: {
      type: String,
      default: DocumentProcessTypes.PROCEDURE
    },
    isSequential: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const store = useStore()
    const tooltipPaymentModal = ref(null)
    const {
      signers,
      currentPdf
    } = store.state.documents
    const state = reactive({
      isPayappLinked: store.state.user.userInfo.linkedService === 'payapp',
      tooltipPaymentModalYn: !store.state.notice.tooltipPaymentModal,
      document: {
        name: currentPdf.name,
        documentPassword: '',
        configExpireMinute: 14,
        needExpireReminder: false,
        configExpireReminderDay: null,
        needPassword: false,
        fileId: currentPdf.fileId,
        needReservation: false,
        reservationDate: null
      },
      pay: {
        usePayapp: false,
        serviceType: store.state.user.userInfo.linkedService,
        productName: '',
        price: '',
        recvPhone: null,
        signingOrder: signers.length
      },
      resHourList: [],
      resMinuteList: [],
      selectedResDate: null,
      selectedResHour: null,
      selectedResMinute: null,
      validPhoneNumberYn: true,
      participants: [],
      guideToolTip3: store.state.user.userInfo.notifications.guide3
    })
    store.dispatch('setDocumentInfo', state.document)
    store.dispatch('setPay', state.pay)

    const switchTextStatus = () => {
      this.allowEditName = true
    }

    onMounted(() => {
      signers.forEach(participant => {
        state.participants.push({
          value: participant.signingOrder,
          label: `${participant.name}(${participant.signingContactInfo})`
        })
      })
    })

    const getDocumentInfo = () => {
      return state.document
    }

    watch(() => state, (val, preVal) => {
      store.dispatch('setDocumentInfo', state.document)
      store.dispatch('setPay', state.pay)
    }, { deep: true })

    const disableConfirmName = computed(() => {
      return state.document.name.length < 2 || state.document.name.length > 100
    })

    const removeTooltip = () => {
      store.dispatch('setTooltipPayment', true)
      state.tooltipPaymentYn = false
    }

    const disabledDate = current => {
      return current.isBefore(dayjs().format('YYYY-MM-DD'), 'day') || current.isAfter(dayjs().add(30, 'days').format('YYYY-MM-DD'), 'day')
    }

    const changeResDate = () => {
      state.document.reservationDate = null
      state.selectedResHour = null
      state.selectedResMinute = null
      state.resHourList = []
      state.resMinuteList = []
      const currentHour = dayjs().hour()
      const currentMinute = dayjs().minute()
      for (let i = 0; i < 24; i++) {
        if (dayjs(state.selectedResDate).isSame(dayjs(), 'day') && (i < currentHour || (i === currentHour && currentMinute >= 30))) continue
        state.resHourList.push({
          label: `${i}시`,
          value: i
        })
      }
    }

    const changeResHour = () => {
      state.document.reservationDate = null
      state.selectedResMinute = null
      state.resMinuteList = []
      const isToday = dayjs(state.selectedResDate).isSame(dayjs(), 'day')
      const currentMinute = dayjs().minute()

      if (!isToday || (isToday && state.selectedResHour > dayjs().hour())) {
        state.resMinuteList.push({
          label: '0분',
          value: 0
        }, {
          label: '30분',
          value: 30
        })
      } else if (isToday && state.selectedResHour === dayjs().hour()) {
        if (currentMinute < 30) {
          state.resMinuteList.push({
            label: '30분',
            value: 30
          })
        }
      }
    }

    const changeResMinute = () => {
      state.document.reservationDate = dayjs(state.selectedResDate)
        .hour(state.selectedResHour)
        .minute(state.selectedResMinute)
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
    }

    const setReservationSelector = () => {
      state.document.reservationDate = null
      state.selectedResDate = null
      state.selectedResHour = null
      state.selectedResMinute = null
      state.reservationHourList = []
      state.reservationMinuteList = []
    }

    const setReminder = () => {
      if (!state.document.needExpireReminder) {
        state.document.configExpireReminderDay = null
      } else {
        state.document.configExpireReminderDay = 7
      }
    }

    const validateExpireReminder = computed(() => {
      return state.document.configExpireReminderDay < state.document.configExpireMinute
    })

    const changePrice = () => {
      state.pay.price = state.pay.price.replace(/^0+/, '')
      state.pay.price = state.pay.price.replace(/\D/g, '')
      state.pay.price = state.pay.price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    }

    const validatePhoneNumber = () => {
      state.pay.recvPhone = state.pay.recvPhone.replace(/\D/g, '')
      state.validPhoneNumberYn = validPureMobilePhone(state.pay.recvPhone)
    }

    const switchPaymentYn = () => {
      state.tooltipPaymentYn = false
      if (state.pay.usePayapp && state.tooltipPaymentModalYn) tooltipPaymentModal.value.show()
    }

    const tooltipPaymentModalCancel = () => {
      tooltipPaymentModal.value.hide()
    }

    const tooltipPaymentModalConfirm = () => {
      tooltipPaymentModal.value.hide()
      store.dispatch('setTooltipPaymentModal', true)
      state.tooltipPaymentModalYn = false
    }

    const closeGuideTooltip3 = () => {
      setNotice({
        type: 'guide3'
      })
        .then(() => {
          store.dispatch('userInfo').then(() => {
            state.guideToolTip3 = true
          })
        })
        .catch((err) => {
          Modal.error({ content: err.msg })
        })
    }

    return {
      DocumentProcessTypes,
      configExpireReminderDayList,
      dayjs,
      tooltipPaymentModal,
      switchTextStatus,
      signers,
      getDocumentInfo,
      disableConfirmName,
      removeTooltip,
      disabledDate,
      changeResDate,
      changeResHour,
      changeResMinute,
      setReservationSelector,
      setReminder,
      validateExpireReminder,
      changePrice,
      validatePhoneNumber,
      switchPaymentYn,
      tooltipPaymentModalCancel,
      tooltipPaymentModalConfirm,
      closeGuideTooltip3,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.e-cont {
  overflow-y: scroll;
}

.document-edit-row {
  display: flex;

  div {
    flex: 1;

    &:nth-of-type(1) {
      margin-right: 10px;
    }
  }
}

.pay-box {
  label {
    line-height: 1rem
  }

  .content {
    margin-bottom: 2rem;
  }
}

.form-group {
  margin-top: 10px;
}

.ant-input-number {
  width: 100%;
}

.switch {
  transform: scale(0.9);
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 99;
}

.res-description {
  margin-top: 10px;
  font-size: 12px;
  color: #8d8d8d;
}

.form {
  min-height: 3.5em;
  padding: 0.8em 0;
  font-size: 1.4rem;
}

.form2 {
  font-size: 1.4rem;
}

.form-line {
  border-top: 1px solid #f1f1f1;
}

.signpay-sm {
  margin-right: 0.8rem;
  width: 7rem;
}

.signpay-title {
  width: 10rem;
}

.signpay-content {
  width: 5rem;
}

.fixed-tooltip {
  position: absolute;
  top: -40px;
  left: 214px;
  width: 17rem;
  text-align: center;
  border-radius: 10rem;
  background: #27d0b0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0 1.5rem;
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-timing-function: ease;
  z-index: 1;

  .txt {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon-close {
      padding: 1rem;
      margin: -0.9rem -1rem -1rem -1rem;
    }
  }

  .anticon-caret-down {
    position: absolute;
    top: 35px;
    left: 80px;
    line-height: 0 !important;
    color: #27d0b0;
    font-size: 20px;
    transform: scale(1, 3);
  }
}

@media screen and (max-width: 768px ) {
  .fixed-tooltip {
    display: none;
  }
}

@keyframes bounce {
  0% {
    top: -40px;
  }
  50% {
    top: -35px;
  }
  100% {
    top: -40px;
  }
}
</style>
