<template>
  <a-modal
    v-model:visible="state.visible"
    :bodyStyle="{ padding: 0 }"
    :closable="false"
    :footer="null"
    class="member-modal-wrapper"
    :width="380"
    :destroyOnClose="true"
    :zIndex="10002"
  >
    <a-spin :spinning="state.isLoading">
      <div class="modal-header bg-gray">
        <h2>구성원 추가하기</h2>
        <span role="button" class="modal-close" @click="state.visible = false">닫기</span>
      </div>
      <div class="modal-content modal-set">
        <div class="modal-body">
          <h3>추가 가능 구성원 <span v-show=" state.selectedRowKeys.length">({{ state.selectedRowKeys.length }}명)</span></h3>
          <a-table :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }" :dataSource="state.members" :columns="state.columns" size="small" rowKey="memberId"
                   :pagination="pagination" @change="tableChange"/>
          <span class="commt">
            <svg
              fill="currentColor"
              stroke="currentColor"
              stroke-width="0"
              xmlns="http://www.w3.org/2000/svg"
              height="17"
              viewBox="0 0 12 17"
              width="12"
              class="css-1v2pt2d"
            >
              <path
                d="M6 4.5C2.67188 4.5 0 7.19531 0 10.5C0 13.8281 2.67188 16.5 6 16.5C9.30469 16.5 12 13.8281 12 10.5C12 7.19531 9.30469 4.5 6 4.5ZM6 15.375C3.30469 15.375 1.125 13.1953 1.125 10.5C1.125 7.82812 3.30469 5.625 6 5.625C8.67188 5.625 10.875 7.82812 10.875 10.5C10.875 13.1953 8.67188 15.375 6 15.375ZM6.9375 12.375H6.5625V10.3125C6.5625 10.0078 6.30469 9.75 6 9.75H5.25C4.92188 9.75 4.6875 10.0078 4.6875 10.3125C4.6875 10.6406 4.92188 10.875 5.25 10.875H5.4375V12.375H5.0625C4.73438 12.375 4.5 12.6328 4.5 12.9375C4.5 13.2656 4.73438 13.5 5.0625 13.5H6.9375C7.24219 13.5 7.5 13.2656 7.5 12.9375C7.5 12.6328 7.24219 12.375 6.9375 12.375ZM6 9C6.39844 9 6.75 8.67188 6.75 8.25C6.75 7.85156 6.39844 7.5 6 7.5C5.57812 7.5 5.25 7.85156 5.25 8.25C5.25 8.67188 5.57812 9 6 9Z"
              ></path>
            </svg>
            소속 팀 구성원만 폴더에 추가할 수 있습니다.
          </span>

          <div class="btn-row">
            <div class="btn-table">
              <a-button class="btn" @click="state.visible = false">취소</a-button>
              <a-button class="btn" type="primary" @click="confirm">추가</a-button>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script setup>
import { defineEmits, defineExpose, reactive } from 'vue'
import { getAvailbaleTeamMembers } from 'api/folder'
import { useStore } from 'vuex'

const store = useStore()

const state = reactive({
  visible: false,
  isLoading: false,
  selectedMembers: [],
  selectedRowKeys: [],
  selectedRows: [],
  members: [],
  columns: [
    {
      title: '이름',
      dataIndex: 'userName'
    },
    {
      title: '이메일',
      dataIndex: 'email'
    }
  ],
  pagination: {
    size: 'small',
    total: 0,
    current: 1,
    pageSize: 10
  }
})

const fetchTeamMembers = (excludeMemberIds) => {
  state.isLoading = true
  const teamId = store.state.app.selectedTeam.teamId
  getAvailbaleTeamMembers({
    teamId,
    limit: state.pagination.pageSize,
    page: state.pagination.current,
    folderId: state.folderId,
    excludeMemberIds
  }).then(res => {
    state.members = res.result.list
  }).catch(err => console.log('err', err))
    .finally(() => {
      state.isLoading = false
    })
}

const show = (folderId, excludeMemberIds) => {
  state.folderId = folderId
  fetchTeamMembers(excludeMemberIds)
  state.selectedRowKeys = []
  state.selectedRows = []
  state.visible = true
}

const onSelectChange = (selectedRowKeys, selectedRows) => {
  state.selectedRowKeys = selectedRowKeys
  state.selectedRows = selectedRows
}

const emit = defineEmits(['memberAdded'])
// const confirm = async () => {
//   state.isLoading = true
//   await addAccessMember({ members: state.selectedRowKeys, folderId: state.folderId, teamId: store.state.app.selectedTeam.teamId })
//   state.isLoading = false
//   emit('memberAdded')
//   state.visible = false
// }

const confirm = () => {
  emit('memberAdded', state.selectedRows)
  state.visible = false
}

const tableChange = (pagination) => {
  state.pagination.current = pagination.page
  fetchTeamMembers()
}

defineExpose({ show })
</script>

<style lang="less" scoped>
.member-modal-wrapper {
  .modal-header {
    padding: 2rem;
  }

  .modal-close {
    margin: 24px;
  }

  .modal-set .commt {
    background: #f7f7f7;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    width: 100%;
    display: block;
    margin: 1rem 0 0 0;
  }

  .modal-set .commt svg {
    padding-top: 0.3rem;
  }

  .btn-row {
    .btn-table {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .btn {
        width: 48%;
      }
    }
  }
}
</style>
