<template>
  <div class="sign-draw">
    <div :style="{ height: `${height}px` }" class="write-box box-wrap bg-light-gray">
      <p v-if="!isWrited" class="tip text-center text-gray">이 곳에 사인을 그려주세요.</p>
      <canvas id="writePad" @click="touchWritePad" @mousemove="checkValidation" @touchmove="checkValidation"></canvas>
    </div>
    <div class="btn-txt-group buttons" style="display: flex; justify-content: space-between;">
      <span style="color: #ff0000">{{ errorInfo }}</span>
      <button class="btn-txt text-gray text-line" type="button" @click="resetPad">모두 지우기</button>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, toRefs } from 'vue'
import SignaturePad from 'signature_pad'

export default {
  setup () {
    const state = reactive({
      signaturePad: null,
      isWrited: false,
      isConfirmed: false,
      height: 0,
      errorInfo: ''
    })
    const resizeCanvas = () => {
      var ratio = Math.max(window.devicePixelRatio || 1, 1)
      const canvas = document.getElementById('writePad')
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext('2d').scale(ratio, ratio)
      state.signaturePad.clear() // otherwise isEmpty() might return incorrect value
    }
    onMounted(() => {
      const boxWidth = document.querySelector('.write-box').clientWidth
      state.height = boxWidth / 2.588
      nextTick(() => {
        state.signaturePad = new SignaturePad(document.getElementById('writePad'))
        state.signaturePad.onBegin = () => {
          touchWritePad()
        }
        resizeCanvas()
      })
    })
    const resetPad = () => {
      state.signaturePad.clear()
      state.isWrited = false
      state.isConfirmed = false
    }
    const getSignSvg = () => {
      if (state.isConfirmed) {
        const base64Img = state.signaturePad.toDataURL('image/svg+xml')
        return `<svg width="260" height="100" version="1.1" viewBox="0 0 260 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <image width="260px" height="100px" xlink:href="${base64Img}" /></svg>`
      } else {
        return null
      }
    }

    const touchWritePad = () => {
      state.isWrited = true
    }

    const checkValidation = () => {
      const canvas = document.getElementById('writePad')
      const ctx = canvas.getContext('2d')
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const data = imageData.data
      let filledPixels = 0
      for (let i = 0; i < data.length; i += 4) {
        const alpha = data[i + 3]
        if (alpha > 0) {
          filledPixels++
        }
      }

      const totalPixels = (canvas.width * canvas.height)
      const filledRatio = (filledPixels / totalPixels) * 100
      state.isConfirmed = filledRatio.toFixed(2) > 1.5
      state.errorInfo = (state.isWrited && !state.isConfirmed) ? '사인을 충분히 그려 주세요' : ''
    }
    return {
      resetPad,
      getSignSvg,
      touchWritePad,
      checkValidation,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.sign-draw {
  margin: 0 auto;
  padding: 0 20px;
  // width: 580px;
}

.write-box {
  position: relative;
  border: 1px solid #eee;
  // width: 580px;
  // height: 220px;
  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .tip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
  }
}

.buttons {
  margin-top: 5px
}
</style>
