<template>
  <a-modal
    :closable="false"
    :footer="false"
    :keyboard="false"
    :maskClosable="false"
    :visible="visible"
    :zIndex="1000"
    centered
    @cancel="visible = false"
  >
    <div class="page">
      <h3 style="background: #2b3549; margin:-2.5rem -2.5rem 2rem -2.5rem; padding:1.2rem; color:#fff">[재공지]서버 점검 안내</h3>
      <p>안녕하세요. 유캔싸인 운영팀입니다.</p>
      <p>보다 나은 서비스 환경 제공을 위해 정기 서버 점검이 진행될 예정입니다.</p>
      <p>이용에 참고 부탁드립니다.</p>
      <br/>
      <p>*작업일시: 2024-12-16 23:00 ~ 2024-12-17 09:00(총 10시간)</p>
      <br/>
      <p>작업기간 동안 서비스 이용이 제한되오니, 고객님의 넓은 양해 부탁드립니다.</p>
      <br/>
      <p>감사합니다.</p>
    </div>
    <div class="close-contents">
      <a-checkbox v-model:checked="dontShowAgain">다시 보지않기</a-checkbox>
      <a-button class="close-button" style="background: #aaa; border-color:#aaa" type="primary" @click="hide">닫기</a-button>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { setNotice as submit } from 'api/user'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { NOTI1_VERSION } from '@/config/config'

export default {
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      visible: false,
      dontShowAgain: false
    })

    const show = () => {
      state.visible = true
    }

    const hide = () => {
      if (state.dontShowAgain) {
        submit({
          type: 'notice1',
          version: parseFloat(NOTI1_VERSION)
        })
          .then(() => {
            store.dispatch('userInfo').then(() => {
              state.visible = false
              emit('confirm')
            })
          })
          .catch((err) => {
            Modal.error({ content: err.msg })
          })
      } else {
        state.visible = false
        emit('confirm')
      }
    }

    return {
      ...toRefs(state),
      show,
      hide
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .btn {
    height: 42px;
    line-height: 42px;
    padding: 0;
    margin: 50px auto;
    width: 200px;
  }

  img {
    object-fit: cover;
    padding-bottom: 3rem;
    width: 80%;
    max-width: 340px;
    margin: 0 auto;
  }

  h3 {
    text-align: center;
    font-size: 20px;
    margin: 15px 0px 20px;
    line-height: 28px;
    font-weight: 500;

  }

  h4 {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #555;

    strong {
      font-weight: 500;
      color: #000
    }
  }
}

.close-contents {
  text-align: right;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  .close-button {
    width: 60px;
    height: 32px;
  }
}
</style>
