<template>
  <a-modal v-model:visible="state.visible"
           :bodyStyle="{ padding: 0 }"
           :closable="false"
           :footer="null"
           class="folder-edit-modal-wrapper"
           :width="378"
           :zIndex="props.zIndex">
    <a-spin :spinning="state.isLoading">
      <div id="folder-modi" class="modal modal-sm">
        <div class="modal-header bg-gray">
          <h2>폴더 정보</h2>
          <span role="button" class="modal-close" @click="state.visible = false">닫기</span>
        </div>
        <div class="modal-content modal-set">
          <div class="modal-body">
            <section>
              <h3>기본 정보</h3>
              <div>
                <a-input
                  v-model:value="state.folderName"
                  enter-button="변경하기"
                  @change="state.hasChanged = true"
                  maxlength="14"
                />
              </div>
            </section>
            <section class="folder-type-radio-box">
              <h3>접근 가능 구성원</h3>
              <a-radio-group v-model:value="state.folderType" size="large" @change="onChangeFolderType">
                <a-radio-button value="PUBLIC">팀 공개 폴더</a-radio-button>
                <a-radio-button value="PRIVATE">비공개 폴더</a-radio-button>
              </a-radio-group>
              <span class="commt">
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0"
                  xmlns="http://www.w3.org/2000/svg"
                  height="17"
                  viewBox="0 0 12 17"
                  width="12"
                  class="css-1v2pt2d"
                >
                  <path
                    d="M6 4.5C2.67188 4.5 0 7.19531 0 10.5C0 13.8281 2.67188 16.5 6 16.5C9.30469 16.5 12 13.8281 12 10.5C12 7.19531 9.30469 4.5 6 4.5ZM6 15.375C3.30469 15.375 1.125 13.1953 1.125 10.5C1.125 7.82812 3.30469 5.625 6 5.625C8.67188 5.625 10.875 7.82812 10.875 10.5C10.875 13.1953 8.67188 15.375 6 15.375ZM6.9375 12.375H6.5625V10.3125C6.5625 10.0078 6.30469 9.75 6 9.75H5.25C4.92188 9.75 4.6875 10.0078 4.6875 10.3125C4.6875 10.6406 4.92188 10.875 5.25 10.875H5.4375V12.375H5.0625C4.73438 12.375 4.5 12.6328 4.5 12.9375C4.5 13.2656 4.73438 13.5 5.0625 13.5H6.9375C7.24219 13.5 7.5 13.2656 7.5 12.9375C7.5 12.6328 7.24219 12.375 6.9375 12.375ZM6 9C6.39844 9 6.75 8.67188 6.75 8.25C6.75 7.85156 6.39844 7.5 6 7.5C5.57812 7.5 5.25 7.85156 5.25 8.25C5.25 8.67188 5.57812 9 6 9Z"
                  ></path>
                </svg>
                {{ state.folderType === 'PUBLIC' ? '팀 구성원 모두가 폴더의 문서를 볼 수 있습니다.' : '구성원으로 추가된 계정만 비공개 폴더의 문서를 볼 수 있습니다.' }}
              </span>
              <!--비공개 폴더일 경우만 보임-->
              <template v-if="state.folderType === 'PRIVATE'">
                <div class="member-add">
                  <h4>구성원 ({{ membersNum }}명)</h4>
                  <button
                    type="button"
                    class="modal-trigger"
                    data-modal-id="member-add"
                    @click="onClickAddMember"
                  >
                    <svg
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="0"
                      xmlns="http://www.w3.org/2000/svg"
                      height="12"
                      viewBox="0 0 10 12"
                      width="10"
                      class="css-qv2efc"
                    >
                      <path
                        d="M5 5.875C6.50391 5.875 7.75 4.65039 7.75 3.125C7.75 1.62109 6.50391 0.375 5 0.375C3.47461 0.375 2.25 1.62109 2.25 3.125C2.25 4.65039 3.47461 5.875 5 5.875ZM6.07422 6.90625H3.9043C1.8418 6.90625 0.1875 8.58203 0.1875 10.6445C0.1875 11.0527 0.509766 11.375 0.917969 11.375H9.06055C9.46875 11.375 9.8125 11.0527 9.8125 10.6445C9.8125 8.58203 8.13672 6.90625 6.07422 6.90625Z"
                      ></path>
                    </svg>
                    구성원 추가하기
                  </button>
                </div>
                <a-table :dataSource="accessMemberState.members" :columns="accessMemberState.columns" size="small" rowKey="memberId" :pagination="false" @change="tableChange"
                         :scroll="{x: true, y: 200}">
                  <template #operation="{ record }">
                    <a-button type="text" @click="remove(record.memberId)">제외</a-button>
                  </template>
                </a-table>
              </template>
              <!--비공개 폴더일 경우만 보임-->
            </section>

            <div class="btn-row">
              <div class="btn-table">
                <a class="btn btn-gray-line" @click="state.visible = false">취소</a>
                <a class="btn btn-blue" @click="onConfirm">완료</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
  <MemberPickModal ref="memberPickModal" @memberAdded="onMemberAdded"/>
</template>

<script setup>
import { computed, defineEmits, defineExpose, defineProps, reactive, ref } from 'vue'
import { getPriviteFolderMembers, saveFolderInfo } from 'api/folder'
import MemberPickModal from './MemberPickModal'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'

const store = useStore()
const props = defineProps(['teamId', 'zIndex'])

const folderType = {
  private: 'PRIVATE',
  public: 'PUBLIC'
}

const state = reactive({
  visible: false,
  isLoading: false,
  folderName: '',
  folderType: folderType.public,
  curFolder: null,
  hasChanged: false
})

const show = folder => {
  state.curFolder = folder
  state.folderName = folder.name
  state.folderType = folder.type
  state.visible = true
  if (folder.type === folderType.private) {
    fetchPriviteFolderMembers()
  }
}

// access members
const accessMemberState = reactive({
  members: [],
  columns: [
    {
      title: '이름',
      dataIndex: 'userName'
    },
    {
      title: '이메일',
      dataIndex: 'email'
    },
    {
      title: '접근 설정',
      slots: { customRender: 'operation' }
    }
  ],
  pagination: {
    size: 'small',
    total: 0,
    current: 1,
    pageSize: 10
  }
})

const membersId = computed(() => {
  return accessMemberState.members.map(item => item.memberId)
})

const fetchPriviteFolderMembers = async () => {
  state.isLoading = true
  const { folderId } = state.curFolder
  const res = await getPriviteFolderMembers({
    folderId,
    page: accessMemberState.pagination.current,
    limit: 99999
  })
  state.isLoading = false
  accessMemberState.members = res.result.list
  accessMemberState.pagination.total = res.result.totalCount
}

const membersNum = computed(() => accessMemberState.members.length)

const memberPickModal = ref(null)
const onClickAddMember = () => {
  memberPickModal.value.show(state.curFolder.folderId, membersId.value)
}

const onChangeFolderType = async () => {
  state.isLoading = true
  if (state.folderType === folderType.private) {
    await fetchPriviteFolderMembers()
    if (accessMemberState.members.length === 0 && store.state.user.userInfo.organization.roleId === '1101') {
      accessMemberState.members.push({
        userName: store.state.user.userInfo.name,
        email: store.state.user.userInfo.email,
        memberId: store.state.user.userInfo.organization.memberId
      })
    }
  }
  state.isLoading = false
  state.hasChanged = true
}

const emit = defineEmits(['hasChanged'])

const onConfirm = async () => {
  if (state.hasChanged) {
    await saveFolder()
  }
}

const saveFolder = async () => {
  const requestData = {
    name: state.folderName,
    type: state.folderType,
    teamId: props.teamId,
    members: membersId.value
  }
  await saveFolderInfo(state.curFolder.folderId, requestData)
    .then(() => {
      emit('hasChanged')
      state.visible = false
    })
    .catch((err) => {
      Modal.error({
        content: err.msg,
        zIndex: 10002
      })
    })
}

const remove = async memberId => {
  state.hasChanged = true
  accessMemberState.members = accessMemberState.members.filter(item => item.memberId !== memberId)
}

const tableChange = (pagination) => {
  accessMemberState.pagination.current = pagination.page
  fetchPriviteFolderMembers()
}

const onMemberAdded = (members) => {
  state.hasChanged = true
  accessMemberState.members.push(...members)
}

defineExpose({ show })

</script>

<style lang="less" scoped>
.folder-edit-modal-wrapper {
  .modal-header {
    padding: 2rem;
  }

  .modal-close {
    margin: 24px;
  }

  .folder-type-radio-box {
    :deep(.ant-radio-group) {
      width: 100%;

      .ant-radio-button-wrapper {
        width: 50%;
        text-align: center;
      }
    }
  }

  .folder-btnset {
    display: flex;
    justify-content: space-between;
  }

  .folder-btnset a {
    width: 50%;
    margin: 1.5rem 0;
    text-align: center;
    font-size: 1.5rem;
    text-decoration: underline;
  }

  .modal-set .input-group-addon {
    padding: 0.5rem 1.5rem;
    background: #1890ff;
    color: #fff
  }

  .modal-set h3 {
    margin-bottom: 1rem
  }

  .modal-set section {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 2rem
  }

  .modal-set section:nth-child(2) {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0
  }

  .modal-set section .btn-radio {
    margin-left: 0rem
  }

  .modal-set .commt {
    background: #f7f7f7;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    width: 100%;
    display: block;
    margin: 1rem 0 0 0
  }

  .modal-set .commt svg {
    padding-top: 0.3rem
  }

  .modal-set .ant-table th, .modal-set .ant-table td {
    padding: 0.5rem 1rem;
    text-align: left;
  }

  .modal-set .ant-table th:nth-child(3), .modal-set .ant-table td:nth-child(3) {
    text-align: right
  }

  .modal-set .ant-pagination {
    float: inherit;
    margin: 1rem 0 0 0;
    text-align: center;
  }

  .modal-set .member-add {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem
  }

  .modal-set .member-add h4 {
    font-size: 1.5rem
  }

  .modal-set .member-add button {
    font-size: 1.4rem;
    text-decoration: underline;
  }

  @media (hover: hover) {
    .modal-set .member-add button:hover {
      color: #1890ff;
    }
  }

  .doc-list .except {
    text-decoration: underline;
  }
}

</style>
