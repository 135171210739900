<template>
  <div
    :style="style"
    style="transform-origin: left;"
  >
    <a-steps
      :responsive="false"
      :current="props.currentStep"
      :size="isMobile ? 'small' : 'default'"
      :labelPlacement="isMobile ? 'vertical' : 'horizontal'"
      ref="stepsComp"
    >
      <a-step :key="step" v-for="step in props.steps" :title="step"/>
    </a-steps>
  </div>
</template>
<script setup>
import { defineProps, onMounted, ref } from 'vue'
import { useMediaQuery } from '@vueuse/core'

const props = defineProps(['currentStep', 'steps'])
const isMobile = useMediaQuery('(max-width: 768px)')
const stepsComp = ref(null)
const style = ref({})

onMounted(() => {
  if (isMobile.value) {
    const maxW = document.body.clientWidth
    const setpsW = stepsComp.value.$el.scrollWidth
    if (setpsW > maxW) {
      const scale = maxW / setpsW
      style.value = {
        transform: `scale(${scale})`
      }
    }
  }
})
</script>
