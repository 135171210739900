import request from 'utils/request'

export const payment = (paymentId) => {
  return request.post(`/payment/${paymentId}`)
}

export const getPaymentInfo = (paymentId) => {
  return request.get(`/payment/${paymentId}`)
}

export const cancelPayment = (paymentId, cancelPrice) => {
  return request.put(`/payment/${paymentId}/cancel`, {
    cancelPrice: cancelPrice
  })
}

export const requestKakaoPayment = (paymentParam) => {
  return request.post('/payment/kakao', paymentParam)
}

export const getPaymentHistory = (documentId) => {
  return request.get(`/payment/${documentId}/histories`)
}

export const getPaymentList = (params) => {
  return request.get('/payment/list', {
    params: params
  })
}

export const getPaymentListExcel = (params) => {
  return request.get('/payment/list/excel', {
    params: params
  })
}
