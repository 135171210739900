<template>
  <div v-if="pdfRenderData.length > 0 && pdfRenderData.length === pdfNums">
    <div v-if="isMobile" class="custom-padding"></div>
    <div
      v-for="(item, pdfIndex) in pdfRenderData"
      :key="pdfIndex"
      ref="pageWrap"
      class="frame-wrap"
    >
      <div
        :id="`pageContainer-${pdfIndex + 1}`"
        :class="item.emphasizing ? 'emphasizing-page' : ''"
        class="pdf-content"
        @click="item.emphasizing = false"
      >
        <div v-show="isSigning" class="fields-wrapper">
          <template v-for="(field, index) in item.fields" :key="field.fieldId">
            <component
              :is="matchComponent(field)"
              v-if="!field.signData.isShow"
              v-model:fontSize="field.textStyleFontSize"
              v-model:isChecked="field.signData.isChecked"
              v-model:textValue="field.signData.textValue"
              :class="[field.emphasizing ? 'emphasizing-field' : '', field.groupParentName]"
              :field="field"
              :scale="item.scale"
              :signedData="signedData"
              :style="getFieldStyle(field, pdfIndex)"
              class="sign-tool-tip"
              @click="clickEvent(field)"
              @clickSignBtn="onClickSignBtn(field, index, $event)"
              @mouseenter="onActiveGroup(field)"
              @mouseleave="onDeactivatedGroup"
              @onActive="onActive(field)"
            ></component>
            <SignatureResize
              v-else
              :key="field.key"
              v-model:h="field.signData.h"
              v-model:isActive="field.signData.active"
              v-model:w="field.signData.w"
              v-model:x="field.signData.x"
              v-model:y="field.signData.y"
              :signatureId="field.signData.signatureId"
              @onActive="onActive(field)"
              @remove="onClickRemove(field)"
            />
          </template>
        </div>
      </div>
    </div>
    <SignatureModal ref="signatureModal" @finishSign="onFinishSign"/>
    <StampModal ref="stampModal" @finishSign="onFinishSign"/>
    <div v-if="isMobile" class="custom-padding"></div>
  </div>
</template>

<script>
import { createVNode, reactive, ref, toRefs, watchEffect } from 'vue'
import SignatureTip from 'components/signing/signing/SignatureTip'
import CheckboxTip from 'components/signing/signing/CheckboxTip'
import TextTip from 'components/signing/signing/TextTip'
import SignatureModal from 'components/signing/signing/SignatureModal'
import DragFrom from 'components/signing/createForm/DragForm'
import SignatureDrag from 'components/signing/signaure/SignatureDrag'
import StampModal from 'components/signing/signing/StampModal'
import { formatSubmitData } from 'components/signing/createForm/createForm'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { genKey, signFromLink } from 'utils/helper'
import SignatureResize from 'components/signing/signaure/SignatureResize'
import { useMediaQuery } from '@vueuse/core'
import DateTip from 'components/signing/signing/DateTip'
import NumberTip from 'components/signing/signing/NumberTip'
import { DocumentProcessTypes } from 'utils/commonValue'
import { useStore } from 'vuex'

const getPdfWH = (field) => {
  const pdf = document.getElementById(`pageContainer-${field.locationPage}`)
  const w = pdf.clientWidth
  const h = pdf.clientHeight
  return {
    x: Math.floor(field.locationX * w),
    y: Math.floor(field.locationY * h)
  }
}

export default {
  props: ['document', 'participant', 'fields', 'isSigning', 'pdfPages', 'pdfNums', 'processType'],
  components: {
    SignatureTip,
    CheckboxTip,
    TextTip,
    SignatureModal,
    DragFrom,
    SignatureDrag,
    StampModal,
    SignatureResize,
    DateTip,
    NumberTip
  },
  setup (props, { emit }) {
    const store = useStore()
    const pageWrap = ref(null)
    const signatureModal = ref(null)
    const stampModal = ref(null)
    const [isFromLink] = signFromLink()
    const isMobile = useMediaQuery('(max-width: 768px)')
    const state = reactive({
      isLoading: false,
      currentSignField: null,
      currentSignFieldIndex: -1,
      pdfRenderData: [],
      signedData: null,
      signNums: 0,
      isFromLink
    })
    watchEffect(() => {
      state.pdfRenderData = props.pdfPages.map((item, pageIndex) => {
        item.fields = props.fields.filter((field) => {
          field.signData = {
            isShow: false,
            textValue: '',
            isChecked: false
          }
          return field.locationPage - 1 === pageIndex
        })
        return item
      })
    })

    watchEffect(() => {
      emit('signChanged', state.pdfRenderData)
    })

    const getFieldStyle = (field, pdfIndex) => {
      // 패치 이전 필드 old field
      if (field.fieldType === 'signature' && field.sizeWidth === 0 && field.sizeHeight === 0) {
        console.log('❗Old signature field detected❗')
        field.sizeWidth = 0.1028
        field.sizeHeight = 0.0523
        field.locationX = field.locationX - field.sizeWidth / 2
        field.locationY = field.locationY - field.sizeHeight / 2
      }
      const curPdf = document.getElementById(`pageContainer-${pdfIndex + 1}`)
      const w = curPdf.clientWidth
      const h = curPdf.clientHeight
      const width = field.sizeWidth * w
      const height = field.sizeHeight * h
      field.width = width
      field.height = height
      let size = {}
      if (field.fieldType !== 'text') {
        size = {
          width: width + 'px',
          height: height + 'px'
        }
      }
      return {
        left: Math.floor(field.locationX * w) + 'px',
        top: Math.floor(field.locationY * h) + 'px',
        ...size
      }
    }

    const matchComponent = field => {
      if (field.fieldType === 'checkbox') return 'CheckboxTip'
      if (field.fieldType === 'text') return 'TextTip'
      if (field.fieldType === 'signature') return 'SignatureTip'
      if (field.fieldType === 'date') return 'DateTip'
      if (field.fieldType === 'number') return 'NumberTip'
    }
    const onClickSignBtn = (field, index, { signType }) => {
      if (field.fieldType === 'signature') {
        state.currentSignField = field
        state.currentSignFieldIndex = index
        if (state.signedData) { // is alreay has signed field
          onFinishSign(state.signedData)
        } else {
          if (props.processType === DocumentProcessTypes.FACE_TO_FACE) {
            const isRequester = (store.state.user.userInfo.email === props.participant.signingContactInfo) || (store.state.user.userInfo.phone === props.participant.signingContactInfo)
            if (signType === 'sign') signatureModal.value.show(isRequester, isRequester)
            if (signType === 'stamp') stampModal.value.show(isRequester, isRequester)
          } else {
            if (signType === 'sign') signatureModal.value.show(!isFromLink, !isFromLink)
            if (signType === 'stamp') stampModal.value.show(!isFromLink, !isFromLink)
          }
        }
      }
    }

    const onFinishSign = (signData) => {
      if (state.currentSignField) {
        // copy signData
        state.signedData = signData
        const width = state.currentSignField.width
        const height = state.currentSignField.height
        const {
          x,
          y
        } = getPdfWH(state.currentSignField)
        const pageIndex = state.currentSignField.locationPage - 1
        state.pdfRenderData[pageIndex].fields[state.currentSignFieldIndex].signData = {
          initW: width,
          initH: height,
          x,
          y,
          w: width,
          h: height,
          active: true,
          isShow: true,
          minH: 100 / 3,
          minW: width / 3,
          key: genKey(),
          ...signData
        }
        state.signNums++
      }
    }

    const onClickRemove = field => {
      if (state.signNums === 1) {
        field.signData.isShow = false
        state.signedData = null
        state.signNums = 0
      } else {
        Modal.confirm({
          title: '서명 크기/모양 변경',
          icon: createVNode(ExclamationCircleOutlined),
          content: '서명 크기 또는 모양을 변경하면 입력한 서명이 모두 사라집니다. 변경하시겠습니까?',
          okText: '확인',
          cancelText: '취소',
          onOk () {
            clearSign()
          }
        })
      }
    }

    const clearSign = () => {
      state.pdfRenderData.forEach(item => {
        item.fields.forEach(field => {
          field.signData.isShow = false
        })
      })
      state.signedData = null
      state.signNums = 0
    }

    const getSignResult = () => {
      return formatSubmitData(state.pdfRenderData)
    }

    const onActive = (field) => {
      state.pdfRenderData.forEach(pdf => {
        pdf.fields.forEach(item => {
          if (item.signData.key !== field.signData.key) {
            item.signData.active = false
          }
        })
      })
    }

    const emphasizingField = (fields) => {
      if (Array.isArray(fields)) {
        state.pdfRenderData.forEach(pdf => {
          pdf.fields.forEach(item => {
            fields.forEach(field => {
              if (item.fieldId === field.fieldId) {
                item.emphasizing = true
                pdf.emphasizing = true
              }
            })
          })
        })
      } else {
        if (fields.fieldType === 'group') {
          emit('groupEmphasize', fields)
          state.pdfRenderData.forEach(pdf => {
            pdf.fields.forEach(item => {
              if (item.groupParentName === fields.groupName) {
                pdf.emphasizing = true
              }
            })
          })
        } else {
          state.pdfRenderData.forEach(pdf => {
            pdf.fields.forEach(item => {
              if (item.fieldId === fields.fieldId) {
                item.emphasizing = true
                pdf.emphasizing = true
              }
            })
          })
        }
      }
    }

    const deactiveAllEmphasizing = (field = null) => {
      emit('groupEmphasize', null)
      if (field != null && field.emphasizing) {
        field.emphasizing = false
        state.pdfRenderData[field.locationPage - 1].emphasizing = false
      } else {
        state.pdfRenderData.forEach(pdf => {
          pdf.emphasizing = false
          pdf.fields.forEach(item => {
            item.emphasizing = false
          })
        })
      }
    }

    // watchEffect(() => {
    //   if (props.isSigning) {
    //     setTimeout(() => {
    //       state.isLoaded = true
    //     }, 100)
    //   } else {
    //     state.isLoaded = false
    //   }
    // })

    const onActiveGroup = (field) => {
      emit('groupFocus', field.groupParentName)
    }

    const onDeactivatedGroup = () => {
      emit('groupFocus', null)
      deactiveAllEmphasizing(null)
    }

    const clickEvent = (field) => {
      deactiveAllEmphasizing(field)
      if (field.groupParentName) onActiveGroup(field)
    }

    return {
      isMobile,
      pageWrap,
      getFieldStyle,
      matchComponent,
      signatureModal,
      onClickSignBtn,
      onFinishSign,
      onClickRemove,
      stampModal,
      getSignResult,
      onActive,
      emphasizingField,
      deactiveAllEmphasizing,
      onActiveGroup,
      onDeactivatedGroup,
      clickEvent,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.frame-wrap {
  position: relative;
  margin: 10px auto;
  width: 100%
}

.sign-tool-tip {
  position: absolute;
  z-index: 99;
  @media (hover: hover) {
    &:hover {
      z-index: 999;
    }
  }
}

.fields-wrapper {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.custom-padding {
  height: 80px;
}

// #pdfWrapper {
//   -webkit-touch-callout: none; /*系统默认菜单被禁用*/
// }hs

</style>
