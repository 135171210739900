<template>
  <div class="e-wrapper">
    <div class="e-header">
      <div class="back">
        <router-link to="" @click="onConfrimBack">
          <LeftOutlined/>
          나가기
        </router-link>
      </div>
      <div class="title">
        {{ title }}
      </div>
      <div class="settings">
        <a-dropdown :trigger="['click']">
          <button class="layout-btn">
            <DownloadOutlined class="icon"/>
            <span class="txt">다운로드</span></button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-if="hasAttachment" @click="onClickAttachment">첨부파일</a-menu-item>
              <a-menu-item v-if="isCompleted" @click="downloadDoc('contract')">완료된 문서</a-menu-item>
              <a-menu-item v-if="isCompleted" @click="downloadDoc('trackFile')">감사추적인증서</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div class="e-container layout-container">
      <router-view></router-view>
    </div>
  </div>
  <AttachmentModal ref="attachmentModal"/>
</template>

<script setup>
import { inject, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { DownloadOutlined, LeftOutlined } from '@ant-design/icons-vue'
import AttachmentModal from 'components/common/AttachmentModal.vue'
import { clearLinkSessions, clearUserInfo, downloadFile, signFromLink } from '@/utils/helper'
import { useRoute, useRouter } from 'vue-router'
import { getAttachments, getAuditTrail, getDocFile } from 'api/sign'
import { useStore } from 'vuex'

const attachmentModal = ref(null)
const route = useRoute()
const router = useRouter()
const isCompleted = route.query.reason === 'completed'
const [isFromLink] = signFromLink()
const store = useStore()
const title = route.query.docName
let hasAttachment = false
const redirectUrl = route.query.redirectUrl

onBeforeMount(() => {
  getAttachments(route.params.documentId).then((res) => {
    hasAttachment = res.result.length > 0
  }).catch(() => {
    hasAttachment = false
  })
})

const onClickAttachment = () => {
  const documentId = route.params.documentId
  attachmentModal.value.show(documentId)
}

onBeforeUnmount(() => {
  if (isFromLink) {
    clearLinkSessions()
    clearUserInfo()
  }
})

const onConfrimBack = () => {
  if (redirectUrl) {
    let link = redirectUrl
    if (!redirectUrl.includes(encodeURIComponent('?'))) link += '?'
    link += `&documentId=${route.params.documentId}&action=document_view`
    window.location.href = decodeURIComponent(link)
    return false
  } else if (isFromLink) {
    clearLinkSessions()
    clearUserInfo()
    window.location.href = 'https://ucansign.com/'
    return false
  } else {
    if (store.state.user.userInfo) {
      router.push({ name: 'Home' })
    } else {
      clearLinkSessions()
      clearUserInfo()
      window.location.href = 'https://ucansign.com/'
    }
  }
}

const globalSpin = inject('globalSpin')

const downloadDoc = async (type = 'contract') => {
  globalSpin.show()
  try {
    const file = type === 'contract' ? await getDocFile(route.params.documentId) : await getAuditTrail(route.params.documentId)
    const docName = type === 'contract' ? route.query.docName || 'Contract' : route.query.docName + '_감사추적인증서'
    await downloadFile(file.result.url, `${docName}.pdf`)
    globalSpin.hide()
  } catch (error) {
    console.error(error)
    globalSpin.hide()
  }
}

</script>

<style lang="less" scoped>
.e-wrapper {
  min-height: 100vh;

  .e-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #fff;
    border: none;
    font-size: 16px;
    padding: 0 25px;

    .title {
      text-align: center;
    }

    .settings .layout-btn {
      padding: 8px;
      font-size: 16px;

      .icon {
        font-size: 18px;
      }

      span.txt {
        margin-left: 0.8rem
      }

      @media screen and (max-width: 768px) {
        span.txt {
          display: none
        }
      }
    }
  }

  .layout-container {
    height: calc(100vh - 60px);
  }
}
</style>
