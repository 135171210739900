<template>
  <a-modal
    v-model:visible="isShow"
    :bodyStyle="{ padding: 0, fontSize: '16px' }"
    :closable="false"
    :footer="null"
    :width="520"
    class="confirm-modal-wrapper ucs-modal">
    <div id="certify-way" class="modal modal-md">
      <div class="modal-header">
        <h2>
          추가 인증 수단 설정
          <a-tooltip :color="'#FFF'" overlayClassName="signer-tooltip" placement="right">
            <button class="btn-info" type="button">?</button>
            <template #title>
              <p class="content">
                기본 인증만으로 보안이 걱정될 때, 추가 인증 수단을 설정해 인증 절차를 강화할 수 있어요. 두 가지 수단을 함께 사용할 수 있습니다.
              </p>
            </template>
          </a-tooltip>
        </h2>
        <span class="modal-close" role="button" @click="isShow=false">닫기</span>
      </div>
      <div v-if="processType === DocumentProcessTypes.PROCEDURE" class="modal-content bg-gray">
        <div class="panel-wrap">
          <div class="panel">
            <div class="panel-head">
              <div class="sub-tit">
                <i class="ico ico-pw-chk s-left"></i>
                <div class="tit-txt">
                  <h3>
                    접근 암호 인증
                    <span>
                      <a-switch v-model:checked="needPwd" size="small"/>
                    </span>
                  </h3>
                  <p>서명에 참여하기에 앞서 입력할 암호를 설정합니다.</p>
                </div>
              </div>
            </div>
            <!-- // 접근 암호 인증 체크버튼을 클릭하면 노출되는 영역 -->
            <div v-show="needPwd" class="panel-body">
              <div class="form-group">
                <label class="form-tit" for="pw">접근 암호</label>
                <a-input-password v-model:value="passwordAuth.authValuePassword" placeholder="4자 이상 20자 이하"/>
                <p class="form-guide list-style mt15">
                  <b class="text-black"><i>※</i> 접근 암호를 참여자에게 미리 알려주세요.</b><br>
                  설정된 암호는 이후 변경, 확인이 어려우니 신중하게 설정해주세요.
                </p>
              </div>
            </div>
          </div>
          <!-- //접근 암호 인증 -->
          <div class="panel">
            <div class="panel-head">
              <div class="sub-tit">
                <i class="ico ico-phone-chk s-left"></i>
                <div class="tit-txt">
                  <h3>
                    휴대폰 본인 인증
                    <span>
                      <a-switch v-model:checked="needPhone" size="small"/>
                    </span>
                  </h3>
                  <p>서명에 참여하기에 앞서 휴대폰으로 본인인증을 하도록 설정합니다.</p>
                </div>
              </div>
            </div>
            <!-- // 휴대폰 본인 인증 체크버튼을 클릭하면 노출되는 영역 -->
            <div v-show="needPhone" class="panel-body">
              <div class="form-group">
                <label class="form-tit" for="userName">참여자 실명</label>
                <a-input v-model:value="mobileAuth.authValueName" placeholder="1자 이상 30자 이내"/>
              </div>
              <!-- //참여자 실명 -->
              <div class="form-group">
                <label class="form-tit" for="phone">참여자 명의의 휴대폰 번호</label>
                <a-input v-model:value="mobileAuth.authValuePhone" :maxlength="13" class="form-control" placeholder="'-'없이 숫자만 입력" type="tel"/>
                <p class="form-guide mt15">
                  <b class="text-black"><i>※</i> 휴대폰 명의의 참여자 실명을 입력해 주세요.</b>
                </p>
              </div>
              <!-- //참여자 명의의 휴대폰 번호 -->
            </div>
          </div>
          <!-- //휴대폰 본인 인증 -->
        </div>
        <!-- //panel-wrap -->
        <div class="btn-horizon">
          <a-button class="btn btn-gray-line" @click="isShow=false">취소</a-button>
          <a-button :disabled="isDisableConfirm" class="btn" type="primary" @click="confirmInfo">설정하기</a-button>
        </div>
      </div>
      <div v-else class="modal-content bg-gray">
        <div class="panel-wrap">
          <div class="panel">
            <div class="panel-head">
              <div class="sub-tit">
                <i class="ico ico-phone-chk s-left"></i>
                <div class="tit-txt">
                  <h3>
                    OTP 인증
                    <span>
                      <a-switch v-model:checked="needOtp" size="small"/>
                    </span>
                  </h3>
                  <p>서명 진행 전, 참여자 휴대폰에서 요청자 기기에 표시된 인증번호를 입력하여 본인인증하는 방식입니다.</p>
                </div>
              </div>
            </div>
            <!-- // 휴대폰 본인 인증 체크버튼을 클릭하면 노출되는 영역 -->
            <div v-show="needOtp" class="panel-body">
              <div class="form-group">
                <label class="form-tit" for="userName">참여자 실명</label>
                <a-input v-model:value="otpAuth.authValueName" placeholder="1자 이상 30자 이내"/>
              </div>
              <!-- //참여자 실명 -->
              <div class="form-group">
                <label class="form-tit" for="phone">참여자 명의의 휴대폰 번호</label>
                <a-input v-model:value="otpAuth.authValuePhone" :maxlength="13" class="form-control" placeholder="'-'없이 숫자만 입력" type="tel"/>
                <p class="form-guide mt15">
                  <b class="text-black"><i>※</i> 휴대폰 명의의 참여자 실명을 입력해 주세요.</b>
                </p>
              </div>
              <!-- //참여자 명의의 휴대폰 번호 -->
            </div>
          </div>
          <!-- //휴대폰 본인 인증 -->
        </div>
        <!-- //panel-wrap -->
        <div class="btn-horizon">
          <a-button class="btn btn-gray-line" @click="isShow=false">취소</a-button>
          <a-button :disabled="isDisableConfirm" class="btn" type="primary" @click="confirmInfo">설정하기</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { DocumentProcessTypes } from 'utils/commonValue'
import { validMobilePhone, validPureMobilePhone } from 'utils/validate'

export default {
  props: ['signer', 'processType'],
  setup (props, { emit }) {
    const store = useStore()
    const mobileAuth = {
      authValueName: '',
      authValuePhone: '',
      authType: 'mobile_identification'
    }
    const passwordAuth = {
      authValuePassword: '',
      authType: 'password'
    }
    const otpAuth = {
      authValueName: '',
      authValuePhone: '',
      authType: 'mobile_otp'
    }
    const state = reactive({
      isShow: false,
      needPwd: false,
      needPhone: false,
      needOtp: false,
      mobileAuth,
      passwordAuth,
      otpAuth
    })
    const confirmInfo = () => {
      const info = []
      if (state.needPwd) info.push(state.passwordAuth)
      if (state.needPhone) {
        state.mobileAuth.authValuePhone = state.mobileAuth.authValuePhone.replace(/-/g, '')
        info.push(state.mobileAuth)
      }
      if (state.needOtp) {
        state.otpAuth.authValuePhone = state.otpAuth.authValuePhone.replace(/-/g, '')
        info.push(state.otpAuth)
      }
      emit('confirmInfo', {
        authentications: info
      })
      store.dispatch('updateSigner', {
        ...props.signer,
        authentications: info
      })
      state.isShow = false
    }

    const show = () => {
      const authentications = props.signer.authentications
      if (authentications && authentications.length > 0) {
        authentications.forEach(item => {
          if (item.authType === 'password') {
            state.needPwd = true
            state.passwordAuth = {
              authValuePassword: item.authValuePassword,
              authType: 'password'
            }
          }
          if (item.authType === 'mobile_identification') {
            state.needPhone = true
            state.mobileAuth = {
              authValueName: '',
              authValuePhone: item.authValuePhone,
              authType: 'mobile_identification'
            }
          }
          if (item.authType === 'mobile_otp') {
            state.needOtp = true
            state.otpAuth = {
              authValueName: '',
              authValuePhone: '',
              authType: 'mobile_otp'
            }
          }
        })
        state.isShow = true
      } else {
        state.mobileAuth = { ...mobileAuth }
        state.passwordAuth = { ...passwordAuth }
        state.otpAuth = { ...otpAuth }
        state.needPwd = false
        state.needPhone = false
        state.needOtp = false
        state.isShow = true
      }
    }

    const isDisableConfirm = computed(() => {
      console.log(validMobilePhone(state.otpAuth.authValuePhone))
      if (
        state.needPwd &&
        (
          state.passwordAuth.authValuePassword.length < 4 || state.passwordAuth.authValuePassword.length > 20
        )
      ) {
        return true
      }

      if (
        state.needPhone &&
        (
          (state.mobileAuth.authValueName.length < 1 || state.mobileAuth.authValueName.length > 30) ||
          (state.mobileAuth.authValuePhone.length !== 11 && state.mobileAuth.authValuePhone.length !== 13) ||
          (!validMobilePhone(state.mobileAuth.authValuePhone) && !validPureMobilePhone(state.mobileAuth.authValuePhone))
        )
      ) {
        return true
      }

      if (
        state.needOtp &&
        (
          (state.otpAuth.authValueName.length < 1 || state.otpAuth.authValueName.length > 30) ||
          (state.otpAuth.authValuePhone.length !== 11 && state.otpAuth.authValuePhone.length !== 13) ||
          (!validMobilePhone(state.otpAuth.authValuePhone) && !validPureMobilePhone(state.otpAuth.authValuePhone))
        )
      ) {
        return true
      }
      return false
    })

    return {
      DocumentProcessTypes,
      confirmInfo,
      show,
      isDisableConfirm,
      ...toRefs(props),
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.confirm-modal-wrapper {
  .modal-header {
    padding: 2rem;
  }

  .modal-close {
    margin: 24px;
  }
}

.tit-txt h3 {
  display: flex;
  justify-content: space-between;
}

</style>
