<template>
  <div class="e-cont">
    <div class="e-inner-sm">
      <div class="box">
        <div class="box-body">
          <span class="txt">서명 유형&nbsp;</span>
          <a-tooltip :color="'#FFF'" overlayClassName="signer-tooltip" placement="right">
            <button class="btn-info" type="button">?</button>
            <template #title>
              <p class="content">
                비대면 : 참여자 이메일 또는 휴대폰 번호로 서명요청 알림이 전송되어 서명을 진행하는 방식<br/><br/>
                대면: 요청자 기기에서 참여자가 직접 서명하는 방식(계약 생성 시 입력한 이메일, 휴대폰 번호는 완료된 문서 전송 시 사용됨)
              </p>
            </template>
          </a-tooltip>
        </div>
        <div class="box-btn" @mousedown.stop @pointerdown.stop>
          <div class="table-group">
            <span :class="{ active: processType === DocumentProcessTypes.PROCEDURE }" class="btn btn-gray" role="button" @click.prevent="onClickTypeBtn(DocumentProcessTypes.PROCEDURE)">비대면</span>
            <span :class="{ active: processType === DocumentProcessTypes.FACE_TO_FACE }" class="btn btn-gray" role="button" @click.prevent="onClickTypeBtn(DocumentProcessTypes.FACE_TO_FACE)">대면</span>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-body">
          <span class="txt">서명 순서 설정&nbsp;</span>
          <a-tooltip :color="'#FFF'" overlayClassName="signer-tooltip" placement="right">
            <button class="btn-info" type="button">?</button>
            <template #title>
              <p class="content">
                순차 서명 : 추가한 참여자 순서대로 서명 요청 알림이 전송되어 순차적으로 서명이 진행됩니다.<br/><br/>
                동시 서명 : 추가한 모든 참여자에게 동시에 서명 요청 알림이 전송되어 서명을 진행할 수 있습니다.
              </p>
            </template>
          </a-tooltip>
        </div>
        <div class="box-btn" @mousedown.stop @pointerdown.stop>
          <div class="table-group">
            <span :class="{ active: isSequential }" class="btn btn-gray" role="button" @click.prevent="onClickSequentialBtn(true)">순차 서명</span>
            <span :class="{ active: !isSequential }" class="btn btn-gray" role="button" @click.prevent="onClickSequentialBtn(false)">동시 서명</span>
          </div>
        </div>
      </div>
      <draggable v-model="signers" :scroll="true" animation="300" filter=".ant-input" ghostClass="ghost" itemKey="dragKey">
        <template #item="{element, index}">
          <Signer :key="element.dragKey" :index="index" :isSequential="isSequential" :operationType="operationType" :signer="element" @removeSigner="onRemoveSigner" @updateSigner="onUpdateSigner"/>
        </template>
        <!-- //다섯번째 서명 (input 알럿 노출 화면)-->
      </draggable>
      <!-- //서명 리스트 그룹 -->
      <div class="box end">
        <div class="btn-center">
          <button v-if="signers.length<5" class="btn btn-lg btn-block btn-white" type="button" @click="addSigner">
            <span class="ico-plus">
              <svg
                aria-hidden="true"
                data-icon="plus"
                fill="#828282"
                focusable="false"
                height="16px"
                viewBox="64 64 896 896"
                width="16px"
              >
                <path
                  d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"
                ></path>
                <path
                  d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"
                ></path>
              </svg>
            </span>
            서명 참여자 추가
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, toRefs } from 'vue'
import Signer from 'components/signing/Signer'
import draggable from 'vuedraggable'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { genKey } from 'utils/helper'
import { Modal } from 'ant-design-vue'
import { DocumentProcessTypes } from 'utils/commonValue'

const createParticipant = (order, type = 'sign') => {
  const signer = {
    name: '',
    signingMethodType: 'email',
    signingContactInfo: '',
    signingOrder: order,
    message: '',
    attachments: [],
    dragKey: genKey()
  }
  if (type === 'template') signer.roleName = ''
  return signer
}

export default {
  components: {
    Signer,
    draggable
  },
  props: {
    operationType: {
      type: String,
      default: 'sign'
    },
    processType: {
      type: String,
      default: DocumentProcessTypes.PROCEDURE
    },
    isSequential: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const initSigners = () => {
      const signersInit = [
        {
          name: '',
          signingMethodType: 'email',
          signingContactInfo: '',
          signingOrder: 1,
          message: '',
          attachments: [],
          dragKey: genKey()
        }
      ]

      const templateSignersInit = [
        {
          name: '',
          roleName: '',
          signingMethodType: '',
          signingContactInfo: '',
          signingOrder: 1,
          message: '',
          attachments: [],
          dragKey: genKey()
        }
      ]
      const signers = props.operationType === 'template' ? templateSignersInit : signersInit
      store.dispatch('updateSigners', signers)
    }
    onBeforeMount(() => {
      const isFromTemplate = Boolean(route.params.documentId)
      if (!isFromTemplate) initSigners()
    })

    const onRemoveSigner = dragKey => {
      const signers = store.state.documents.signers
      if (signers.length > 1) {
        Modal.confirm({
          content: '해당 참여자에 설정된 필드 및 컴포넌트 정보가 초기화됩니다. 삭제하시겠습니까?',
          okText: '확인',
          cancelText: '취소',
          onOk () {
            emit('removeSigner', dragKey)
            setTimeout(() => {
              const index = signers.findIndex(item => item.dragKey === dragKey)
              signers.splice(index, 1)
              refreshOrder()
            }, 200)
          }
        })
      }
    }

    const addSigner = () => {
      if (store.state.documents.signers.length < 5) {
        store.state.documents.signers.push(createParticipant(store.state.documents.signers.length + 1, props.operationType))
      }
    }
    const onUpdateSigner = (signer) => {
      const signers = store.state.documents.signers
      signers[signers.findIndex(item => item.signingOrder === signer.signingOrder)] = signer
      store.dispatch('updateSigners', signers)
    }
    onMounted(() => {
      // setTimeout(() => {
      //   [].forEach.call(document.querySelectorAll('.ant-input'), function (item) {
      //     item.addEventListener('pointerdown', (e) => {
      //       e.stopPropagation()
      //     })
      //     item.addEventListener('mousedown', (e) => {
      //       e.stopPropagation()
      //     })
      //   })
      // }, 200)
    })

    const refreshOrder = () => {
      const orderMap = {}
      store.state.documents.signers = store.state.documents.signers.map((item, index) => {
        item.signingOrder = index + 1
        orderMap[item.dragKey] = item.signingOrder
        return item
      })
      return orderMap
    }

    const onClickTypeBtn = type => {
      emit('update:processType', type)
    }

    const onClickSequentialBtn = value => {
      emit('update:isSequential', value)
    }

    return {
      onRemoveSigner,
      addSigner,
      onUpdateSigner,
      refreshOrder,
      onClickTypeBtn,
      onClickSequentialBtn,
      DocumentProcessTypes,
      ...toRefs(store.state.documents)
    }
  }
}
</script>

<style lang="less" scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.4;
  // background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.active {
  background: #eff8ff;
  border: 1px solid #1890ff;
  color: #1890ff;
}

.box-body {
  padding: 15px;

  .txt {
    font-size: 16px;
    font-weight: 500;
  }
}

</style>
