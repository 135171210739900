<template>
  <div class="e-cont">
    <div class="e-inner">
      <!-- //sidebar -->
      <SignLeftBar ref="signOperation" :isSequential="isSequential"/>
      <div id="optionBox" class="pdf-controller">
        <!--
        <span class="ratio">
          <MinusOutlined @click="selectPdfZoom({key: pdfZoom - 10})"/>
          <a-dropdown class="scale-button">
            <template #overlay>
              <a-menu @click="selectPdfZoom">
                <a-menu-item key=150>
                  150%
                </a-menu-item>
                <a-menu-item key=120>
                  120%
                </a-menu-item>
                <a-menu-item key=100>
                  100%
                </a-menu-item>
                <a-menu-item key=80>
                  80%
                </a-menu-item>
                <a-menu-item key=60>
                  60%
                </a-menu-item>
              </a-menu>
            </template>
            <a-button>
              {{ pdfZoom }}%
            </a-button>
          </a-dropdown>
          <PlusOutlined @click="selectPdfZoom({key: pdfZoom + 10})"/>
        </span>
        -->
        <span class="ratio">
          <a-tooltip placement="bottom">
            <template v-if="isMacOS" #title>Command + z</template>
            <template v-else #title>Ctrl + z</template>
            <a class="control-btn" @click="undoFields"><RollbackOutlined class="icon-undo"/>실행취소</a>
          </a-tooltip>
          <a-tooltip placement="bottom">
            <template v-if="isMacOS" #title>Command + y</template>
            <template v-else #title>Ctrl + y</template>
            <a class="control-btn" @click="redoFields"><RollbackOutlined class="icon-redo"/>다시실행</a>
          </a-tooltip>
        </span>
      </div>
      <DragArea v-model:pdfData="pdfPage" :dragFieldId="'pdfViewer'" @onActive="onActive" @onActiveGroup="onActiveGroup"/>
      <ContextMenu ref="contextMenu" v-model:isMacOS="isMacOS" @copyFields="copyFields" @cutFields="cutFields" @pasteFields="pasteFields"/>
      <div id="pdfViewer" ref="pdfViewer" class="e-viewer" @scroll="onScrollPdf">
        <div id="groupArea" class="group-area"/>
        <div
          v-for="(pdf, pdfIndex) in pdfPage"
          :key="pdfIndex"
          class="frame-wrap"
          style="pointer-events: all"
        >
          <div
            :id="`pageContainer-${pdfIndex + 1}`"
            class="pdf-content"
          >
            <div v-if="canRenderFields" id="fieldsWrapper" class="fields-wrapper" @click.stop="onClickPdf(pdfIndex, $event)">
              <template v-for="field in pdf.requesterFields" :key="field.key">
                <DragForm
                  v-if="field.type === 'signature' && !field.signData"
                  :key="field.key"
                  v-model:h="field.h"
                  v-model:isActive="field.active"
                  v-model:isMoving="field.isMoving"
                  v-model:w="field.w"
                  v-model:x="field.x"
                  v-model:y="field.y"
                  :aspectRatio="['checkbox'].includes(field.type)"
                  :fieldKey="field.key"
                  :keyboardEvent="keyboardEvent"
                  :pdfH="pdf.pdfH"
                  :pdfScale="pdf.scale"
                  :pdfW="pdf.pdfW"
                  :resizable="true"
                  :style="{zIndex: field.active ? 999 : 99}"
                  :type="field.type"
                  classNameActive="fields-active"
                  classNameDragging="fields-dragging"
                  @onActive="onActive(field)"
                  @onActiveSingleField="onActiveSingleField(field)"
                  @onDeactivated="onDeactivated(field)"
                >
                  <SignField
                    :active="field.active"
                    :signField="field"
                  />
                </DragForm>
                <RequesterSignField
                  v-if="field.type === 'signature' && field.signData"
                  :key="field.key"
                  v-model:h="field.h"
                  v-model:isActive="field.active"
                  v-model:isMoving="field.isMoving"
                  v-model:w="field.w"
                  v-model:x="field.x"
                  v-model:y="field.y"
                  :fieldKey="field.key"
                  :keyboardEvent="keyboardEvent"
                  :pdfH="pdf.pdfH"
                  :pdfScale="pdf.scale"
                  :pdfW="pdf.pdfW"
                  :signatureId="field.signData.signatureId"
                  @onActive="onActive(field)"
                  @onActiveSingleField="onActiveSingleField(field)"
                  @onDeactivated="onDeactivated(field)"
                />
                <DragForm
                  v-if="field.type === 'checkbox'"
                  :key="field.key"
                  v-model:h="field.h"
                  v-model:isActive="field.active"
                  v-model:w="field.w"
                  v-model:x="field.x"
                  v-model:y="field.y"
                  :aspectRatio="field.type === 'checkbox'"
                  :fieldKey="field.key"
                  :isHandleDrag="field.type === 'text'"
                  :keyboardEvent="keyboardEvent"
                  :pdfH="pdf.pdfH"
                  :pdfScale="pdf.scale"
                  :pdfW="pdf.pdfW"
                  :resizable="true"
                  :style="{zIndex: field.active ? 999 : 99}"
                  classNameActive="fields-active"
                  classNameDragging="fields-dragging"
                  @onActive="onActive(field)"
                  @onActiveSingleField="onActiveSingleField(field)"
                  @onDeactivated="onDeactivated(field)"
                >
                  <UcsCheckbox
                    v-if="field.type === 'checkbox'"
                    :key="field.key"
                    :active="field.active"
                    :signField="field"
                  />
                </DragForm>
                <UcsText
                  v-if="field.type === 'text'"
                  :key="field.key"
                  v-model:fontSize="field.textStyleFontSize"
                  v-model:fontType="field.textStyleFontType"
                  v-model:h="field.h"
                  v-model:isActive="field.active"
                  v-model:textAlign="field.textAlign"
                  v-model:value="field.textValueText"
                  v-model:w="field.w"
                  v-model:x="field.x"
                  v-model:y="field.y"
                  :field="field"
                  :fieldKey="field.key"
                  :keyboardEvent="keyboardEvent"
                  :pdfH="pdf.pdfH"
                  :pdfScale="pdf.scale"
                  :pdfW="pdf.pdfW"
                  @onActive="onActive(field)"
                  @onActiveSingleField="onActiveSingleField(field)"
                  @onDeactivated="onDeactivated(field)"
                  @startKeyboardEvent="startKeyboardEvent"
                  @stopKeyboardEvent="stopKeyboardEvent"
                />
              </template>
              <template v-for="field in pdf.fields" :key="field.key">
                <DragForm
                  v-if="field.type === 'signature' || field.type === 'checkbox'"
                  :key="field.key"
                  v-model:h="field.h"
                  v-model:isActive="field.active"
                  v-model:w="field.w"
                  v-model:x="field.x"
                  v-model:y="field.y"
                  :aspectRatio="['checkbox'].includes(field.type)"
                  :fieldKey="field.key"
                  :keyboardEvent="keyboardEvent"
                  :pdfH="pdf.pdfH"
                  :pdfScale="pdf.scale"
                  :pdfW="pdf.pdfW"
                  :resizable="true"
                  :style="{zIndex: field.active ? 999 : 98}"
                  :type="field.type"
                  classNameActive="fields-active"
                  classNameDragging="fields-dragging"
                  @onActive="onActive(field)"
                  @onActiveSingleField="onActiveSingleField(field)"
                  @onDeactivated="onDeactivated(field)"
                  @onDragging="setGroupAreaStyle()"
                >
                  <SignField
                    v-if="field.type === 'signature'"
                    :key="field.key"
                    :active="field.active"
                    :required="field.required"
                    :signField="field"
                  />
                  <UcsCheckbox
                    v-if="field.type === 'checkbox'"
                    :key="field.key"
                    :active="field.active"
                    :required="field.required"
                    :signField="field"
                  />
                </DragForm>
                <UcsText
                  v-if="field.type === 'text'"
                  :key="field.key"
                  v-model:fontSize="field.textStyleFontSize"
                  v-model:fontType="field.textStyleFontType"
                  v-model:h="field.h"
                  v-model:isActive="field.active"
                  v-model:required="field.required"
                  v-model:textAlign="field.textAlign"
                  v-model:value="field.textValueText"
                  v-model:w="field.w"
                  v-model:x="field.x"
                  v-model:y="field.y"
                  :field="field"
                  :fieldKey="field.key"
                  :keyboardEvent="keyboardEvent"
                  :pdfH="pdf.pdfH"
                  :pdfScale="pdf.scale"
                  :pdfW="pdf.pdfW"
                  @onActive="onActive(field)"
                  @onActiveSingleField="onActiveSingleField(field)"
                  @onDeactivated="onDeactivated(field)"
                  @startKeyboardEvent="startKeyboardEvent"
                  @stopKeyboardEvent="stopKeyboardEvent"
                />
                <UcsDate
                  v-if="field.type === 'date'"
                  :key="field.key"
                  v-model:dateFormat="field.dateFormat"
                  v-model:fontSize="field.textStyleFontSize"
                  v-model:fontType="field.textStyleFontType"
                  v-model:h="field.h"
                  v-model:isActive="field.active"
                  v-model:required="field.required"
                  v-model:textAlign="field.textAlign"
                  v-model:value="field.textValueText"
                  v-model:w="field.w"
                  v-model:x="field.x"
                  v-model:y="field.y"
                  :field="field"
                  :fieldKey="field.key"
                  :keyboardEvent="keyboardEvent"
                  :pdfH="pdf.pdfH"
                  :pdfScale="pdf.scale"
                  :pdfW="pdf.pdfW"
                  @onActive="onActive(field)"
                  @onActiveSingleField="onActiveSingleField(field)"
                  @onDeactivated="onDeactivated(field)"
                />
                <UcsNumber
                  v-if="field.type === 'number'"
                  :key="field.key"
                  v-model:addComma="field.addComma"
                  v-model:fontSize="field.textStyleFontSize"
                  v-model:fontType="field.textStyleFontType"
                  v-model:h="field.h"
                  v-model:isActive="field.active"
                  v-model:required="field.required"
                  v-model:textAlign="field.textAlign"
                  v-model:value="field.textValueText"
                  v-model:w="field.w"
                  v-model:x="field.x"
                  v-model:y="field.y"
                  :field="field"
                  :fieldKey="field.key"
                  :keyboardEvent="keyboardEvent"
                  :pdfH="pdf.pdfH"
                  :pdfScale="pdf.scale"
                  :pdfW="pdf.pdfW"
                  @onActive="onActive(field)"
                  @onActiveSingleField="onActiveSingleField(field)"
                  @onDeactivated="onDeactivated(field)"
                  @startKeyboardEvent="startKeyboardEvent"
                  @stopKeyboardEvent="stopKeyboardEvent"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <SignRightBar
        ref="signRightBar"
        :pdfNums="pdfState.pdfNums"
        :selectedFields="selectedFields"
        :selectedGroup="selectedGroup"
        @changeDocFile="changeDocFile"
        @changeField="changeField"
        @clickPreviewer="scrollToPage"
        @clickSignBtn="onClickSignBtn"
        @groupingFields="groupingFields"
        @removeField="removeField"
        @removeRequesterSignData="setRequesterSignData"
        @sortFields="sortFields"
        @ungroupingFields="ungroupingFields"
      />
    </div>
    <SignatureModal ref="signatureModal" @finishSign="setRequesterSignData"/>
    <StampModal ref="stampModal" @finishSign="setRequesterSignData"/>
    <TestModal ref="testModal"/>
    <TestModal2 ref="testModal2"/>
    <TestModal3 ref="testModal3" v-model:selectedFields="selectedFields"/>
  </div>
</template>

<script>
import { nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch, watchEffect } from 'vue'
import SignLeftBar from './modules/SignLeftBar'
import SignRightBar from './modules/SignRightBar'
import DragForm from 'components/signing/createForm/DragForm'
import UcsCheckbox from 'components/signing/createForm/UcsCheckbox'
import SignField from 'components/signing/createForm/SignField'
import { useStore } from 'vuex'
import UcsText from 'components/signing/createForm/UcsText'
import { genKey } from 'utils/helper'
import { onClickOutside } from '@vueuse/core'
import RequesterSignField from 'components/signing/createForm/SignatureDragResize'
import SignatureModal from 'components/signing/signing/SignatureModal'
import StampModal from 'components/signing/signing/StampModal'
import UcsDate from 'components/signing/createForm/UcsDate'
import UcsNumber from 'components/signing/createForm/UcsNumber'
import TestModal from './modules/TestModal'
import TestModal2 from './modules/TestModal2'
import TestModal3 from './modules/TestModal3'
import DragArea from 'views/signCreating/modules/dragArea'
import ContextMenu from 'views/signCreating/modules/contextMenu'
// import { MinusOutlined, PlusOutlined, RedoOutlined, UndoOutlined } from '@ant-design/icons-vue'
import { RollbackOutlined } from '@ant-design/icons-vue'
import { validDate } from 'utils/validate'
import dayjs from 'dayjs'
import { DocumentProcessTypes } from 'utils/commonValue'

export default {
  components: {
    TestModal,
    TestModal2,
    TestModal3,
    SignLeftBar,
    SignRightBar,
    DragForm,
    UcsCheckbox,
    SignField,
    UcsText,
    RequesterSignField,
    SignatureModal,
    StampModal,
    UcsDate,
    UcsNumber,
    DragArea,
    ContextMenu,
    RollbackOutlined
    // PlusOutlined,
    // MinusOutlined
  },
  props: {
    signData: {
      type: Object,
      default: () => null
    },
    pdfRenderData: {
      type: Array,
      default: () => []
    },
    isShowField: {
      type: Boolean,
      default: false
    },
    pdfState: { type: Object },
    processType: {
      type: String,
      default: DocumentProcessTypes.PROCEDURE
    },
    isSequential: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    const signOperation = ref()
    const store = useStore()
    const signatureModal = ref(null)
    const stampModal = ref(null)
    const signRightBar = ref(null)
    const contextMenu = ref(null)
    const testModal = ref(null)
    const testModal2 = ref(null)
    const testModal3 = ref(null)
    const state = reactive({
      isShowInput: false,
      pdfPage: store.state.documents.pdfData, // 얕은 복사로 pdfPage값 변경시 store 값도 함께 변경
      canRenderFields: false,
      currentSignData: null,
      fieldGroup: store.state.documents.fieldGroup,
      selectedFields: [],
      selectedGroup: null,
      keyboardEvent: null,
      dragPosition: {
        x: 0,
        y: 0
      },
      copyPositionRange: 10,
      pdfIndex: 0,
      pdfZoom: 100,
      isMacOS: window.navigator.userAgent.indexOf('Mac') !== -1,
      mousePosition: {
        position: {
          // 컨텍스트 메뉴 출력용 좌표
          pageX: 0,
          pageY: 0,

          // 마우스 위치 붙여넣기용 좌표
          offsetX: 0,
          offsetY: 0,
          page: 1
        }
      }
    })

    onMounted(() => {
      setting()
    })

    const setting = () => {
      bindScrollEvent()
      checkRequesterSigned()
      searchRequesterSignData()
      checkParticipantsSignFields()
      setEventListeners()
    }

    onBeforeUnmount(() => {
      stopKeyboardEvent()
      removeEventListeners()
    })

    const onClickPdf = (pdfIndex, event) => {
      event.stopImmediatePropagation()
      const canPut = event.target.className === 'fields-wrapper' || event.target.localName === 'span'
      let clickLocation = {
        x: event.offsetX,
        y: event.offsetY
      }
      if (event.target.className !== 'fields-wrapper') {
        clickLocation = {
          x: event.offsetX + event.target.offsetLeft,
          y: event.offsetY + event.target.offsetTop
        }
      }
      state.isShowInput = true
      const currentField = signOperation.value.getCurrentField()

      if (currentField && canPut) {
        const initSize = {
          text: {
            w: 150,
            h: 14
          },
          checkbox: {
            w: 20,
            h: 20
          },
          signature: {
            w: 65,
            h: 65
          },
          date: {
            w: 150,
            h: 14
          },
          number: {
            w: 150,
            h: 14
          }
        }

        const DEFAULT_FIELD_NAME = {
          text: '텍스트',
          signature: '서명',
          checkbox: '체크박스',
          checkbox_group: '체크박스 그룹',
          date: '날짜',
          number: '숫자'
        }

        const field = {
          ...currentField,
          x: clickLocation.x,
          y: clickLocation.y,
          w: Math.round(initSize[currentField.type].w * state.pdfPage[pdfIndex].scale),
          h: Math.round(initSize[currentField.type].h * state.pdfPage[pdfIndex].scale),
          key: `${currentField.type}-${genKey()}`,
          active: false,
          locationPage: pdfIndex + 1,
          required: currentField.type === 'signature' && currentField.formType !== 'requesterFields',
          // fieldName: `${DEFAULT_FIELD_NAME[currentField.type]}-${currentField.signingOrder}-${pdfIndex + 1}-${order}`
          fieldName: DEFAULT_FIELD_NAME[currentField.type]
        }

        if (currentField.type === 'text' || currentField.type === 'date' || currentField.type === 'number') {
          field.textValueText = ''
          field.textStyleFontSize = 12
          field.textStyleFontType = 'Noto Sans KR'
          field.textAlign = 'left'
          if (currentField.type === 'date') field.dateFormat = 'YYYY-MM-DD'
          if (currentField.type === 'number') field.addComma = true
        }
        if (currentField.formType === 'requesterFields' && currentField.type === 'signature') {
          if (state.currentSignData) {
            field.signData = state.currentSignData
          }
        }
        state.pdfPage[pdfIndex][currentField.formType].push(field)
        signOperation.value.clearCurrentField()
        checkRequesterSigned()
        checkParticipantsSignFields()
        store.dispatch('pushHistoryStorage')
      }
    }

    watchEffect(() => {
      if (props.isShowField) {
        nextTick(() => {
          state.canRenderFields = true
        })
      } else {
        state.canRenderFields = false
      }
    })

    watch(() => state.pdfPage, () => {
      setGroupAreaStyle()
    })

    watch(() => state.selectedGroup, () => {
      setGroupAreaStyle()
    })

    watch(() => store.state.documents.pdfData, () => {
      state.pdfPage = store.state.documents.pdfData
    })

    const onActive = (field) => {
      if (!state.selectedFields.find((element) => element.key === field.key)) {
        state.selectedFields.push(field)
      }
    }

    const onActiveSingleField = (field) => {
      if (field === null) {
        state.pdfPage.forEach(pdf => {
          state.selectedFields = []
          pdf.requesterFields.forEach(item => {
            item.active = false
          })
          pdf.fields.forEach(item => {
            item.active = false
          })
        })
        return
      }
      if (!state.keyboardEvent || (!state.keyboardEvent.ctrlKey && !state.keyboardEvent.shiftKey)) {
        state.pdfPage.forEach(pdf => {
          pdf.requesterFields.forEach(item => {
            if (item.key !== field.key) {
              item.active = false
            }
          })
          pdf.fields.forEach(item => {
            if (item.key !== field.key) {
              item.active = false
            }
          })
        })
      }
      if (!state.selectedFields.find((element) => element.key === field.key)) {
        state.selectedFields.push(field)
      }
      onActiveGroup()
    }

    const onDeactivated = (field) => {
      state.selectedFields = state.selectedFields.filter((element) => element.key !== field.key)
      onActiveGroup()
    }

    const onActiveGroup = () => {
      if (state.selectedFields.length === 0) {
        state.selectedGroup = null
        return
      }
      const groupParentName = state.selectedFields[0].groupParentName
      if (!groupParentName) {
        state.selectedGroup = null
        return
      }
      for (const index in state.selectedFields) {
        if (state.selectedFields[index].groupParentName !== groupParentName) {
          state.selectedGroup = null
          return
        }
      }
      state.selectedGroup = state.fieldGroup.find((element) => element.name === groupParentName)
      console.log(state.selectedGroup)
    }

    const pdfViewer = ref(null)
    onClickOutside(pdfViewer, () => {
      if (signOperation.value.getCurrentField()) {
        signOperation.value.clearCurrentField()
      }
    })

    const onClickSignBtn = (signType) => {
      if (signType === 'sign') signatureModal.value.show(true, true)
      if (signType === 'stamp') stampModal.value.show(true, true)
    }

    const setRequesterSignData = (signData) => {
      state.pdfPage.forEach(pdf => {
        pdf.requesterFields.forEach(item => {
          if (item.type === 'signature') {
            state.currentSignData = signData
            item.signData = signData
            if (signData === null) delete item.signData
          }
        })
      })
      checkRequesterSigned()
    }

    const checkRequesterSigned = () => {
      let result = true
      state.pdfPage.forEach(pdf => {
        pdf.requesterFields.forEach(item => {
          if (item.type === 'signature' && !item.signData) {
            result = false
          }
        })
      })
      store.dispatch('setIsRequesterSigned', result)
    }

    const searchRequesterSignData = () => {
      state.pdfPage.forEach(pdf => {
        pdf.requesterFields.forEach(item => {
          if (item.type === 'signature' && item.signData) {
            state.currentSignData = item.signData
          }
        })
      })
    }

    const checkParticipantsSignFields = () => {
      let result = true
      store.state.documents.signers.forEach(signer => {
        const temp = []
        state.pdfPage.forEach(pdf => {
          temp.push(...pdf.fields.filter(field => field.signingOrder === signer.signingOrder && field.type === 'signature' && field.required))
        })
        if (temp.length === 0) result = false
      })
      store.dispatch('setIsAllSignFilled', result)
    }

    const removeField = () => {
      if (state.selectedFields.length < 1) return
      let field, fields
      for (const i in state.selectedFields) {
        field = state.selectedFields[i]
        if (field.formType === 'requesterFields') {
          fields = state.pdfPage[field.locationPage - 1].requesterFields
        } else {
          fields = state.pdfPage[field.locationPage - 1].fields
        }
        const index = fields.findIndex(item => item.key === field.key)
        fields.splice(index, 1)
      }
      state.selectedFields = []
      state.copyPositionRange = 5
      checkRequesterSigned()
      checkGroupState()
      onActiveGroup()
      checkParticipantsSignFields()
      store.dispatch('pushHistoryStorage')
    }

    const changeField = (form, type) => {
      if (state.selectedFields.length < 1) {
        console.error('필드가 존재하지 않습니다')
        return
      }
      if (type === 'group') {
        state.selectedGroup.min = form.groupRange[0]
        state.selectedGroup.max = form.groupRange[1]
        state.selectedGroup.groupRangeOption = form.groupRangeOption
        store.dispatch('pushHistoryStorage')
      } else {
        for (const index in state.selectedFields) {
          let fields
          if (state.selectedFields[index].formType === 'requesterFields') {
            fields = state.pdfPage[state.selectedFields[index].locationPage - 1].requesterFields
          } else {
            fields = state.pdfPage[state.selectedFields[index].locationPage - 1].fields
          }
          const fieldIndex = fields.findIndex(item => item.key === state.selectedFields[index].key)
          if (index === -1) {
            console.error('필드가 존재하지 않습니다')
            continue
          }
          fields[fieldIndex][type] = form[type]
          if (type === 'dateValue') {
            fields[fieldIndex].textValueText = form.textValueText
          }
        }
        checkParticipantsSignFields()
        store.dispatch('pushHistoryStorage')
      }
    }

    const scrollToPage = index => {
      const y = document.getElementById(`pageContainer-${index}`).offsetTop - 150
      pdfViewer.value.scrollTo(0, y)
    }

    const pagesHeight = ref(null)
    const bindScrollEvent = () => {
      const pages = []
      props.pdfState.pdfPages.forEach((item, index) => {
        const curPage = item.pdfH * item.scale - 150
        const reduceVal = index === 0 ? 0 : pages[index - 1]
        const result = (reduceVal + curPage)
        pages.push(result)
      })
      pagesHeight.value = pages
    }

    const onScrollPdf = (event) => {
      const index = pagesHeight.value.findIndex(item => event.target.scrollTop < item)
      state.pdfIndex = index
      state.copyPositionRange = 10
      signRightBar.value.setCurrentPage(index + 1)
      setGroupAreaStyle()
    }

    /**
     * 키보드 이벤트 DOM에 등록
     */
    const startKeyboardEvent = () => {
      document.addEventListener('keydown', globalKeyboardDownEvent)
      document.addEventListener('keyup', globalKeyboardUpEvent)
    }

    /**
     * 키보드 이벤트 제거
     */
    const stopKeyboardEvent = () => {
      document.removeEventListener('keydown', globalKeyboardDownEvent)
      document.removeEventListener('keyup', globalKeyboardUpEvent)
    }

    /**
     * 키보드 이벤트 제어
     */
    const globalKeyboardDownEvent = ev => {
      if (ev.target.tagName.includes('INPUT')) {
        return
      }
      state.keyboardEvent = ev
      if (ev.ctrlKey || ev.metaKey) {
        if (ev.code === 'KeyC') {
          ev.preventDefault()
          copyFields()
        } else if (ev.code === 'KeyV') {
          if (ev.shiftKey) {
            pasteFields(state.mousePosition)
          } else {
            pasteFields()
          }
          ev.preventDefault()
        } else if (ev.code === 'KeyZ') {
          ev.preventDefault()
          undoFields()
        } else if (ev.code === 'KeyX') {
          ev.preventDefault()
          copyFields()
          removeField()
        } else if (ev.code === 'KeyY') {
          ev.preventDefault()
          redoFields()
        } else if (ev.code === 'KeyG') {
          ev.preventDefault()
          groupingFields()
        } else if (ev.code === 'KeyA') {
          ev.preventDefault()
          testModal2.value.activate()
        } else if (ev.code === 'KeyS') {
          ev.preventDefault()
          // testModal.value.activate()
          // testModal3.value.activate()
        }
      } else if (ev.code === 'Delete') {
        ev.preventDefault()
        removeField()
      }
    }

    /**
     * 필드 잘라내기
     */
    const cutFields = () => {
      copyFields()
      removeField()
    }

    /**
     * 필드 복사하기
     */
    const copyFields = () => {
      if (state.selectedFields.length === 0) return
      store.dispatch('copyFields', state.selectedFields)
      state.copyPositionRange = 10
    }

    /**
     * 필드 붙여넣기
     */
    const pasteFields = (position = null) => {
      // 클립보드 비어있으면 종료
      if (store.getters.getClipBoard.length === 0) return

      // 현재 선택된 필드들 비활성화
      state.selectedFields.forEach(field => {
        field.active = false
        onDeactivated(field)
      })
      state.selectedGroup = null
      onActiveGroup()
      setGroupAreaStyle()

      // 클립보드에서 필드와 그룹 가져오기
      const pasteFields = JSON.parse(JSON.stringify(store.getters.getClipBoard))
      const pasteGroups = [...state.fieldGroup]

      for (const i in pasteGroups) {
        const groupName = pasteGroups[i].name
        const groupFields = []
        for (const j in pasteFields) {
          if (pasteFields[j].groupParentName === groupName) {
            groupFields.push(pasteFields[j])
          }
        }
        if (groupFields.length === pasteGroups[i].count) {
          const newGroup = Object.assign({}, pasteGroups[i])
          const newGroupName = `group-${genKey()}`
          newGroup.name = newGroupName
          for (const j in groupFields) {
            pasteFields.find(item => item.key === groupFields[j].key).groupParentName = newGroupName
          }
          state.fieldGroup.push(newGroup)
        } else {
          for (const j in groupFields) {
            delete pasteFields.find(item => item.key === groupFields[j].key).groupParentName
          }
        }
      }

      let pdfPage, newX, newY, rangeX, rangeY
      for (const index in pasteFields) {
        if (position) {
          pdfPage = state.pdfPage[position.page - 1]
        } else {
          pdfPage = state.pdfPage[pasteFields[index].locationPage - 1]
        }

        const maxX = Math.floor(pdfPage.pdfW * pdfPage.scale - pasteFields[index].w)
        const maxY = Math.floor(pdfPage.pdfH * pdfPage.scale - pasteFields[index].h)

        const field = Object.assign({}, pasteFields[index])
        field.key = `${field.type}-${genKey()}`

        if (position) {
          if (index === '0') {
            rangeX = position.offsetX - field.x
            rangeY = position.offsetY - field.y
          }
          newX = rangeX + field.x
          newY = rangeY + field.y
        } else {
          newX = field.x + state.copyPositionRange
          newY = field.y + state.copyPositionRange
        }

        if (newX < 0) {
          newX = 0
        } else if (newX > maxX) {
          newX = maxX
        }

        if (newY < 0) {
          newY = 0
        } else if (newY > maxY) {
          newY = maxY
        }

        field.x = newX
        field.y = newY
        if (position) {
          field.locationPage = position.page
          state.pdfPage[position.page - 1][field.formType].push(field)
        } else {
          field.locationPage = state.pdfIndex + 1
          state.pdfPage[state.pdfIndex][field.formType].push(field)
        }

        if (field.type === 'date') {
          if (field.textValueText !== null && validDate(field.textValueText)) {
            const dateArray = field.textValueText.split(/[-/년월일]/)
            const year = parseInt(dateArray[0])
            const month = parseInt(dateArray[1]) - 1
            const day = parseInt(dateArray[2])
            field.dateValue = dayjs(new Date(year, month, day))
          }
        }
        field.active = true
        state.selectedFields.push(field)
      }
      state.copyPositionRange = state.copyPositionRange + 10
      checkRequesterSigned()
      checkParticipantsSignFields()
      store.dispatch('pushHistoryStorage')
      onActiveGroup()
    }

    /**
     * 이전 작업으로 되돌리기
     */
    const undoFields = () => {
      const undoRecord = store.getters.undoTask
      if (undoRecord) {
        store.dispatch('setPdfData', undoRecord.pdfData)
        store.dispatch('setFieldGroup', undoRecord.fieldGroup)
        state.pdfPage = store.state.documents.pdfData
        state.fieldGroup = store.state.documents.fieldGroup
        // onActiveSingleField(null)
        checkActivatedFields()
        nextTick(() => {
          checkRequesterSigned()
          checkParticipantsSignFields()
          signRightBar.value.checkSelectedFields()
          signRightBar.value.setOptionVisible()
        })
      }
    }

    /**
     * 되돌린 작업 복구하기
     */
    const redoFields = () => {
      const redoRecord = store.getters.redoTask
      if (redoRecord) {
        store.dispatch('setPdfData', redoRecord.pdfData)
        store.dispatch('setFieldGroup', redoRecord.fieldGroup)
        state.pdfPage = store.state.documents.pdfData
        state.fieldGroup = store.state.documents.fieldGroup
        // onActiveSingleField(null)
        checkActivatedFields()
        nextTick(() => {
          checkRequesterSigned()
          checkParticipantsSignFields()
          signRightBar.value.checkSelectedFields()
          signRightBar.value.setOptionVisible()
        })
      }
    }

    const globalKeyboardUpEvent = ev => {
      state.keyboardEvent = null
    }

    /**
     * 필드 정렬
     */
    const sortFields = (position) => {
      if (state.selectedFields.length < 1) return
      const components = []
      for (const index in state.selectedFields) {
        components.push(
          {
            left: state.selectedFields[index].x,
            right: state.selectedFields[index].x + state.selectedFields[index].w,
            top: state.selectedFields[index].y,
            bottom: state.selectedFields[index].y + state.selectedFields[index].h,
            width: state.selectedFields[index].w,
            height: state.selectedFields[index].h
          })
      }

      if (position === 'left') {
        const minLeft = Math.min(...components.map(item => item.left))
        for (const index in state.selectedFields) {
          state.selectedFields[index].x = minLeft
        }
      } else if (position === 'right') {
        const maxRight = Math.max(...components.map(item => item.right))
        for (const index in state.selectedFields) {
          state.selectedFields[index].x = maxRight - state.selectedFields[index].w
        }
      } else if (position === 'top') {
        const minTop = Math.min(...components.map(item => item.top))
        for (const index in state.selectedFields) {
          state.selectedFields[index].y = minTop
        }
      } else if (position === 'bottom') {
        const maxBottom = Math.max(...components.map(item => item.bottom))
        for (const index in state.selectedFields) {
          state.selectedFields[index].y = maxBottom - state.selectedFields[index].h
        }
      }
      store.dispatch('pushHistoryStorage')
    }

    /**
     * 필드의 그룹화 작업
     */
    const groupingFields = () => {
      // 선택된 필드들의 검증단계
      // 1. 선택된 필드의 갯수 검사
      if (state.selectedFields.length < 2) {
        console.log('필드갯수 미달')
        return false
      }

      // 2. 요청자 필드 검사
      const signingOrder = state.selectedFields[0].signingOrder
      if (!signingOrder) {
        console.log('참여자 필드 아님')
        return false
      }

      // 3. 선택된 필드중
      // 3-1. 체크박스가 아닌 필드가 있는지
      // 3-2. 선택된 필드끼리 참여자 정보가 일치하는지
      for (const index in state.selectedFields) {
        if (state.selectedFields[index].type !== 'checkbox' || state.selectedFields[index].signingOrder !== signingOrder) {
          console.log('체크박스가 아니거나 다른 참여자 필드끼리 뭉침')
          return false
        }
      }

      const groupList = []
      for (const index in state.selectedFields) {
        if (state.selectedFields[index].groupParentName) {
          groupList.push(state.selectedFields[index].groupParentName)
        }
      }
      const result = [...new Set(groupList)]
      if (result.length > 1) { // 4. 선택된 필드들에 두개 이상의 그룹이 존재하는지 검사
        console.log('그룹이 두개 이상 감지')
        return false
      } else if (result.length === 1) { // 1. 기존 그룹과 같이 선택되어 있을 경우, 해당 그룹에 편입한다
        for (const index in state.selectedFields) {
          const oldGroup = result[0]
          if (!state.selectedFields[index].groupParentName) {
            state.selectedFields[index].groupParentName = oldGroup
            state.selectedFields[index].required = false
          }
          checkGroupState()
          onActiveGroup()
        }
        onActiveGroup()
      } else { // 2. 기존 그룹이 없는 신규 필드로만 구성되어 있을 경우, 신규 그룹을 생성, 필드들은 해당 그룹을 참조하는 방식으로 논리적 종속관계를 맺는다
        const newGroup = {
          type: 'group',
          name: `group-${genKey()}`,
          signingOrder,
          groupRangeOption: 'over',
          min: 1,
          max: state.selectedFields.length,
          count: state.selectedFields.length
        }
        state.fieldGroup.push(newGroup)
        for (const index in state.selectedFields) {
          state.selectedFields[index].groupParentName = newGroup.name
          state.selectedFields[index].required = false
        }
        onActiveGroup()
      }
      store.dispatch('pushHistoryStorage')
    }

    /**
     * 필드 그룹 해제
     */
    const ungroupingFields = () => {
      if (state.selectedFields.length < 1) return
      for (let i = 0; i < state.selectedFields.length; i++) {
        delete state.selectedFields[i].groupParentName
      }
      state.selectedGroup = null
      checkGroupState()
      store.dispatch('pushHistoryStorage')
    }

    /**
     * 그룹 상태 확인
     */
    const checkGroupState = () => {
      for (const groupIndex in state.fieldGroup) {
        const groupName = state.fieldGroup[groupIndex].name
        const groupFields = []
        for (const pageIndex in state.pdfPage) {
          for (const fieldIndex in state.pdfPage[pageIndex].fields) {
            if (state.pdfPage[pageIndex].fields[fieldIndex].type === 'checkbox' && state.pdfPage[pageIndex].fields[fieldIndex].groupParentName === groupName) {
              groupFields.push(state.pdfPage[pageIndex].fields[fieldIndex])
            }
          }
        }

        if (groupFields.length <= 1) {
          state.fieldGroup.splice(groupIndex, 1)
          if (groupFields.length === 1) {
            delete groupFields[0].groupParentName
          }
        } else {
          state.fieldGroup[groupIndex].count = groupFields.length
          if (state.fieldGroup[groupIndex].max > state.fieldGroup[groupIndex].count) state.fieldGroup[groupIndex].max = state.fieldGroup[groupIndex].count
          if (state.fieldGroup[groupIndex].min > state.fieldGroup[groupIndex].count) state.fieldGroup[groupIndex].min = state.fieldGroup[groupIndex].count
        }
      }
      store.dispatch('pushHistoryStorage')
    }

    /**
     * 그룹 영역 표시
     */
    const setGroupAreaStyle = () => {
      const groupArea = document.getElementById('groupArea')
      const group = state.selectedGroup
      if (!group) {
        groupArea.style.top = '0px'
        groupArea.style.left = '0px'
        groupArea.style.width = '0px'
        groupArea.style.height = '0px'
        document.body.style.overflowY = ''
        groupArea.style.display = 'none'
        return
      }
      const groupFields = []
      for (const pageIndex in state.pdfPage) {
        for (const fieldIndex in state.pdfPage[pageIndex].fields) {
          if (state.pdfPage[pageIndex].fields[fieldIndex].type === 'checkbox' && state.pdfPage[pageIndex].fields[fieldIndex].groupParentName === group.name) {
            groupFields.push(state.pdfPage[pageIndex].fields[fieldIndex])
          }
        }
      }

      const styleTemp = {
        top: [],
        left: [],
        right: [],
        bottom: []
      }
      for (const index in groupFields) {
        const field = document.getElementById(groupFields[index].key)
        if (!field || field.getBoundingClientRect() === null) {
          groupArea.style.top = '0px'
          groupArea.style.left = '0px'
          groupArea.style.width = '0px'
          groupArea.style.height = '0px'
          document.body.style.overflowY = ''
          groupArea.style.display = 'none'
          return
        }
        const fieldRect = field.getBoundingClientRect()
        styleTemp.top.push(fieldRect.top)
        styleTemp.left.push(fieldRect.left)
        styleTemp.right.push(fieldRect.right)
        styleTemp.bottom.push(fieldRect.bottom)
      }

      groupArea.style.top = `${Math.min(...styleTemp.top)}px`
      groupArea.style.left = `${Math.min(...styleTemp.left)}px`
      groupArea.style.width = `${Math.max(...styleTemp.right) - Math.min(...styleTemp.left)}px`
      groupArea.style.height = `${Math.max(...styleTemp.bottom) - Math.min(...styleTemp.top)}px`
      groupArea.style.display = 'block'
      document.body.style.overflowY = 'hidden'
    }

    const showContextMenu = (ev) => {
      ev.preventDefault()
      if (ev.target.closest('.vdr') && state.selectedFields.length > 0) {
        // 필드에 컨텍스트메뉴 표시
        contextMenu.value.showContextMenu('field', {
          pageX: ev.pageX,
          pageY: ev.pageY,
          offsetX: ev.offsetX,
          offsetY: ev.offsetY
        })
      } else if (ev.target.closest('.fields-wrapper')) {
        const page = ev.target.parentElement.id.slice(14, 15)
        // pdf에 컨텍스트메뉴 표시
        contextMenu.value.showContextMenu('page', {
          pageX: ev.pageX,
          pageY: ev.pageY,
          offsetX: ev.offsetX,
          offsetY: ev.offsetY,
          page: Number(page)
        })
      } else {
        // 이외 위치에선 아무것도 안함
        console.log('This is Nothing')
      }
    }

    const hideContextMenu = (ev) => {
      if (ev.target.id !== 'contextMenuItem') {
        contextMenu.value.hideContextMenu()
      }
    }

    const setEventListeners = () => {
      document.getElementById('pdfViewer').addEventListener('contextmenu', showContextMenu)
      document.addEventListener('mousedown', hideContextMenu)
      document.getElementById('pdfViewer').addEventListener('mousemove', setMousePosition)
    }

    const removeEventListeners = () => {
      document.getElementById('pdfViewer').removeEventListener('contextmenu', showContextMenu)
      document.removeEventListener('mousedown', hideContextMenu)
      document.getElementById('pdfViewer').removeEventListener('mousemove', setMousePosition)
    }

    const setMousePosition = (ev) => {
      if (ev.target.closest('.fields-wrapper')) {
        const page = ev.target.parentElement.id.slice(14, 15)
        state.mousePosition = {
          pageX: ev.pageX,
          pageY: ev.pageY,
          offsetX: ev.offsetX,
          offsetY: ev.offsetY,
          page: Number(page)
        }
      }
    }

    const selectPdfZoom = ({ key }) => {
      if (Number(key) >= 60 && Number(key) <= 150) {
        nextTick(() => {
          state.pdfZoom = Number(key)
          document.getElementById('pdfViewer').style.transform = `scale(${state.pdfZoom / 100})`
          document.getElementById('pdfViewer').style.transformOrigin = 'top'
          bindScrollEvent()
        })
      }
    }

    const changeDocFile = () => {
      emit('changeDocFile')
    }

    const test = () => {
      console.log('test')
    }

    const checkActivatedFields = () => {
      state.selectedFields = []
      state.pdfPage.forEach(pdf => {
        pdf.requesterFields.forEach(item => {
          if (item.active) state.selectedFields.push(item)
        })
        pdf.fields.forEach(item => {
          if (item.active) state.selectedFields.push(item)
        })
      })
    }

    return {
      ...toRefs(state),
      testModal,
      testModal2,
      testModal3,
      signatureModal,
      stampModal,
      signOperation,
      signRightBar,
      contextMenu,
      pdfViewer,
      setting,
      onClickPdf,
      removeField,
      onActive,
      onActiveSingleField,
      onActiveGroup,
      onDeactivated,
      checkParticipantsSignFields,
      onClickSignBtn,
      setRequesterSignData,
      changeField,
      scrollToPage,
      onScrollPdf,
      startKeyboardEvent,
      stopKeyboardEvent,
      cutFields,
      copyFields,
      pasteFields,
      undoFields,
      redoFields,
      sortFields,
      groupingFields,
      ungroupingFields,
      setGroupAreaStyle,
      selectPdfZoom,
      changeDocFile,
      test
    }
  }
}
</script>

<style lang="less" scoped>
.frame-wrap {
  background: rgb(240, 242, 244);
}

.fields-wrapper {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.fields-dragging,
.fields-active {
  border: none;
}

.group-area {
  position: fixed;
  border: 2px #0f78d9 dotted;
  pointer-events: none;
  z-index: 99;
  display: none;
}

.pdf-controller {
  z-index: 1000;
  padding: 0.5rem 2rem;
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translate(-40%, 0);
  text-align: center;
  background: #000;
  opacity: 0.65;
  color: #fff;
  border-radius: 3px;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ratio {
    margin: 0 2px;

    .scale-button {
      width: 60px;
    }

    .anticon {
      margin: -1rem;
      padding: 1rem;
      font-size: 22px;
    }

    .ant-dropdown-trigger {
      background: #000;
      color: #fff;
      border: 0;
    }

    .control-btn {
      margin: 0 5px;
      font-size: 16px;
      user-select: none;

      .icon-undo {
        transform: scale(0.7);
      }

      .icon-redo {
        transform: scale(-0.7, 0.7);
      }
    }
  }

}
</style>
