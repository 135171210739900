<template>
  <a-modal v-model:visible="state.visible"
           :bodyStyle="{ padding: 0 }"
           :closable="false"
           :footer="null"
           class="folder-edit-modal-wrapper"
           :width="378"
           :zIndex="props.zIndex">
    <a-spin :spinning="state.isLoading">
      <div id="folder-modi" class="modal modal-sm">
        <div class="modal-header bg-gray">
          <h2>폴더 정보</h2>
          <span role="button" class="modal-close" @click="state.visible = false">닫기</span>
        </div>
        <div class="modal-content modal-set">
          <div class="modal-body">
            <section style="border: none;">
              <h3>기본 정보</h3>
              <div>
                <a-input
                  v-model:value="state.folderName"
                  enter-button="변경하기"
                  :maxlength="14"
                >
                </a-input>
              </div>
            </section>

            <div class="btn-row">
              <div class="btn-table">
                <a class="btn btn-gray-line" @click="state.visible = false">취소</a>
                <a class="btn btn-blue" @click="saveFolderName">완료</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script setup>
import { defineEmits, defineExpose, defineProps, reactive } from 'vue'
import { changeFolderName } from 'api/folder'
import { contentPre } from '@/utils/util'
import { Modal } from 'ant-design-vue'

const props = defineProps(['zIndex'])

const state = reactive({
  visible: false,
  isLoading: false,
  folderName: '',
  curFolder: null
})

const show = folder => {
  state.curFolder = folder
  state.folderName = folder.name
  state.visible = true
}

const saveFolderName = async () => {
  state.isLoading = true
  try {
    await changeFolderName({
      folderId: state.curFolder.folderId,
      name: state.folderName
    })
    state.visible = false
    emit('hasChanged')
  } catch (error) {
    Modal.error({ content: contentPre(error) })
  }
  state.isLoading = false
}

const emit = defineEmits(['hasChanged'])

defineExpose({ show })

</script>

<style lang="less" scoped>
.folder-edit-modal-wrapper {
  .modal-header {
    padding: 2rem;
  }

  .modal-close {
    margin: 24px;
  }

  .folder-type-radio-box {
    :deep(.ant-radio-group) {
      width: 100%;

      .ant-radio-button-wrapper {
        width: 50%;
        text-align: center;
      }
    }
  }

  .folder-btnset {
    display: flex;
    justify-content: space-between;
  }

  .folder-btnset a {
    width: 50%;
    margin: 1.5rem 0;
    text-align: center;
    font-size: 1.5rem;
    text-decoration: underline;
  }

  .modal-set .input-group-addon {
    padding: 0.5rem 1.5rem;
    background: #1890ff;
    color: #fff
  }

  .modal-set h3 {
    margin-bottom: 1rem
  }

  .modal-set section {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 2rem
  }

  .modal-set section:nth-child(2) {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0
  }

  .modal-set section .btn-radio {
    margin-left: 0rem
  }

  .modal-set .commt {
    background: #f7f7f7;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    width: 100%;
    display: block;
    margin: 1rem 0 0 0
  }

  .modal-set .commt svg {
    padding-top: 0.3rem
  }

  .modal-set .ant-table th, .modal-set .ant-table td {
    padding: 0.5rem 1rem;
    text-align: left;
  }

  .modal-set .ant-table th:nth-child(3), .modal-set .ant-table td:nth-child(3) {
    text-align: right
  }

  .modal-set .ant-pagination {
    float: inherit;
    margin: 1rem 0 0 0;
    text-align: center;
  }

  .modal-set .member-add {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem
  }

  .modal-set .member-add h4 {
    font-size: 1.5rem
  }

  .modal-set .member-add button {
    font-size: 1.4rem;
    text-decoration: underline;
  }

  @media (hover: hover) {
    .modal-set .member-add button:hover {
      color: #1890ff;
    }
  }

  .doc-list .except {
    text-decoration: underline;
  }
}

</style>
