import { reactive, toRefs } from 'vue'
import { getDocDetail, getDocFile } from 'api/sign'
import { useStore } from 'vuex'

const hasSignPermission = (signData, currentUserInfo) => {
  if (signData.status !== 'on_going') {
    return false
  }
  const currentSigner = signData.participants[signData.currentSigningOrder - 1]
  if (currentSigner.signingMethodType === 'email' && currentSigner.signingContactInfo === currentUserInfo.email) {
    return true
  }
  return false
}

export const useSigningData = documentId => {
  const store = useStore()
  const state = reactive({
    isLoading: true,
    signingData: null,
    hasSignPermission: false
  })
  const fetchData = () => {
    return Promise.all([getDocDetail(documentId), getDocFile(documentId)]).then(res => {
      const [detailRes, docRes] = res
      state.signingData = detailRes.result
      store.dispatch('setCurrentPdf', { pdfUrl: docRes.result.file })
      state.hasSignPermission = hasSignPermission(state.signingData, store.state.user.userInfo)
    }).finally(() => {
      state.isLoading = false
    })
  }

  // fetchData()

  return {
    ...toRefs(state),
    fetchData
  }
}
