<template>
  <div style="font-size: 16px">
    <div v-if="isLoading" class="loading-wrap">
      <a-spin class="spin" size="large"/>
    </div>
    <template v-else>
      <div class="sign-steps-wrapper">
        <div class="e-header e-fixed">
          <!--<UcsSetps v-if="isLoaded" :currentStep="currentStep" :steps="steps" />-->
          <h2 class="title">{{ title }}</h2>
        </div>
        <div class="attachment-btn">
          <button @click="onClickAttachment"><i class="ico ico-clip"></i><span>첨부파일</span></button>
        </div>
      </div>
      <div class="pdf-container">
        <div v-show="currentStep === 0 || currentStep === 1" class="sider">
          <PdfPreviewer
            ref="pdfPreviewer"
            :fields="fields"
            :participant="curParticipant"
            :pdfNums="pdfNums"
            mode="signing"
            @clickPreviewer="scrollToPage"
          ></PdfPreviewer>
        </div>
        <div v-show="currentStep === 0 || currentStep === 1" class="main">
          <div class="pdf-controller">
            <span class="require">필수항목 {{ necessaryFieldsNum }}</span>
            <span v-if="!isMobile" class="ratio">
              <MinusOutlined @click="selectPdfZoom({key: pdfZoom - 10})"/>
                <a-dropdown class="scale-button">
                  <template #overlay>
                    <a-menu @click="selectPdfZoom">
                      <a-menu-item key=150>
                        150%
                      </a-menu-item>
                      <a-menu-item key=120>
                        120%
                      </a-menu-item>
                      <a-menu-item key=100>
                        100%
                      </a-menu-item>
                      <a-menu-item key=80>
                        80%
                      </a-menu-item>
                      <a-menu-item key=60>
                        60%
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button>
                    {{ pdfZoom }}%
                  </a-button>
                </a-dropdown>
              <plus-outlined @click="selectPdfZoom({key: pdfZoom + 10})"/>
            </span>
          </div>
          <div
            ref="pdfRender"
            class="pdf-viewer"
            @scroll="onScrollPdf"
          >
            <div id="groupArea" :class="emphasizeGroup ? 'emphasizing-field' : ''" class="group-area"></div>
            <div id="groupTooltip" style="position: absolute;">
              <a-tooltip v-if="focusedGroup" v-model:visible="showGroupStat">
                <template #title>
                  <div v-if="focusedGroup.groupRangeOption === 'over'">{{ focusedGroup.groupMin }}개 이상</div>
                  <div v-else-if="focusedGroup.groupRangeOption === 'less'">{{ focusedGroup.groupMax }}개 이하</div>
                  <div v-else-if="focusedGroup.groupRangeOption === 'exact'">{{ focusedGroup.groupMin }}개 선택</div>
                  <div v-else-if="focusedGroup.groupRangeOption === 'range'">{{ focusedGroup.groupMin }}개 ~ {{ focusedGroup.groupMax }}개 선택</div>
                </template>
              </a-tooltip>
            </div>
            <PdfViewer
              id="pdfWrapper"
              ref="pdfViewer"
              :document="document"
              :fields="fields"
              :isSigning="currentStep !== 0"
              :participant="curParticipant"
              :pdfNums="pdfNums"
              :pdfPages="pdfPages"
              :processType="signingData.processType"
              class="pdf-render"
              mode="signing"
              @groupEmphasize="groupEmphasize"
              @groupFocus="groupFocus"
              @signChanged="onSignChanged"
            ></PdfViewer>
          </div>
        </div>
        <AttachmentsUpload
          v-if="currentStep === 2 && steps.length === 4"
          :attachments="curAttachments"
          :documentId="documentId"
        />
      </div>
      <!-- //e-container -->
      <div class="e-footer e-fixed">
        <div class="e-inner-sm">
          <div class="btn-row">
            <div class="btn-table">
              <a-button
                class="btn btn-gray-line"
                @click="goBack"
              >{{ backBtnText }}
              </a-button
              >
              <a-button
                :class="danger ? 'btn-disabled' : ''"
                :disabled="disableNextBtn"
                class="btn btn-blue"
                type="primary"
                @click="goNext"
              >{{ nextBtnText }}
              </a-button
              >
            </div>
            <div v-show="fixedToolTip === true" class="fixed-tooltip">
              <div class="txt">
                <span>버튼 클릭하고 서명 시작하기!</span>
                <CloseOutlined @click="removeTooltip"/>
              </div>
              <CaretDownOutlined/>
            </div>
          </div>
        </div>
      </div>
      <!-- //e-footer -->
    </template>
  </div>
  <!-- 계약 완료 -->
  <a-modal
    :confirmLoading="isSubmitting"
    :footer="null"
    :visible="isShowConfirmModal && payment === null && signingData.processType !== DocumentProcessTypes.FACE_TO_FACE"
    title=""
    @cancel="isShowConfirmModal = false"
  >
    <div class="cont-header">
      <svg aria-hidden="true" class="ico-img" data-icon="exclamation-circle" fill="#1890ff" focusable="false" height="4.5em" viewBox="64 64 896 896" width="4.5em">
        <path
          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z">
        </path>
      </svg>
      <h3>전자서명에 대한 동의</h3>
      <div class="text-gray mt15">
        모든 조건이 정확히 입력되었는지 확인해주세요.<br>
        서명 완료 후에는 내용을 수정할 수 없습니다.
      </div>
    </div>
    <div class="gray-box">
      <ol>
        <li><span>- 입력한 서명의 법적 효력에 대하여 동의합니다.</span></li>
        <li><span>- 서명 완료 후 전송되는 문서를 원본으로 인정합니다.</span></li>
        <li><span>- 모든 서명 참여자가 전자서명에 정당한 권한을 가지는 것을 확인했습니다.</span></li>
        <li><span>- 기타 자세한 내용은 <a href="https://ucansign.com/inc/terms" style="color: #1890ff;" target="_blank">이용약관</a> 및 <a href="https://ucansign.com/inc/privacy" style="color: #1890ff;"
                                                                                                                              target="_blank">개인정보처리방침</a>에 따라 동의합니다.</span></li>
        <li><span>- 서비스 운영에 필요한 기술적 지원 및 사용자 민원 처리가 필요한 경우, 최소한의 권한을 가진 관리자가 계약문서에 접근할 수 있습니다.</span></li>
      </ol>
    </div>
    <div class="btn-horizon">
      <a-button :loading="isSubmitting" class="btn btn-blue" @click="submitData">동의하고 서명완료</a-button>
    </div>
  </a-modal>
  <!-- end 계약 완료 -->
  <!-- 결제 계약 완료 -->
  <a-modal
    :confirmLoading="isSubmitting"
    :footer="null"
    :visible="isShowConfirmModal && payment && signingData.processType !== DocumentProcessTypes.FACE_TO_FACE "
    title=""
    @cancel="isShowConfirmModal = false"
  >
    <div class="cont-header">
      <svg aria-hidden="true" class="ico-img" data-icon="exclamation-circle" fill="#1890ff" focusable="false" height="4.5em" viewBox="64 64 896 896" width="4.5em">
        <path
          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z">
        </path>
      </svg>
      <h3>전자서명완료 및 결제 진행 동의</h3>
      <div class="text-gray mt15">
        입력한 내용으로 서명이 완료됩니다.<br/>
        서명 완료 후에는 내용을 수정할 수 없으니 다시 한번 확인해 주세요.<br/>
        전자서명이 완료된 이후 계약 내용에 대한 결제가 진행됩니다.
      </div>
    </div>
    <div class="gray-box">
      <ol>
        <li><span>- 입력한 서명의 법적 효력에 대하여 동의합니다.</span></li>
        <li><span>- 서명 완료 후 전송되는 문서를 원본으로 인정합니다.</span></li>
        <li><span>- 모든 서명 참여자가 전자서명에 정당한 권한을 가지는 것을 확인했습니다.</span></li>
        <li><span>- 기타 자세한 내용은 <a href="https://ucansign.com/inc/terms" style="color: #1890ff;" target="_blank">이용약관</a> 및 <a href="https://ucansign.com/inc/privacy" style="color: #1890ff;"
                                                                                                                              target="_blank">개인정보처리방침</a>에 따라 동의합니다.</span></li>
        <li><span>- 서비스 운영에 필요한 기술적 지원 및 사용자 민원 처리가 필요한 경우, 최소한의 권한을 가진 관리자가 계약문서에 접근할 수 있습니다.</span></li>
      </ol>
    </div>
    <div class="btn-horizon">
      <a-button class="btn btn-blue" @click="onClickConfirm">동의하고 결제하기</a-button>
    </div>
  </a-modal>
  <!-- end 결제 계약 완료 -->
  <!-- 대면 계약 완료 -->
  <a-modal
    :closable="false"
    :confirmLoading="isSubmitting"
    :footer="null"
    :visible="isShowConfirmModal && payment === null && signingData.processType === DocumentProcessTypes.FACE_TO_FACE"
    title=""
    @cancel="isShowConfirmModal = false"
  >
    <div class="face-to-face-modal">
      <div class="modal-header">
        참여자 대면서명 동의 및 완료
      </div>
      <div class="modal-wrapper">
        <div class="cnt">
          <table>
            <tr>
              <td>문서명</td>
              <td>{{ document.name }}</td>
            </tr>
            <tr>
              <td>서명 요청자 정보</td>
              <td>{{ document.requester.name }}<br v-if="isMobile"/>({{ document.requester.signingContactInfo }})</td>
            </tr>
            <tr>
              <td>서명 참여자 정보</td>
              <td>
                {{ curParticipant.name }}<br v-if="isMobile"/><span v-if="curParticipant.signingContactInfo">({{ curParticipant.signingContactInfo }})</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="gray-box">
      <ol>
        <li><span>- 서명 완료 후, 내용 수정을 할 수 없으니, 입력 내용을 다시 한 번 확인해 주세요.</span></li>
        <li><span>- 서명 완료 후 전송되는 문서를 원본으로 인정합니다.</span></li>
        <li><span>- 모든 서명 참여자가 전자서명에 정당한 권한을 가지는 것을 확인했습니다</span></li>
        <li><span>- 기타 자세한 내용은 <a href="https://ucansign.com/inc/terms" style="color: #1890ff;" target="_blank">이용약관</a> 및 <a href="https://ucansign.com/inc/privacy" style="color: #1890ff;"
                                                                                                                              target="_blank">개인정보처리방침</a>에 따라 동의합니다.</span></li>
        <li><span>- 서비스 운영에 필요한 기술적 지원 및 사용자 민원 처리가 필요한 경우, 최소한의 권한을 가진 관리자가 계약문서에 접근할 수 있습니다.</span></li>
      </ol>
    </div>
    <div class="modal-footer">
      <a-button class="btn btn-cancel" @click="isShowConfirmModal = false">이전 단계로</a-button>
      <a-button class="btn btn-confirm" type="primary" @click="submitData">동의하고 서명완료</a-button>
    </div>
  </a-modal>
  <!-- end 대면 계약 완료 -->
  <SimpleModal ref="backModal">
    <template #title>
      <div class="cont-header">
        <h3>나가기</h3>
      </div>
    </template>
    <template #content>
      <p>
        지금 나가면 작성한 내용을 모두 잃게 됩니다.<br/>
        정말 나가시겠습니까?
      </p>
    </template>
    <template #footer>
      <a-button class="btn btn-gray-line" @click="backModal.hide()">취소</a-button>
      <a-button class="btn btn-blue" @click="onConfirmBack">나가기</a-button>
    </template>
  </SimpleModal>
  <SimpleModal ref="faceToFaceNotiModal" :centered="true">
    <template #title>
      <div>
        <h3>서명 참여자 안내</h3>
      </div>
    </template>
    <template #content>
      <p v-if="document.isSequential">
        {{ signerTip[curParticipant.signingOrder - 1] }}<br/>
      </p>
      <p>
        '{{ curParticipant.name }}'님의 서명 화면입니다.
      </p>
    </template>
    <template #footer>
      <a-button class="btn btn-blue" @click="faceToFaceNotiModal.hide()">확인</a-button>
    </template>
  </SimpleModal>
  <AttachmentModal ref="attachmentModal"/>
</template>

<script>
import { computed, inject, nextTick, onBeforeMount, reactive, ref, toRefs, watchEffect } from 'vue'
import { CaretDownOutlined, CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import PdfPreviewer from 'components/signing/signing/PdfPreviewer'
import PdfViewer from 'components/signing/signing/PdfViewer'
import { useSigningData } from 'use/sign/useSigningData'
import { getAttachments, getCurrentField, signDocument } from 'api/sign'
import AttachmentsUpload from 'components/signing/signaure/AttachmentsUpload'
import { usePdf } from 'use/sign/usePdf'
import { clearLinkSessions, clearUserInfo, signFromLink } from '@/utils/helper'
import SimpleModal from 'components/common/SimpleModal'
import { useMediaQuery } from '@vueuse/core'
import PinchZoom from 'utils/pinchZoom'
import AttachmentModal from 'components/common/AttachmentModal.vue'
import { message, Modal } from 'ant-design-vue'
import { getPaymentInfo, payment as startPayment } from 'api/payment'
import { getMainPayment } from 'utils/payment'
import { useStore } from 'vuex'
import { DocumentProcessTypes } from 'utils/commonValue'
import { getFile } from 'api/file'

const signerTip = ['첫 번째 서명 참여자', '두 번째 서명 참여자', '세 번째 서명 참여자', '네 번째 서명 참여자', '다섯 번째 서명 참여자']

export default {
  components: {
    PlusOutlined,
    MinusOutlined,
    CaretDownOutlined,
    CloseOutlined,
    PdfPreviewer,
    PdfViewer,
    AttachmentsUpload,
    SimpleModal,
    // UcsSetps,
    AttachmentModal
  },
  setup () {
    const isMobile = useMediaQuery('(max-width: 768px)')
    const pdfRender = ref(null)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const pdfViewer = ref(null)
    const globalSpin = inject('globalSpin')
    const backModal = ref(null)
    const faceToFaceNotiModal = ref(null)
    const pdfPreviewer = ref(null)
    const attachmentModal = ref(null)

    // let curParticipant = null
    const state = reactive({
      title: '',
      steps: ['내용 확인', '입력', '파일첨부', '완료'],
      currentStep: 0,
      fields: [],
      isShowConfirmModal: false,
      isSubmitting: false,
      isFinishSign: false,
      curAttachments: [],
      documentId: route.params.documentId,
      isFinishAttachments: false,
      loadPdfFinished: false,
      pdfRenderData: [],
      isLoaded: false,
      payment: null,
      necessaryFieldsNum: '0개중 0개 입력',
      pdfZoom: 100,
      fixedToolTip: !store.state.notice.tooltipSignstart,
      focusedGroup: null,
      emphasizeGroup: false,
      showGroupStat: false,
      document: null
    })

    const {
      isLoading,
      signingData,
      hasSignPermission,
      fetchData
    } = useSigningData(state.documentId)

    const {
      pdfState,
      renderPdf,
      loadPdf,
      renderPdfPreviewer
    } = usePdf()

    const curParticipant = computed(() => {
      if (route.query.pi !== null && route.query.pi !== undefined) {
        return signingData.value.participants.find(participant => participant.participantId === route.query.pi)
      }
      return signingData.value
        ? signingData.value.participants[
          signingData.value.currentSigningOrder - 1
        ]
        : null
    })

    let touchManager = null
    const mobileZoomHandle = () => {
      if (!touchManager && isMobile.value) {
        /*
        const pdfWrapper = document.getElementById('pdfWrapper')
        pinchZoom(pdfWrapper)
        */
        nextTick(() => {
          const pdfWrapper = document.getElementById('pdfWrapper')
          const initScale = Math.floor(pdfWrapper.clientWidth / document.body.clientWidth)
          touchManager = new PinchZoom(pdfWrapper, {
            zoomFactor: initScale,
            use2d: false,
            setOffsetsOnce: true,
            maxZoom: 40,
            minZoom: 1,
            verticalPadding: 0,
            horizontalPadding: 0,
            onZoomStart: (object, evnet) => {
              if (state.focusedGroup) {
                groupFocus(state.focusedGroup.groupName)
                state.showGroupStat = true
                // groupFocus(null)
              }
            },
            onZoomUpdate: (object, event) => {
              if (state.focusedGroup) {
                groupFocus(state.focusedGroup.groupName)
                state.showGroupStat = false
                // groupFocus(null)
              }
            },
            onZoomEnd: (object, event) => {
              if (state.focusedGroup) {
                groupFocus(state.focusedGroup.groupName)
                state.showGroupStat = true
                // groupFocus(null)
              }
            },
            onDragStart: (object, event) => {
              if (state.focusedGroup) {
                groupFocus(state.focusedGroup.groupName)
                state.showGroupStat = true
                // groupFocus(null)
              }
            },
            onDragUpdate: (object, event) => {
              if (state.focusedGroup) {
                groupFocus(state.focusedGroup.groupName)
                state.showGroupStat = false
                // groupFocus(null)
              }
            },
            onDragEnd: (object, event) => {
              if (state.focusedGroup) {
                groupFocus(state.focusedGroup.groupName)
                state.showGroupStat = true
                // groupFocus(null)
              }
            }
          })
        })
      }
    }
    const fixMobileClientHeight = () => {
      nextTick(() => {
        const flexheight = pdfRender.value.clientHeight
        pdfRender.value.style.height = `${flexheight}px`
        const wrapperHeight = document.querySelector('.pdf-container').clientHeight
        document.querySelector('.pdf-container').style.height = `${wrapperHeight}px`
      })
    }

    const init = async () => {
      globalSpin.show()
      try {
        await fetchData()
        if (hasSignPermission) {
          const fileRes = await getFile({ documentId: state.documentId })
          const [fieldsResponse, attachemntsResponse] = await Promise.all([
            getCurrentField(state.documentId, curParticipant.value.participantId),
            getAttachments(state.documentId),
            loadPdf(atob(fileRes.result.file))
          ]).catch(error => {
            Modal.error({
              content: 'Critical Error : ' + error,
              onOk () {
                router.back()
              }
            })
            return false
          })
          state.curAttachments = attachemntsResponse.result.filter(item => item.participant.participantId === curParticipant.value.participantId)
          freshSteps()
          state.fields = fieldsResponse.result
          state.groups = state.fields.filter(item => item.fieldType === 'group')
          for (let i = 0; i < state.groups.length; i++) {
            state.groups[i].count = state.fields.filter(item => item.groupParentName === state.groups[i].groupName).length
          }
          if (signingData.value.processType === DocumentProcessTypes.PROCEDURE && curParticipant.value.payments) {
            state.payment = getMainPayment(curParticipant.value.payments)
          }
          await renderPdf()
          await renderPdfPreviewer()
          bindScrollEvent()
          if (isMobile.value) {
            mobileZoomHandle()
            fixMobileClientHeight()
          } else {
            pdfViewer.value.$el.style.height = '100%'
            selectPdfZoom({ key: 100 })
          }
          state.isLoaded = true

          if (signingData.value.processType === DocumentProcessTypes.FACE_TO_FACE) {
            faceToFaceNotiModal.value.show()
          }
        }
        state.title = signingData.value.name
        state.document = signingData.value
        globalSpin.hide()
      } catch (error) {
        globalSpin.hide()
      }
    }

    const disableNextBtn = computed(() => {
      if (state.currentStep === 2 && state.steps.length === 4) {
        return !state.isFinishAttachments
      } else {
        return false
      }
    })

    const checkFields = pdfRenderData => {
      let totalNecessaryFieldsCount = 0
      const unfinishedFields = []
      const checkedGroup = []
      let isFinishSign = false
      pdfRenderData.forEach(item => {
        item.fields.forEach(field => {
          if (field.fieldType === 'signature' && field.required) {
            totalNecessaryFieldsCount++
            if (!field.signData.isShow) {
              unfinishedFields.push(field)
            }
          } else if ((field.fieldType === 'text' || field.fieldType === 'date' || field.fieldType === 'number') && field.required) {
            totalNecessaryFieldsCount++
            if (!field.signData.textValue) {
              unfinishedFields.push(field)
            }
          } else if (field.fieldType === 'checkbox') {
            if (field.required) {
              totalNecessaryFieldsCount++
              if (!field.signData.isChecked) {
                unfinishedFields.push(field)
              }
            }
          }
          if (field.groupParentName) {
            if (checkedGroup.findIndex(item => item === field.groupParentName) === -1) {
              totalNecessaryFieldsCount++
              checkedGroup.push(field.groupParentName)
              if (unfinishedFields.findIndex(item => item.groupName === field.groupParentName) === -1) {
                if (!isGroupFinished(field.groupParentName)) {
                  unfinishedFields.push(state.groups.find(group => group.groupName === field.groupParentName))
                }
              }
            }
          }
        })
      })

      if (totalNecessaryFieldsCount !== 0 && unfinishedFields.length < 1) {
        isFinishSign = true
      }
      const result = {
        totalNecessaryFieldsCount: totalNecessaryFieldsCount,
        unfinishedFields: unfinishedFields,
        isFinishSign: isFinishSign
      }
      return result
    }

    const isGroupFinished = (groupName) => {
      const group = state.groups.find(group => group.groupName === groupName)
      const groupFields = []
      state.pdfRenderData.forEach(item => {
        item.fields.forEach(field => {
          if (field.groupParentName === groupName) groupFields.push(field)
        })
      })
      if (groupFields.length > 0) {
        let completedFieldsNum = 0

        groupFields.forEach(field => {
          if (field.fieldType === 'signature' && field.signData.isShow) {
            completedFieldsNum++
          } else if ((field.fieldType === 'text' || field.fieldType === 'date' || field.fieldType === 'number') && field.signData.textValue) {
            completedFieldsNum++
          } else if (field.fieldType === 'checkbox' && field.signData.isChecked) {
            completedFieldsNum++
          }
        })

        if (group.groupMin > completedFieldsNum || group.groupMax < completedFieldsNum) {
          return false
        }
      }
      return true
    }

    const onSignChanged = pdfRenderData => {
      // const hasUnDoSignField = pdfRenderData.findIndex(item => {
      //   return item.fields.findIndex(field => field.signData.isShow === false) !== -1
      // })
      // state.isFinishSign = hasUnDoSignField === -1
      state.pdfRenderData = pdfRenderData
      const result = checkFields(pdfRenderData)
      state.isFinishSign = result.isFinishSign
      if (!result.isFinishSign) {
        state.necessaryFieldsNum = `${result.totalNecessaryFieldsCount}개중 ${result.totalNecessaryFieldsCount - result.unfinishedFields.length}개 입력`
      } else {
        state.necessaryFieldsNum = '모두 입력 완료'
      }
    }

    const goNext = () => {
      if (state.currentStep === 0) {
        state.fixedToolTip = false
      }
      if (state.currentStep + 1 === state.steps.length - 1 && state.isFinishSign) {
        state.isShowConfirmModal = true
      } else if (state.currentStep === 1 && !checkFields(state.pdfRenderData).isFinishSign) {
        gotoUnfinishedField(state.pdfRenderData)
      } else {
        state.currentStep++
      }
    }
    const goBack = () => {
      if (state.currentStep === 0) {
        backModal.value.show()
      } else {
        pdfViewer.value.deactiveAllEmphasizing()
        state.currentStep--
      }
    }

    const onClickConfirm = async () => {
      if (state.payment) {
        getPaymentInfo(state.payment.paymentId).then(res => {
          state.payment.payState = res.result.payState
        })
      }

      if (state.payment.payState !== 1) {
        submitData()
      } else {
        state.isShowConfirmModal = false
        let timer = null
        try {
          globalSpin.show()
          const paymentId = state.payment.paymentId
          const popupWindow = window.open('about:blank')
          startPayment(paymentId).then(res => {
            popupWindow.location.replace(res.result.payUrl)
            timer = setInterval(() => {
              if (popupWindow.closed) {
                globalSpin.hide()
                clearInterval(timer)
                timer = null

                getPaymentInfo(state.payment.paymentId).then(res => {
                  if (res.result.payState === 4) {
                    submitData()
                  } else {
                    Modal.error({ content: '결제가 취소되었습니다. 다시 진행해 주세요.' })
                  }
                })
              }
            }, 1000)
          })
        } catch (err) {
          Modal.error({ content: `알 수 없는 이유로 결제에 실패했습니다. 에러코드: ${err.code}` })
          globalSpin.hide()
        }
      }
    }

    const submitData = () => {
      state.isShowConfirmModal = false
      globalSpin.show()
      const signResult = pdfViewer.value.getSignResult()

      for (const field of signResult) {
        if (field.locationX > 1 || field.locationY > 1 || field.locationX < 0 || field.locationY < 0) {
          globalSpin.hide()
          Modal.error({ content: `필드 좌표값 이상이 감지되었습니다. 해당 오류에 대해 고객센터에 문의해 주시기 바랍니다. (페이지 : ${field.locationPage}페이지, 필드타입 : ${field.fieldType}, 가로좌표 : ${field.locationX}, 세로좌표 : ${field.locationY}` })
          return false
        } else if (field.sizeHeight > 1 || field.sizeWidth > 1 || field.sizeHeight < 0 || field.sizeWidth < 0) {
          globalSpin.hide()
          Modal.error({ content: `필드 사이즈 이상이 감지되었습니다. 해당 필드를 다시 설정해 주시기 바랍니다.(페이지 : ${field.locationPage}페이지, 필드타입 : ${field.fieldType}, 가로좌표 : ${field.sizeWidth}, 세로좌표 : ${field.sizeHeight}` })
          return false
        }
      }

      signDocument({
        documentId: state.documentId,
        participantId: curParticipant.value.participantId,
        postData: signResult
      })
        .then(res => {
          const [isFromLink] = signFromLink()
          if (route.query.redirectUrl) {
            let link = route.query.redirectUrl
            if (!link.includes(encodeURIComponent('?'))) link += '?'
            link += `&documentId=${state.documentId}`
            if (res.result.status === 'completed') {
              link += '&action=signing_completed_all'
            } else {
              link += '&action=signing_completed'
            }
            window.location.href = decodeURIComponent(link)
            return false
          } else {
            if (state.payment !== null && state.payment !== undefined) {
              router.replace({
                name: 'SigningComplete',
                query: {
                  documentId: state.documentId,
                  paymentId: state.payment.paymentId
                }
              })
            } else {
              if (res.result.status === 'completed' && isFromLink) {
                router.replace({
                  name: 'SigningComplete',
                  query: {
                    documentId: state.documentId
                  }
                })
              } else {
                router.replace({
                  name: 'SigningComplete',
                  query: {
                    documentId: state.documentId,
                    processType: signingData.value.processType
                  }
                })
              }
            }
          }
        })
        .finally(() => {
          globalSpin.hide()
        })
    }

    const freshSteps = () => {
      if (state.curAttachments.length === 0 && state.steps.length === 4) {
        state.steps.splice(2, 1)
      }
    }

    watchEffect(() => {
      let requireNum = 0
      let finishedNum = 0
      state.curAttachments.forEach(item => {
        if (item.request.required) requireNum++
        if (item.file && item.request.required) finishedNum++
      })
      state.isFinishAttachments = requireNum === finishedNum
    })

    const nextBtnText = computed(() => {
      return state.currentStep === state.steps.length - 2 ? '서명 완료하기' : state.currentStep === 0 ? '입력 시작하기' : '다음 단계로'
    })

    onBeforeMount(async () => {
      await init()
    })

    const onConfirmBack = () => {
      if (route.query.redirectUrl) {
        let link = route.query.redirectUrl
        if (!route.query.redirectUrl.includes(encodeURIComponent('?'))) link += '?'
        link += `&documentId=${state.documentId}&action=signing_fail`
        window.location.href = decodeURIComponent(link)
        return false
      }
      const [isFromLink] = signFromLink()
      if (isFromLink) {
        clearLinkSessions()
        clearUserInfo()
        window.location.href = 'https://ucansign.com/'
      } else {
        if (history.state.back === null) {
          clearLinkSessions()
          clearUserInfo()
          window.location.href = 'https://ucansign.com/'
        } else {
          router.back()
        }
      }
    }

    const backBtnText = computed(() => {
      return state.currentStep !== 0 ? '이전 단계로' : '나가기'
    })

    const scrollToPage = index => {
      const y = document.getElementById(`pageContainer-${index}`).offsetParent.offsetTop
      pdfRender.value.scrollTo(0, y * (state.pdfZoom / 100))
    }

    const pagesHeight = ref(null)
    const bindScrollEvent = () => {
      const pages = []
      pdfState.pdfPages.forEach((item, index) => {
        const curPage = item.pdfH * item.scale * (state.pdfZoom / 100)
        const reduceVal = index === 0 ? 0 : pages[index - 1]
        const result = (reduceVal + curPage)
        pages.push(result)
      })
      pagesHeight.value = pages
    }

    const onScrollPdf = (event) => {
      const index = pagesHeight.value.findIndex(item => event.target.scrollTop < item)
      pdfPreviewer.value.setCurrentPage(index + 1)
      setGroupAreaStyle(state.focusedGroup)
    }

    const onClickAttachment = () => {
      attachmentModal.value.show(state.documentId)
    }

    const danger = computed(() => {
      return state.currentStep === 1 && !state.isFinishSign
    })

    const searchPosition = (field) => {
      if (field.fieldType === 'group') {
        const groupFields = state.fields.filter(item => item.groupParentName === field.groupName)
        let upperField = null
        for (const index in groupFields) {
          if (upperField === null) {
            upperField = groupFields[index]
          } else if (upperField.page >= groupFields[index] && upperField.locationY > groupFields.locationY) upperField = groupFields[index]
        }
        const page = upperField.locationPage
        const x = upperField.locationX
        const y = upperField.locationY
        const pdf = document.getElementById(`pageContainer-${page}`)
        const w = pdf.clientWidth
        const h = pdf.clientHeight
        return {
          left: (Math.floor(x * w)) * (state.pdfZoom / 100),
          top: (Math.floor(y * h) + ((h) * (page - 1)) - pdfRender.value.clientHeight / 5) * (state.pdfZoom / 100)
        }
      }
      const page = field.locationPage
      const x = field.locationX
      const y = field.locationY
      const pdf = document.getElementById(`pageContainer-${page}`)
      const w = pdf.clientWidth
      const h = pdf.clientHeight
      return {
        left: (Math.floor(x * w)) * (state.pdfZoom / 100),
        top: (Math.floor(y * h) + ((h) * (page - 1)) - pdfRender.value.clientHeight / 5) * (state.pdfZoom / 100)
      }
    }

    message.config({
      maxCount: 3
    })

    const gotoUnfinishedField = () => {
      const unsignedField = checkFields(state.pdfRenderData).unfinishedFields
      const page = unsignedField[0].locationPage
      pdfViewer.value.emphasizingField(unsignedField[0])
      if (touchManager === null) {
        const getPosition = searchPosition(unsignedField[0])
        pdfRender.value.scrollTo({
          left: 0,
          top: getPosition.top,
          behavior: 'smooth'
        })
      } else {
        const getPosition = searchPosition(unsignedField[0])
        pdfRender.value.scrollTo({
          left: 0,
          top: getPosition.top,
          behavior: 'smooth'
        })
      }
      if (unsignedField[0].fieldType === 'group') {
        message.error({
          content: () => '필드 그룹의 입력조건이 일치하지 않습니다.',
          class: 'ant-message-center'
        })
      } else {
        message.error({
          content: () => `${page}페이지에 누락된 항목이 있습니다.`,
          class: 'ant-message-center'
        })
      }
    }

    const selectPdfZoom = ({ key }) => {
      if (Number(key) >= 60 && Number(key) <= 150) {
        nextTick(() => {
          state.pdfZoom = Number(key)
          pdfViewer.value.$el.style.transform = `scale(${state.pdfZoom / 100})`
          pdfViewer.value.$el.style.transformOrigin = 'top'
          bindScrollEvent()

          // 컴포넌트 로드를 통한 스크롤 보정
          const height = pdfRender.value.scrollHeight
          if (height) {
          }
        })
      }
    }

    const removeTooltip = () => {
      store.dispatch('setTooltipSignStart', true)
      state.fixedToolTip = false
    }

    const groupFocus = (groupParentName) => {
      if (groupParentName === null) {
        state.focusedGroup = null
        state.showGroupStat = false
      } else {
        state.focusedGroup = state.groups.find(item => item.groupName === groupParentName)
        state.showGroupStat = true
      }
      setGroupAreaStyle(state.focusedGroup)
    }

    const setGroupAreaStyle = (group) => {
      const groupArea = document.getElementById('groupArea')
      if (!group) {
        groupArea.style.top = '0px'
        groupArea.style.left = '0px'
        groupArea.style.width = '0px'
        groupArea.style.height = '0px'
        document.body.style.overflowY = ''
        return
      }
      const groupFields = document.getElementsByClassName(group.groupName)

      const styleTemp = {
        top: [],
        left: [],
        right: [],
        bottom: []
      }

      for (let i = 0; i < groupFields.length; i++) {
        const fieldRect = groupFields[i].getBoundingClientRect()
        styleTemp.top.push(fieldRect.top)
        styleTemp.left.push(fieldRect.left)
        styleTemp.right.push(fieldRect.right)
        styleTemp.bottom.push(fieldRect.bottom)
      }

      groupArea.style.top = `${Math.min(...styleTemp.top)}px`
      groupArea.style.left = `${Math.min(...styleTemp.left)}px`
      groupArea.style.width = `${Math.max(...styleTemp.right) - Math.min(...styleTemp.left)}px`
      groupArea.style.height = `${Math.max(...styleTemp.bottom) - Math.min(...styleTemp.top)}px`
      document.body.style.overflowY = 'hidden'

      const groupTooltip = document.getElementById('groupTooltip')
      groupTooltip.style.top = `${Math.min(...styleTemp.top)}px`
      groupTooltip.style.left = `${Math.min(...styleTemp.left) + (Math.max(...styleTemp.right) - Math.min(...styleTemp.left)) / 2}px`
    }

    const groupEmphasize = (group) => {
      state.focusedGroup = group
      if (group === null) {
        setGroupAreaStyle(null)
        state.emphasizeGroup = false
      } else {
        setGroupAreaStyle(group)
        state.emphasizeGroup = true
      }
    }

    return {
      DocumentProcessTypes,
      signerTip,
      goBack,
      goNext,
      pdfRender,
      scrollToPage,
      onScrollPdf,
      attachmentModal,
      isLoading,
      signingData,
      curParticipant,
      submitData,
      pdfViewer,
      disableNextBtn,
      onSignChanged,
      nextBtnText,
      backModal,
      faceToFaceNotiModal,
      onConfirmBack,
      backBtnText,
      isMobile,
      pdfPreviewer,
      onClickConfirm,
      onClickAttachment,
      ...toRefs(pdfState),
      ...toRefs(state),
      danger,
      selectPdfZoom,
      removeTooltip,
      groupFocus,
      groupEmphasize
    }
  }
}
</script>

<style lang="less" scoped>
.ant-spin-container {
  touch-action: none;
  background: red
}

.e-header {
  max-width: 100vw;
}

.cont-header {
  margin-top: 3rem;
  text-align: center;
}

.text-gray {
  color: #8c8c8c;
}

.gray-box {
  margin: 2rem;
  padding: 1.5rem;
  border: 1px solid #eee;
  background: #f7f7f7;
  border-radius: 5px;
  text-align: left;
}

.btn-table {
  display: flex;
  justify-content: space-between;

  .btn {
    width: 165px;
  }
}

.pdf-container {
  display: flex;
  background: #f0f2f4;
  height: calc(100vh - 160px);

  .sider {
    z-index: 1000;
    width: 145px;
    padding: 10px;
    height: 100%;
    background: #fff;
    border-right: 1px solid #e0e2e4;
  }

  .pdf-viewer {
    margin-left: -120px;
    @media screen and(min-width: 769px) {
      padding-top: 4rem;
    }
  }
}

.fixed-tooltip {
  position: absolute;
  bottom: 70px;
  left: 153px;
  width: 21rem;
  text-align: center;
  border-radius: 10rem;
  background: #27d0b0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0 1.5rem;
  animation-name: updown;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-timing-function: ease;

  .txt {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon-close {
      padding: 1rem;
      margin: -0.9rem -1rem -1rem -1rem;
    }
  }

  .anticon-caret-down {
    position: absolute;
    top: 35px;
    left: 96px;
    line-height: 0 !important;
    color: #27d0b0;
    font-size: 20px;
    transform: scale(1, 3);
  }
}

@media screen and(max-width: 1200px) {
  .fixed-tooltip {
    left: 156px;

    .anticon-caret-down {
      left: 93px;
    }
  }
}

@media screen and(max-width: 640px) {
  .fixed-tooltip {
    left: 158px;

    .anticon-caret-down {
      left: 90px;
    }
  }
}

@keyframes updown {
  0% {
    bottom: 70px;
  }
  50% {
    bottom: 65px;
  }
  100% {
    bottom: 70px;
  }
}

.loading-wrap {
  position: relative;
  height: 100vh;
  touch-action: none;

  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 992px) {
  .pdf-container {
    .sider {
      display: none;
    }

    .pdf-viewer {
      margin: 10px auto 0;
      width: 100vw;
    }
  }
}

.attachment-btn {
  z-index: 1000;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 1rem;

  span {
    margin-left: 0.8rem
  }

  @media screen and (max-width: 768px) {
    span {
      display: none
    }
  }
}

.e-fixed {
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;

  .pdf-controller {
    z-index: 1000;
    padding: 0.5rem 2rem;
    position: fixed;
    width: 320px;
    top: 74px;
    left: 50%;
    margin-left: -160px;
    text-align: center;
    background: #000;
    opacity: 0.65;
    color: #fff;
    border-radius: 3px;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ratio {
      .scale-button {
        width: 60px;
      }

      .anticon {
        margin: -1rem;
        padding: 1rem;
      }

      .ant-dropdown-trigger {
        background: #000;
        color: #fff;
        border: 0;
      }
    }

  }

  .pdf-viewer {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media screen and (max-width: 768px) {
    .pdf-controller {
      margin-left: 15%;
      width: 70%;
      left: 0;
      justify-content: center;
    }
  }
}

.group-area {
  position: absolute;
  border: 2px #0f78d9 dotted;
  pointer-events: none;
  z-index: 99;
}

.face-to-face-modal {
  text-align: center;
}

.modal-header {
  display: inherit;
  font-size: 18px;
  font-weight: 700;
}

.modal-wrapper {
  table {
    width: 100%;

    td {
      padding: 15px;
      border: 1px solid #f0f0f0;
      text-align: left;
      vertical-align: middle;

      &:nth-child(1) {
        background-color: #fafafa;
      }
    }
  }

  .gray-box {
    margin: 2rem;
    padding: 1.5rem;
    border: 1px solid #eee;
    background: #f7f7f7;
    border-radius: 5px;
    text-align: left;
  }

  .approve {
    font-size: 14px;
    font-weight: 700;
  }
}

.modal-footer {
  width: 100%;
  justify-content: center;
  display: flex;
  border-top: 0;
  padding: 0;

  .btn-cancel {
    margin-right: 5px;
    width: 10.375em;
    min-width: 160px;
  }

  .btn-confirm {
    margin-left: 5px;
    width: 10.375em;
    min-width: 160px;
  }
}
</style>
