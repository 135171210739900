<template>
  <a-modal v-model:visible="state.visible"
           :bodyStyle="{ padding: 0 }"
           :closable="false"
           :footer="null"
           class="folder-modal-wrapper ucs-modal"
           :destroyOnClose="true"
  >
    <a-spin :spinning="state.isLoading">
      <div id="folder-set" class="modal modal-md">
        <div class="modal-header bg-gray">
          <h2>폴더 설정</h2>
          <span role="button" class="modal-close" @click="state.visible = false">닫기</span>
        </div>
        <div class="modal-content">
          <div class="modal-body">
            <div class="box-wrap">
              <ul class="folder-list">
                <!-- 폴더 설정 모달에서 리스트의 수정버튼을 클릭했을 때 시작 -->
                <li class="new-folder" v-if="state.isEditting">
                  <div class="new-form">
                    <!-- 수정 버튼 클릭하면 display:block 적용 -->
                    <div class="input-group">
                      <div>
                        <i class="ico ico-folder"></i>
                        <input
                          ref="folderNameInput"
                          type="text"
                          placeholder="폴더명을 입력하세요."
                          maxlength="14"
                          @blur="onBlur"
                          @keypress.enter="saveFolderName"
                          v-model="state.newFolderName"
                        />
                      </div>
                      <div class="input-group-addon btn-txt-group" v-if="state.isEditting && state.newFolderName">
                        <button
                          type="button"
                          class="btn-txt modal-trigger"
                          data-modal-id="folder-modi"
                          @click="saveFolderName"
                        >
                          완료
                        </button>
                        <button type="button" class="btn-txt" @click="cancelCreate">
                          취소
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list" v-for="folder in state.folders" :key="folder.folderId" :class="{ 'folder-selected': state.selectedFolder ? state.selectedFolder.folderId === folder.folderId : '' }"
                    @click="handleSelectedFolder(folder)">
                  <div class="name">
                    <i class="ico ico-folder"></i>
                    <i class="ico ico-lock" v-if="folder.type === 'PRIVATE'"></i>
                    {{ folder.name }}
                    <div class="btn-txt-group" v-if="folder.defaultY !== true && folder.folderId !== '' && showModifyBtns">
                      <button type="button" class="btn-txt" title="수정" @click="onClickEdit(folder)">
                        <i class="ico ico-modify">수정</i>
                      </button>
                      <button type="button" class="btn-txt" title="삭제" @click="removeFolder(folder)">
                        <i class="ico ico-eraser">삭제</i>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="folder-btnset" v-if="showModifyBtns">
              <a @click="onClickCreateFolder('PRIVATE')">+ 새 폴더 추가하기</a>
            </div>
            <div class="btn-row">
              <div class="btn-table">
                <a class="btn btn-gray-line" @click="state.visible = false">{{ cancelText }}</a>
                <a class="btn btn-blue" @click="confirm">{{ okText }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>

  </a-modal>
  <FolderNameModal ref="folderEditModal" @hasChanged="fetchData" :zIndex="10001"/>
</template>

<script setup>
import { createVNode, defineEmits, defineExpose, defineProps, reactive, ref } from 'vue'
import { createFolder, deleteFolder, getFolders } from 'api/folder'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue'
import FolderNameModal from '@/components/default/folder/FolderNameModal'
// import { useStore } from 'vuex'
import { getTeamId } from '@/utils/helper'

const folderEditModal = ref(null)
// const store = useStore()

const state = reactive({
  visible: false,
  folders: [],
  isEditting: false,
  newFolderName: '',
  isLoading: false,
  selectedFolder: null
})

const props = defineProps({
  showFullFolder: {
    type: Boolean,
    default: true
  },
  okText: { default: '완료' },
  cancelText: { default: '취소' },
  showModifyBtns: {
    type: Boolean,
    default: false
  },
  canChangeTeam: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['folderSelected', 'deletedFolder'])

const show = () => {
  state.visible = true
  fetchData()
}
const folderNameInput = ref(null)

const fetchData = async () => {
  state.isLoading = true
  const teamId = getTeamId()
  state.folders = props.showFullFolder ? [{
    name: '모든 폴더',
    folderId: ''
  }] : []
  const res = await getFolders({ teamId })
  state.folders.push(...res.result)
  state.isLoading = false
}

const onBlur = () => {
  if (!state.newFolderName.trim()) state.isEditting = false
}

const onClickCreateFolder = () => {
  state.isEditting = true
  setTimeout(() => {
    folderNameInput.value.focus()
  }, 0)
}

const saveFolderName = async () => {
  state.isLoading = true
  try {
    await createFolder({ name: state.newFolderName })
    state.newFolderName = ''
    state.isEditting = false
    fetchData()
  } catch (error) {
    Modal.error({ content: error.msg })
  }
  state.isLoading = false
}

const cancelCreate = () => {
  state.newFolderName = ''
  state.isEditting = false
}

const removeFolder = (folder) => {
  Modal.confirm({
    title: () => `${folder.name} 폴더를 삭제하시겠습니까?`,
    content: '삭제된 폴더에 포함된 문서는 모두 ‘기본 폴더’로 이동합니다.',
    icon: () => createVNode(ExclamationCircleOutlined),
    okText: () => '예',
    cancelText: () => '아니오',
    onOk () {
      state.isLoading = true
      deleteFolder(folder.folderId)
        .then(res => {
          if (state.selectedFolder.folderId === folder.folderId) {
            state.selectedFolder = null
          }
          fetchData()
          var defaultFolder = null
          for (let index = 0; index < state.folders.length; index++) {
            const element = state.folders[index]
            if (element.defaultY) {
              defaultFolder = element
              break
            }
          }
          emit('deletedFolder', {
            deletedFolder: folder,
            defaultFolder: defaultFolder
          })
        })
        .finally(() => {
          state.isLoading = false
        })
    }
  })
}

const onClickEdit = folder => {
  folderEditModal.value.show(folder)
}

const confirm = () => {
  if (state.selectedFolder) emit('folderSelected', state.selectedFolder)
  state.visible = false
}

const handleSelectedFolder = (folder) => {
  if (folder.folderId === '') {
    state.selectedFolder = {
      name: '모든 폴더',
      folderId: ''
    }
  } else {
    state.selectedFolder = folder
  }
}

defineExpose({ show })
</script>

<style lang="less" scoped>
.folder-modal-wrapper {
  .modal-header {
    padding: 2rem;
  }

  .modal-close {
    margin: 24px;
  }

  .modal-content {
    border: none;
  }

  .folder-selected {
    background: #1890ff !important;
    color: #fff !important;
  }

  .folder-selected .ico {
    filter: brightness(0) invert(1);
  }

  @media (hover: hover) {
    .folder-selected:hover {
      background: #1890ff !important;
      color: #fff !important;
    }

    .folder-selected:hover .ico {
      filter: brightness(0) invert(1);
    }
  }

  .box-wrap {
    height: 290px;
    padding: 0.938em;
    border: 1px solid #eee;
    overflow-y: auto;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  .box-wrap.box-lg {
    max-height: 21.875em;
  }

  @media only screen and (max-width: 640px) {
    .box-wrap.box-lg {
      max-height: 60vh;
    }
  }

  .folder-list li {
    padding: 1em 1.25em;
    border: 1px solid #eee;
    font-size: 1.4rem;
    font-weight: 600;
    color: #999;
    cursor: pointer;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
  }

  @media (hover: hover) {
    .folder-list .list:hover {
      background: #eee;
    }
  }

  .folder-list .list.unlabeled {
    color: #666;
  }

  .folder-list .list.selected,
  .folder-list .new-folder,
  .folder-list .new-form {
    border-color: #ccc;
    background: #fafafa;
  }

  .folder-list .new-folder input {
    width: 80%;
    background: transparent;
  }

  .folder-list li + li {
    margin-top: 0.313em;
  }

  .folder-list li .ico {
    margin: -0.2em 0.5em 0 0;
  }

  .folder-list .name {
    position: relative;
  }

  .folder-list .name .btn-txt-group {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  @media (hover: hover) {
    .folder-list .name:hover .btn-txt-group {
      display: block;
    }
  }

  .folder-btnset {
    display: flex;
    justify-content: center;
  }

  .folder-btnset a {
    width: 50%;
    margin: 1.5rem 0;
    text-align: center;
    font-size: 1.5rem;
    text-decoration: underline;
  }
}
</style>
