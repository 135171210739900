<template>
  <div class="sign-basic move-form">
    <div class="e-control e-chk">
      <button v-if="active" class="e-close" type="button" @click="onClickRemove" @touchstart="onClickRemove"><i class="ico ico-g-delete">닫기</i></button>
      <img :src="fullFilePath" style="width: 100%;height: 100%">
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { SIGNATURE_BYTE_URL } from 'config/config'

export default {
  props: ['signatureId', 'active'],
  setup (props, { emit }) {
    const state = reactive({
      fullFilePath: SIGNATURE_BYTE_URL[process.env.NODE_ENV] + props.signatureId
    })
    const onClickRemove = () => {
      emit('remove')
    }
    return {
      onClickRemove,
      ...toRefs(state)

    }
  }
}
</script>

<style lang="less" scoped>
.move-form {
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.e-chk {
  width: 100%;
  height: 100%;
}
</style>
