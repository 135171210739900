<template>
  <div :class="cls" :style="wrapStyle" class="text-wrap">
    <a-tooltip>
      <template #title>
        {{ fieldName }}
      </template>
      <textarea
        ref="textInput"
        v-model="text"
        :placeholder="fieldName"
        :style="`transform: scale(${inputScale}); width: ${width}px; min-height: ${height}px; max-height:${height}px; text-align:${textAlign}`"
        class="inputtext"
        inputmode="tel"
        style="transform-origin:left top; padding: 0; font-size: 60px; resize: none; line-height: 1; word-break: break-all;letter-spacing: none;  outline: none; border: 0px; overflow: hidden; background-color: rgba(0,144,255,0.2); "
        @click="setScrollH"
        @input="onInput">
        </textarea>
    </a-tooltip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue'

export default {
  props: ['field', 'textValue', 'scale'],
  setup (props, { emit }) {
    const obTextInput = ref(null)
    const textInput = ref(null)

    const state = reactive({
      id: props.field.fieldId,
      oldText: '',
      text: props.field.textValueText,
      addComma: props.field.addComma || false,
      textAlign: props.field.textAlign || 'left',
      inputScale: 1,
      minHeight: 0,
      maxHeight: 0,
      width: 0,
      height: 0,
      fieldName: props.field.fieldName,
      scrollHeight: 0,
      originScrollH: 0
    })

    const init = () => {
      const initScale = (props.field.textStyleFontSize * props.scale) / 60
      const minFontSize = props.field.textStyleFontSize < 4 ? props.field.textStyleFontSize : 4
      const minScale = (minFontSize * props.scale) / 60
      state.inputScale = initScale
      state.minHeight = props.field.height / initScale
      state.maxHeight = props.field.height / minScale
      state.width = props.field.width / initScale
      state.height = props.field.height / initScale
    }

    onMounted(() => {
      init()
      emit('update:textValue', state.text)
    })

    const cls = computed(() => {
      return { 'e-sure': props.field.required }
    })

    const wrapStyle = computed(() => {
      // // has border width
      const width = props.field.width
      const height = props.field.height
      return {
        width: `${width}px`,
        height: `${height}px`,
        fontFamily: props.field.textStyleFontType
      }
    })

    const onInput = () => {
      handleInputSize()
      let numberOnly = state.text.toString().replace(',', '')
      numberOnly = numberOnly.replace(/\D/g, '')
      if (state.addComma && numberOnly.length > 0) {
        numberOnly = Number(numberOnly)
        const formattedNumber = numberOnly.toLocaleString()
        state.text = formattedNumber
      } else {
        state.text = numberOnly
      }
      emit('update:textValue', state.text)
    }

    const handleInputSize = () => {
      const scrollH = textInput.value.scrollHeight
      if (state.originScrollH >= scrollH) {
        state.oldText = state.text
      } else {
        state.text = state.oldText
      }
    }

    const setScrollH = () => {
      if (state.originScrollH === 0) {
        state.originScrollH = textInput.value.scrollHeight
      }
    }

    watch(() => props.field.height, () => {
      init()
    })

    return {
      cls,
      obTextInput,
      textInput,
      onInput,
      wrapStyle,
      setScrollH,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.text-wrap {
  font-size: 0;
  padding: 0;

  .inputtext {
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: text;
    font-size: 60px;
    line-height: 1;
    background-color: rgba(255, 255, 255, 0.8);
    resize: none;
  }

  .ob-text {
    position: absolute;
    z-index: -9999;
    background-color: bisque;
    visibility: hidden;
  }
}

.inputtext::placeholder {
  color: #1890ff;
  font-weight: 500;
}

.inputtext:focus {
  background: #ffffff2c !important;
  box-shadow: 0px 0px 0px 3px #1890ff
}

.inputtext:focus::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

::selection {
  background-color: #338fff;
  color: #ffffff;
}
</style>
