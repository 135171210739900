<template>
  <a-modal
    v-model:visible="visible"
    :bodyStyle="{ padding: 0, fontSize: '16px' }"
    :closable="false"
    :footer="null"
    :maskClosable="false"
    :width="520"
    class="confirm-modal-wrapper"
  >
    <div class="modal modal-md">
      <div class="modal-header">
        <h2 v-if="finishStep === 2">
          <div v-if="processType === DocumentProcessTypes.PROCEDURE">
            서명 요청 정보
          </div>
          <div v-else-if="processType === DocumentProcessTypes.FACE_TO_FACE">
            대면서명 요청 정보
          </div>
        </h2>
        <span class="modal-close" role="button" @click="visible = false">닫기</span>
      </div>
      <div class="modal-content">
        <template v-if="finishStep === 1">
          <div class="cont-header text-center">
            <svg
              aria-hidden="true"
              class="ico-img"
              data-icon="exclamation-circle"
              fill="#ff4d4e"
              focusable="false"
              height="4.5em"
              viewBox="64 64 896 896"
              width="4.5em"
            >
              <path
                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"
              ></path>
            </svg>
            <h3>잠금 설정 주의사항</h3>
            <p>
              서명이 모두 완료되면 문서가 잠금 설정됩니다.<br/>아래 내용을
              반드시 확인해 주세요!
            </p>
          </div>
          <div class="modal-inner bg-light-gray">
            <div class="agree-area">
              <a-checkbox-group
                v-model:value="accept"
                :options="acceptOptions"
              />
            </div>
          </div>
          <!-- //waring-wrap -->
          <div class="btn-horizon">
            <a-button :disabled="!isAccept" class="btn" type="primary" @click="onAgree">다음 단계로</a-button>
          </div>
        </template>
        <template v-if="finishStep === 2">
          <div class="modal-content bg-gray">
            <div class="panel-wrap">
              <div class="panel">
                <div class="panel-head">
                  <div class="sub-tit">
                    <div class="row">
                      <h3 class="col-3">문서 이름</h3>
                      <span class="co-9 text-blue">{{ documentInfo.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //문서 이름 -->
              <div v-if="documentInfo.reservationDate" class="panel">
                <div class="panel-head">
                  <div class="sub-tit">
                    <div class="row">
                      <h3 class="col-3">예약 일시</h3>
                      <span class="co-9 text-blue">{{ FormatTime(documentInfo.reservationDate, 'YYYY-MM-DD HH시 mm분') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //예약 일시 -->
              <div class="panel">
                <div class="panel-head">
                  <div class="sub-tit">
                    <h3>서명 참여자 정보</h3>
                    <span class="s-right">추가 인증수단</span>
                  </div>
                </div>
                <!-- //서명 참여자 정보 -->
                <div class="panel-body">
                  <ul class="list-wrap">
                    <li v-for="signer in signers" :key="signer.signingOrder" class="row">
                      <div class="col-10">
                        <b v-if="isSequential" class="label-num">{{ signer.signingOrder }}</b>
                        <p>{{ signer.name }} ({{ signer.signingContactInfo }})</p>
                      </div>
                      <!-- <span class="col-2 text-gray text-right">설정안함 설정안함</span> -->
                      <div class="auth-info">
                        <template v-if="signer.authentications && signer.authentications.length > 0">
                          <p v-for="auth in signer.authentications" :key="auth.authType">{{ auth.authType === 'password' ? '접근 암호 인증' : '휴대폰 본인 인증' }}</p>
                        </template>
                        <template v-else>
                          <p>설정안함</p>
                        </template>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div style="margin-bottom: 10px;">
                <div class="agree-area mt15">
                  <label class="input-chk" for="agree">
                    <a-checkbox v-model:checked="isAgree1">전자서명개인정보 수집 및 이용에 동의합니다.</a-checkbox>
                  </label>
                  <span class="link text-gray" role="button" @click="onClickDetail">보기</span>
                </div>
                <div v-if="processType === DocumentProcessTypes.FACE_TO_FACE" class="agree-area mt15">
                  <label class="input-chk" for="agree">
                    <a-checkbox v-model:checked="isAgree2">대면서명 요청 후, 취소할 경우 사용한 포인트는 환불되지 않습니다.</a-checkbox>
                  </label>
                </div>
              </div>
              <!--폴더 변경-->
              <div class="panel">
                <div class="panel folder-panel">
                  <span>서명 문서를 <span class="text-blue">{{ teamName }} {{ folderName }}</span> 폴더에 보관합니다. </span>
                  <a-button type="link" @click="showFolderPicker">폴더 변경하기></a-button>
                </div>
              </div>
            </div>
            <!-- //panel-wrap -->
            <div class="btn-horizon">
              <a-button :disabled="isLoading" class="btn" @click="visible=false">취소</a-button>
              <a-button :disabled="!checkAgree" :loading="isLoading" class="btn" type="primary" @click="onSubmit">서명 요청하기</a-button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </a-modal>
  <div ref="modal" class="detail-modal">
    <a-modal
      id="test-modal"
      v-model:visible="isShowDetail"
      :getContainer="()=>$refs.modal"
      title="전자서명 개인정보 수집 및 이용 동의"
      width="730px"
    >
      <h1 class="title">1. 수집하는 개인정보</h1>
      <ul>
        <li>전자계약문서 작성 시 포함되는 개인정보 항목 일체(이름, 이메일, 전화번호, 주소, 생년월일 등)</li>
      </ul>
      <h1 class="title">2. 개인정보의 수집 및 이용목적</h1>
      <ul>
        <li>(1) 서명 요청자가 작성한 전자계약서를 서명 참여자에게 발송</li>
        <li>(2) 서명 참여자의 본인 인증</li>
        <li>(3) 서명 요청자와 서명 참여자 간의 전자계약 체결</li>
        <li>(4) 서명 요청자와 서명 참여자의 문서 이력 관리 및 보관</li>
        <li>(5) 서명 요청자와 서명 참여자의 민원 처리</li>
        <li>(6) 법적 분쟁 발생 시, 증거 및 참고자료로 활용</li>
      </ul>
      <h1 class="title">3. 개인정보의 보유 및 이용기간</h1>
      <ul>
        <li>개인정보의 수집 및 이용목적의 달성 시까지</li>
      </ul>
      <h1 class="title">4. 계약문서 접근 및 목적</h1>
      <ul>
        <li>서비스 운영에 필요한 기술적 지원 및 사용자 민원 처리가 필요한 경우, 최소한의 권한을 가진 관리자가 계약문서에 접근할 수 있습니다.</li>
      </ul>
      <template #footer>
        <a-button type="primary" @click="isShowDetail = false">확인</a-button>
      </template>
    </a-modal>
  </div>
  <component :is="folderModalComponent" ref="folderModal" :showFullFolder="false" @folderSelected="onFolderSelected"></component>
  <!--<component :is="folderModalComponent" :canChangeTeam="true" ref="folderModal" :showFullFolder="false" @folderSelected="onFolderSelected" @teamSelected="onTeamSelected"></component>-->
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import FolderModal from 'components/default/folder/FolderModal'
import { isOrgUser } from 'utils/helper'
import FolderModalNormal from 'components/default/folder/FolderModalNormal'
import { FormatTime } from '@/utils/util'
import { DocumentProcessTypes } from 'utils/commonValue'

export default {
  components: {
    FolderModal,
    FolderModalNormal
  },
  props: {
    processType: {
      type: String,
      default: DocumentProcessTypes.PROCEDURE
    },
    isSequential: {
      type: Boolean,
      default: true
    }
  },
  emits: ['finish'],
  setup (props, { emit }) {
    const state = reactive({
      visible: false,
      accept: [],
      acceptOptions: [
        '비밀번호를 잊으면 찾을 수 없으니, 꼭 기록해 두세요.',
        '서명이 모두 완료되면 문서에 비밀번호가 설정됩니다.',
        '참여자에게 비밀번호를 전달하셔야 문서와 이력 파일을 볼 수 있습니다.'
      ],
      finishStep: 1,
      isAgree1: false,
      isAgree2: false,
      isLoading: false,
      isShowDetail: false,
      currentFolder: null
    })
    const show = (needPassword = false) => {
      state.finishStep = needPassword ? 1 : 2
      state.visible = true
    }
    const isAccept = computed(() => {
      return state.accept.length === 3
    })
    const onAgree = () => {
      state.finishStep = 2
    }
    const store = useStore()
    const onSubmit = () => {
      const folderId = state.currentFolder ? state.currentFolder.folderId : ''
      const teamId = chosedTeam.value ? chosedTeam.value.teamId : ''
      emit('finish', {
        folderId,
        teamId
      })
    }
    const onClickDetail = () => {
      state.isShowDetail = true
    }

    const folderModal = ref(null)

    const folderModalComponent = computed(() => {
      return isOrgUser() ? FolderModal : FolderModalNormal
    })

    const onFolderSelected = (folder) => {
      state.currentFolder = folder
    }

    const onTeamSelected = (team) => {
      chosedTeam.value = team
    }

    const showFolderPicker = () => {
      chosedTeam.value ? folderModal.value.show(chosedTeam.value.teamId) : folderModal.value.show()
    }

    const chosedTeam = ref(null)
    const chosedTeamInit = () => {
      if (isOrgUser()) {
        if (!state.currentFolder && folderModal.value.getChosedTeam()) {
          return folderModal.value.getChosedTeam()
        } else {
          return { teamName: store.state.app.selectedTeam.name, ...store.state.app.selectedTeam }
        }
      } else {
        return null
      }
    }

    onMounted(() => {
      chosedTeam.value = chosedTeamInit()
    })

    const teamName = computed(() => {
      return chosedTeam.value ? chosedTeam.value.teamName + ' / ' : ''
    })

    const folderName = computed(() => {
      return state.currentFolder ? state.currentFolder.name : '기본'
    })

    const checkAgree = computed(() => {
      if (props.processType === DocumentProcessTypes.PROCEDURE) {
        return state.isAgree1
      } else if (props.processType === DocumentProcessTypes.FACE_TO_FACE) {
        return state.isAgree1 && state.isAgree2
      } else {
        return state.isAgree1
      }
    })

    return {
      DocumentProcessTypes,
      FormatTime,
      show,
      isAccept,
      onAgree,
      onSubmit,
      onClickDetail,
      folderModalComponent,
      onFolderSelected,
      showFolderPicker,
      folderModal,
      teamName,
      folderName,
      chosedTeam,
      onTeamSelected,
      checkAgree,
      ...toRefs(store.state.documents),
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.confirm-modal-wrapper {
  .modal-header {
    padding: 20px;
    // height: 63px;
    border: none;
  }

  .modal-close {
    margin: 24px;
  }

  .cont-header {
    // padding-top: 20px;
    h3 {
      padding: 5px 0;
    }
  }

  .modal-content {
    border: none;
  }
}

.list-wrap {
  .row {
    display: flex;
    align-items: center;

    .auth-info {
      width: 100px;
    }
  }
}

.btn-info {
  line-height: 18px;
}

.detail-modal {
  position: fixed;
  z-index: 10000;

  .title {
    margin: 10px 0 5px 0;
    font-size: 16px;
    font-weight: bold;
  }

  /deep/ .ant-modal-header {
    border: none;
  }

  /deep/ .ant-modal-footer {
    border: none;
  }
}

:deep(.agree-area) {
  .ant-checkbox-group-item {
    margin-bottom: 10px;
  }
}

.folder-panel {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
</style>
