import request from 'utils/request'

export const getFolders = (params) => {
  return request.get('/folders', {
    params: params
  })
}

export const createFolder = ({
  name,
  teamId,
  type
}) => {
  return request.post('/folders', {
    name,
    teamId,
    type
  })
}

export const changeFolderName = ({
  folderId,
  name
}) => {
  return request.put(`/folders/${folderId}/name`, { name })
}

export const changeFolderType = ({
  folderId,
  type
}) => {
  return request.put(`/folders/${folderId}/type`, { type })
}

export const deleteFolder = folderId => {
  return request.delete(`/folders/${folderId}`)
}

export const getPriviteFolderMembers = ({
  folderId,
  page,
  limit
}) => {
  return request.get(`/folders/${folderId}/accessible-members`, {
    params: {
      page,
      limit
    }
  })
}

export const getTeamMembers = ({
  teamId,
  page,
  limit
}) => {
  return request.get(`/teams/${teamId}/members`, {
    params: {
      page,
      limit
    }
  })
}

export const addAccessMember = ({
  members,
  folderId,
  teamId
}) => {
  return request.post(`/folders/${folderId}/accessible-members`, {
    members,
    teamId
  })
}

export const removeAccessMember = ({
  folderId,
  memberId
}) => {
  return request.delete(`/folders/${folderId}/accessible-members/${memberId}`)
}

export const getAllTeams = () => {
  return request.get('/teams', {
    params: {
      page: 1,
      limit: 99999
    }
  })
}

export const getAvailbaleTeams = () => {
  return request.get('teams/available', {
    params: {
      page: 1,
      limit: 99999
    }
  })
}

export const getAvailbaleTeamMembers = ({
  teamId,
  page,
  limit,
  folderId,
  excludeMemberIds
}) => {
  const url = `folders/${folderId}/available-members?page=${page}&limit=${limit}`
  return request.post(url, {
    teamId,
    excludeMemberIds
  })
}

export const getTeamInfo = teamId => {
  return request.get(`/teams/${teamId}`)
}

export const saveFolderInfo = (folderId, requestData) => {
  return request.put(`/folders/${folderId}`, requestData)
}
